import React, { useState } from 'react';
import { Box, IconButton, TextField, InputAdornment } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';


interface props {
  steps: number,
  setSteps: React.Dispatch<React.SetStateAction<number>>
}

const InterviewStepsInput: React.FC<props> = ({ steps, setSteps }) => {

  const handleIncrease = () => setSteps(steps + 1);
  const handleDecrease = () => steps > 1 && setSteps(steps - 1);

  return (
    <Box mt={2} display="flex" >
      <TextField
        variant="outlined"
        size="small"
        value={steps}
        InputProps={{
          readOnly: true,
          startAdornment: (
            <InputAdornment position="start">
              <IconButton onClick={handleDecrease} disabled={steps <= 1}>
                <RemoveIcon />
              </IconButton>
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={handleIncrease}>
                <AddIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
        sx={{ maxWidth: 150 }}
      />
    </Box>
  );
};

export default InterviewStepsInput;
