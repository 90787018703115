
export const getQuestionSound = (userID, jobID, soundID, questionID) => {
    //     const outputPath = path.join(__dirname, 'helper/storage', String(jobId),"interview",String(userId),String(questionId),uuid+".wav");
    //https://storage.googleapis.com/optihire-bucket-42/42-9-8e1f8dc7-8f54-4c55-956f-bc9cb9c97570
    return `https://storage.googleapis.com/optihire-bucket-42/${jobID}-${userID}-${soundID}`;
};


const dynamicData = {
    job_title: "Software Engineer",
    company: "Tech Corp",
    candidate_name: "John Doe",
    candidate_email: "john.doe@email.com",
    interview_date: "2024-12-15",
    job_offer_details: "Annual Salary: $80,000",
    application_date: "2024-12-01",
    job_location: "New York, NY",
    company_contact: "hr@techcorp.com",
    recruiter_name: "Jane Smith",
    interview_location: "Tech Corp HQ",
    interview_time: "10:00 AM",
};


export const replacePlaceholders = (content) => {
    let replacedContent = content;
    Object.keys(dynamicData).forEach((key) => {
        const placeholder = `[${key}]`;
        const value = dynamicData[key] || `[${key}]`;
        replacedContent = replacedContent.replaceAll(placeholder, value);
        replacedContent.replaceAll('\n', "<br>");

    });
    return replacedContent;
};