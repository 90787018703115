import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
    Typography,
    Button,
    Card,
    CardContent,
    Box,
    Chip,
    Tab, Tabs,
    Divider,
    CircularProgress,
    Alert,
    ListItem,
    ListItemText
} from '@mui/material';
import { LocationOn, AttachMoney, Favorite } from '@mui/icons-material';
import { fetchJobById, fetchJobKeywordsById, JobKeyword, JobRequirement, fetchJobRequirementsById, fetchCompanyById, activateJob, fetchJobInterviewsByJobId, interviewObj, interviewDetail } from '../api/api';
import JobQuestionsList from '../components/JobQuestionsList';
import Layout from '../components/Layout';
import InterviewDetail from '../components/InterviewDetail';
import Loading from '../components/Loading';

const JobDetail: React.FC = () => {
    const { jobId } = useParams<{ jobId: string }>();
    const navigate = useNavigate();
    const [job, setJob] = useState<any>(null);
    const [company, setCompany] = useState<any>(null); // Add state for company
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);
    const [keywords, setKeywords] = useState<JobKeyword[]>([]);
    const [requirements, setRequirements] = useState<JobRequirement[]>([]);
    const [interviewList, setinterviewList] = useState<interviewDetail[]>([])
    const [selectedInterview, setSelectedInterview] = useState<interviewDetail>();  // Track selected interview
    const [selectedTab, setSelectedTab] = useState(0);  // Track selected tab

    // Handle tab change
    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
        setSelectedTab(newValue);
    };

    // Handle selecting a specific interview
    const handleSelectInterview = (interview: interviewDetail, index: number) => {
        setSelectedInterview(interview);
        setSelectedTab(index);  // Reset to first tab when switching interview
    };
    useEffect(() => {
        const loadJobAndCompany = async () => {
            try {
                if (jobId) {
                    const jobData = await fetchJobById(jobId);
                    const interviews = await fetchJobInterviewsByJobId(jobId);
                    setinterviewList(interviews)
                    setJob(jobData);
                    if (interviews[0])
                        setSelectedInterview(interviews[0])

                    const jobKeywords = await fetchJobKeywordsById(jobId);
                    const jobRequirements = await fetchJobRequirementsById(jobId);
                    setKeywords(jobKeywords);
                    setRequirements(jobRequirements);

                    if (jobData.company_id) {
                        const companyData = await fetchCompanyById(jobData.company_id);
                        setCompany(companyData);
                    }
                }
            } catch (error) {
                //  setError('Failed to load job details');
            } finally {
                setLoading(false);
            }
        };

        loadJobAndCompany();
    }, [jobId]);

    const handleApply = async () => {
        navigate(`/apply/${jobId}`);

    };

    const handleViewInterviewees = () => {
        navigate(`/jobs/${jobId}/interviewees`);
    };

    const handleActivateJob = async () => {
        try {
            if (jobId)
                await activateJob(jobId)
                // to do give alert
        } catch (error) {

        }
    }

    if (loading) return <Loading />;
    if (error) return <Alert severity="error">{error}</Alert>;

    return (
        <Layout>
            {job ? (
                <Box sx={{ p: 3 }}>
                    {/* Header Section */}
                    <Card variant="outlined" sx={{ mb: 3, p: 2 }}>
                        <CardContent sx={{ display: 'flex', alignItems: 'flex-start', flexWrap: 'wrap' }}>
                            {/* Company Icon */}
                            <Box
                                sx={{
                                    width: 120,
                                    height: 120,
                                    borderRadius: '50%',
                                    overflow: 'hidden',
                                    mr: 3,
                                    flexShrink: 0,
                                }}
                            >
                                <img
                                    src={job.icon_url || `https://picsum.photos/80/80?random=${job.id}`}
                                    alt={`${job.company_name} Icon`}
                                    style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                                />
                            </Box>

                            {/* Job Details */}
                            <Box sx={{ flexGrow: 1, minWidth: 0 }}>
                                <Typography variant="h4" gutterBottom>
                                    {job.job_title}
                                </Typography>
                                <Typography variant="h6" color="textSecondary" gutterBottom>
                                    {job.company_name}
                                </Typography>

                                {company && (
                                    <Typography variant="body2" color="textSecondary" sx={{ mb: 2 }}>
                                        {company.description}
                                    </Typography>
                                )}

                                {/* Job Metadata */}
                                <Box sx={{ display: 'flex', gap: 2, flexWrap: 'wrap' }}>
                                    {company?.website_url && (
                                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                            <Favorite sx={{ mr: 1, color: 'primary.main' }} />
                                            <Typography variant="body2" color="textSecondary">
                                                {company.website_url}
                                            </Typography>
                                        </Box>
                                    )}
                                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                        <LocationOn sx={{ mr: 1, color: 'primary.main' }} />
                                        <Typography variant="body2">{job.location}</Typography>
                                    </Box>
                                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                        <AttachMoney sx={{ mr: 1, color: 'primary.main' }} />
                                        <Typography variant="body2">{job.salary}</Typography>
                                    </Box>
                                </Box>
                            </Box>

                            {/* Action Buttons */}
                            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end', ml: 3 }}>
                                <Button variant="contained" color="primary" onClick={handleApply} sx={{ mb: 1 }}>
                                    Apply Now
                                </Button>
                                <Button variant="contained" color="primary" onClick={handleViewInterviewees} sx={{ mb: 1 }}>
                                    Mülakat Sonuçları
                                </Button>
                                <Button variant="contained" color="primary" onClick={handleActivateJob}>
                                    Activate Job
                                </Button>
                            </Box>
                        </CardContent>
                    </Card>


                    {/* Job Description */}
                    <Card variant="outlined" sx={{ mb: 2 }}>
                        <CardContent>
                            <Typography variant="h5" gutterBottom>Description</Typography>
                            <Typography paragraph>{job.description}</Typography>
                        </CardContent>
                    </Card>

                    {/* Keywords */}
                    <Box sx={{ mb: 2 }}>
                        <Typography variant="h6" gutterBottom>Job Keywords</Typography>
                        <Box>
                            {keywords.map((keyword, index) => (
                                <Chip key={index} label={keyword.keyword} sx={{ mr: 1, mb: 1 }} />
                            ))}
                        </Box>
                    </Box>

                    {/* Requirements */}
                    <Box sx={{ mb: 2 }}>
                        <Typography variant="h6" gutterBottom>Job Requirements to evaluate</Typography>
                        <Box>

                            {requirements.map((keyword, index) => (
                                <React.Fragment key={keyword.requirement}>
                                    <ListItem>
                                        <ListItemText
                                            primary={`${index + 1}. ${keyword.requirement}`}
                                            primaryTypographyProps={{ variant: 'body1' }}
                                        />
                                    </ListItem>
                                    <Divider />
                                </React.Fragment>
                            ))}
                        </Box>
                    </Box>




                </Box>
            ) : (
                <Typography variant="body1">Job not found</Typography>
            )}
        </Layout>
    );
};

export default JobDetail;
