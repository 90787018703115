import React, { useState, useRef, useEffect } from "react";
import {
  Container,
  Box,
  Typography,
  Button,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Paper,
} from "@mui/material";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import Layout from "../components/Layout";
import { useParams, useNavigate } from 'react-router-dom';
import { getEmailTemplateWithId, updateEmailTemplate } from "../api/api";

const TemplateEditPage = () => {
  const { templateId } = useParams();
  const [loading, setloading] = useState(true)
  const [emailContent, setEmailContent] = useState({
    subject: "",
    body: "",
    name:"",
  });
  const [selectedPlaceholder, setSelectedPlaceholder] = useState("");
  const [focusedField, setFocusedField] = useState("body"); // Track focus
  const [templateType, settemplateType] = useState()
  const quillRef = useRef(null);
  const subjectFieldRef = useRef(null);

  const dynamicData = {
    job_title: "Software Engineer",
    company: "Tech Corp",
    candidate_name: "John Doe",
    candidate_email: "john.doe@email.com",
    interview_date: "2024-12-15",
    job_offer_details: "Annual Salary: $80,000",
    application_date: "2024-12-01",
    job_location: "New York, NY",
    company_contact: "hr@techcorp.com",
    recruiter_name: "Jane Smith",
    interview_location: "Tech Corp HQ",
    interview_time: "10:00 AM",
  };


  const replacePlaceholders = (content) => {
    let replacedContent = content;
    Object.keys(dynamicData).forEach((key) => {
      const placeholder = `[${key}]`;
      const value = dynamicData[key] || `[${key}]`;
      replacedContent = replacedContent.replaceAll(placeholder, value);
      replacedContent.replaceAll('\n', "<br>");

    });
    return replacedContent;
  };

  // This function simulates fetching a specific template based on templateId
  useEffect(() => {
    const fetchTemplate = async () => {
      // Simulating fetching a template by ID (replace with actual API call)
      const emailTemplate = await getEmailTemplateWithId(templateId)
      setEmailContent({ subject: emailTemplate.subject, body: emailTemplate.body,name:emailTemplate.name })
      console.log("emailTemplate",emailTemplate)
      settemplateType(emailTemplate.template_type_id)
      setloading(false)
    };

    fetchTemplate();
  }, [templateId]);

  const handleSave = () => {
    console.log("Email template saved", templateType);
    updateEmailTemplate(emailContent.subject,emailContent.body,templateId,emailContent.name)

  };

  const handleCancel = () => {
    // Reset to initial state or navigate away
    //setEmailContent({ subject: "", body: "" });
  };

  const handleBodyChange = (value) => {
    setFocusedField("body"); // Update focus
    setEmailContent({ ...emailContent, body: value });
  };

  const handleSubjectChange = (value) => {
    setFocusedField("subject"); // Update focus
    setEmailContent({ ...emailContent, subject: value });
  };

  const handleTitleChange = (value) => {
    setEmailContent({ ...emailContent, name: value });
  };

  const insertPlaceholder = () => {
    if (!selectedPlaceholder) return;
    const placeholder = `[${selectedPlaceholder}]`;
    if (focusedField === "body") {
      // Insert into Quill editor
      const editor = quillRef.current.getEditor();
      const range = editor.getSelection();
      if (range && range.index !== null) {
        editor.insertText(range.index, placeholder);
      }
    } else if (focusedField === "subject") {
      if (subjectFieldRef.current) {
        const cursorPosition = parseInt(subjectFieldRef.current.selectionStart.toString() || emailContent.subject.length);
        const updatedSubject =
          emailContent.subject.slice(0, cursorPosition) +
          placeholder +
          emailContent.subject.slice(cursorPosition);

        setEmailContent({ ...emailContent, subject: updatedSubject });
      }
    }
  };
  if (loading) { return <p>loading</p> }

  return (
    <Layout>
      <Container maxWidth="lg">
        <Box sx={{ display: "flex", height: "100vh", overflow: "hidden" }}>
          <Box sx={{ flex: 1, p: 3 }}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                mb: 3,
              }}
            >
              <Typography variant="h4">Edit Email Template</Typography>
              <Box>
                <Button variant="contained" onClick={handleSave} sx={{ mr: 2 }}>
                  Save
                </Button>
                <Button variant="outlined" onClick={handleCancel}>
                  Cancel
                </Button>
              </Box>
            </Box>

            <Box>
              {/* Subject Field */}
              <TextField
                label="Name"
                variant="outlined"
                fullWidth
                value={emailContent.name}
                onChange={(e) => handleTitleChange(e.target.value)}
                sx={{ mb: 2 }}
              />
              <TextField
                label="Subject"
                variant="outlined"
                fullWidth
                value={emailContent.subject}
                onChange={(e) => handleSubjectChange(e.target.value)}
                inputRef={subjectFieldRef}
                sx={{ mb: 2 }}
              />

              {/* Email Body Editor (Quill) */}
              <ReactQuill
                ref={quillRef}
                value={emailContent.body}
                onFocus={() => setFocusedField("body")}
                onChange={handleBodyChange}
                placeholder="Start writing your email body..."
                style={{ height: "300px", marginBottom: "16px" }}
              />

              {/* Placeholder Insert Form */}
              <Box sx={{ display: "flex", alignItems: "center", gap: 2, mt: 6 }}>
                <FormControl fullWidth>
                  <InputLabel>Insert Placeholder</InputLabel>
                  <Select
                    label="Insert Placeholder"
                    value={selectedPlaceholder}
                    onChange={(e) => setSelectedPlaceholder(e.target.value)}
                  >
                    {Object.keys(dynamicData).map((key) => (
                      <MenuItem key={key} value={key}>
                        {key.replaceAll("_", " ").toUpperCase()}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <Button
                  variant="contained"
                  onClick={insertPlaceholder}
                  sx={{ px: 4, py: 1, whiteSpace: "nowrap" }}
                >
                  Insert Placeholder
                </Button>
              </Box>

              {/* Template Preview */}
              <Box sx={{ mt: 4 }}>
                <Typography variant="h6">Preview</Typography>
                <Paper
                  sx={{
                    mt: 2,
                    p: 2,
                    border: "1px solid #ddd",
                    maxHeight: "400px",
                    overflowY: "auto",
                  }}
                >
                  <Typography variant="h6">
                    {replacePlaceholders(emailContent.subject) || "Subject here"}
                  </Typography>
                  <Typography
                    variant="body1"
                    sx={{ mt: 2 }}
                    dangerouslySetInnerHTML={{
                      __html: replacePlaceholders(emailContent.body) || "Body content here.",
                    }}
                  />
                </Paper>
              </Box>
            </Box>
          </Box>
        </Box>
      </Container>
    </Layout>
  );
};

export default TemplateEditPage;
