import React, { useState } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, List, ListItem, ListItemText, ListItemSecondaryAction, IconButton, TextField, Divider, Typography, Button, Box } from '@mui/material';
import { Delete, Edit, Add, ArrowBack } from '@mui/icons-material';

interface PaymentMethod {
  id: number;
  type: string;
  last4: string;
}

interface ManagePaymentMethodsProps {
  open: boolean;
  onClose: () => void;
}

const ManagePaymentMethods: React.FC<ManagePaymentMethodsProps> = ({ open, onClose }) => {
  const [paymentMethods, setPaymentMethods] = useState<PaymentMethod[]>([
    { id: 1, last4: '1234', type: 'Visa' },
    { id: 2, last4: '5678', type: 'MasterCard' },
  ]);
  const [newPaymentMethod, setNewPaymentMethod] = useState({ type: '', number: '' });
  const [isAddingNew, setIsAddingNew] = useState(false); // Track whether the user is adding a new payment method

  const handlePaymentMethodChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setNewPaymentMethod({ ...newPaymentMethod, [name]: value });
  };

  const handleAddPaymentMethod = () => {
    const newMethod = {
      id: paymentMethods.length + 1,
      last4: newPaymentMethod.number.slice(-4),
      type: newPaymentMethod.type,
    };
    setPaymentMethods([...paymentMethods, newMethod]);
    setNewPaymentMethod({ type: '', number: '' });
    setIsAddingNew(false); // Close the add new payment method form
  };

  const handleEditPaymentMethod = (methodId: number) => {
    console.log(`Edit payment method with id: ${methodId}`);
  };

  const handleDeletePaymentMethod = (methodId: number) => {
    setPaymentMethods(paymentMethods.filter((method) => method.id !== methodId));
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      {isAddingNew ? (
        <>
          {/* Add New Payment Method Form */}
          <DialogTitle>   <IconButton onClick={() => setIsAddingNew(false)}>
            <ArrowBack sx={{ fontSize: 20 }} />
          </IconButton>Add New Payment Method</DialogTitle>
           
        </>
      ) : <DialogTitle>Manage Payment Methods</DialogTitle>}

      <DialogContent>
        {isAddingNew ? (
          <>
            {/* Add New Payment Method Form */}
            
            <TextField
              fullWidth
              label="Card Type"
              name="type"
              value={newPaymentMethod.type}
              onChange={handlePaymentMethodChange}
              sx={{ mb: 2 }}
            />
            <TextField
              fullWidth
              label="Card Number"
              name="number"
              value={newPaymentMethod.number}
              onChange={handlePaymentMethodChange}
              sx={{ mb: 2 }}
              inputProps={{ maxLength: 16 }}
            />
          </>
        ) : (
          <>
            {/* List of current payment methods */}
            <List>
              {paymentMethods.map((method) => (
                <ListItem key={method.id} sx={{ borderBottom: '1px solid #e0e0e0' }}>
                  <ListItemText
                    primary={`${method.type} ending in ${method.last4}`}
                    secondary={`Card Type: ${method.type}`}
                  />
                  <ListItemSecondaryAction>
                    <IconButton edge="end" onClick={() => handleEditPaymentMethod(method.id)}>
                      <Edit />
                    </IconButton>
                    <IconButton edge="end" onClick={() => handleDeletePaymentMethod(method.id)}>
                      <Delete />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
              ))}
            </List>

            <Divider sx={{ my: 2 }} />

            <Button
              variant="outlined"
              color="primary"
              size="small"
              onClick={() => setIsAddingNew(true)} // Switch to "add new payment method" form
              startIcon={<Add />}
            >
              Add New Payment Method
            </Button>
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Close
        </Button>
        {isAddingNew ? (
          <>
            {/* Back button */}

            <Button
              onClick={handleAddPaymentMethod}
              color="primary"
              disabled={!newPaymentMethod.type || !newPaymentMethod.number}
            >
              Add Payment Method
            </Button>
          </>
        ) : null}
      </DialogActions>
    </Dialog>
  );
};

export default ManagePaymentMethods;
