import React, { useState } from 'react';
import { Box, Card, CardContent, Typography, IconButton, Menu, MenuItem } from '@mui/material';
import { DateRange, LocationOn, AttachMoney, MoreVert } from '@mui/icons-material';
import JobStatusChip from './JobStatusChip'; // Assuming this is your custom chip component
import { Job } from '../api/api';

interface JobCardProps {
  job: Job
  onJobAction?: (action: string) => void;
}

const JobCard: React.FC<JobCardProps> = ({ job, onJobAction }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleJobAction = (action: string) => {
    if (onJobAction)
      onJobAction(action);
    setAnchorEl(null);
  };

  return (
    <Card variant="outlined" sx={{ mb: 3, p: 1 }}>
      <CardContent sx={{ display: 'flex', alignItems: 'flex-start', flexWrap: 'wrap' }}>

        <Box sx={{ flexGrow: 1, minWidth: 0 }}>
          <Box sx={{ display: "flex", gap: 2, alignItems: "center", flexDirection: "row" }}>
            <Typography variant="h5" sx={{ fontWeight: 'bold', color: 'primary.main' }} >
              {job.job_title}
            </Typography>
            <JobStatusChip status={job.status} />
          </Box>


          <Typography pt={1} variant="subtitle1"  >
            {job.company_name}
          </Typography>


          <Typography pt={1} variant="body1" color="textSecondary" >
            {job.description}
          </Typography>

          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              gap: 1.5, // Adjusts spacing between items for a cleaner look
              flexWrap: 'wrap',
              backgroundColor: 'background.default', // Subtle background for separation
              paddingTop: 1, // Adds padding for spacing
            }}
          >
            {/* Posted Date */}
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <DateRange sx={{ color: 'primary.main' }} />
              <Typography variant="body2" sx={{ fontWeight: 500 }}>
                Posted on {new Date(job.posted_at).toLocaleDateString()}
              </Typography>
            </Box>

            {/* Location */}
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <LocationOn sx={{ color: 'secondary.main' }} />
              <Typography variant="body2" sx={{ fontWeight: 500 }}>
                {job.location || 'Location not specified'}
              </Typography>
            </Box>

            {/* Salary */}
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <AttachMoney sx={{ color: 'success.main' }} />
              <Typography variant="body2" sx={{ fontWeight: 500 }}>
                {job.salary ? `${job.salary}` : 'Salary not disclosed'}
              </Typography>
            </Box>
          </Box>

        </Box>

        {/* Action Buttons */}
        <IconButton onClick={handleMenuOpen}>
          <MoreVert />
        </IconButton>
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
        >
          <MenuItem onClick={() => handleJobAction('Activate Job')}>Activate Job</MenuItem>
          <MenuItem onClick={() => handleJobAction('Deactivate Job')}>Deactivate Job</MenuItem>
          <MenuItem onClick={() => handleJobAction('Delete Job')}>Delete Job</MenuItem>
        </Menu>
      </CardContent>
    </Card>
  );
};

export default JobCard;
