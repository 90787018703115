import React from "react";
import { Box, Typography, Grid, Card, CardContent, Button, Divider, List, ListItem, ListItemText } from "@mui/material";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';


interface Plan {
    title: string;
    price: string;
    description: string;
    features: string[];
    buttonText: string;
    highlighted?: boolean
}

interface SubscriptionPlansProps {
  plans: Plan[];
  onChangePlan: (planName: string) => void;
}

const SubscriptionPlans: React.FC<SubscriptionPlansProps> = ({ plans, onChangePlan }) => (
  <Box
    sx={{
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    }}
  >
    <Typography variant="h4" fontWeight={700} gutterBottom>
      Choose the Right Plan for Your Team
    </Typography>
    <Typography variant="subtitle1" color="text.secondary" mb={5}>
      Find the perfect subscription plan for your team’s needs.
    </Typography>
    <Grid container spacing={3} justifyContent="center">
      {plans.map((plan, idx) => (
        <Grid item xs={12} sm={6} md={3} key={idx}>
          <Card
            sx={{
              backgroundColor: plan.highlighted ? "#fff" : "#efefef",
              border: "1px solid #e0e0e0",
              boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
              borderRadius: 4,
              transition: "all 0.3s ease",
              "&:hover": {
                boxShadow: "0px 8px 16px rgba(0, 0, 0, 0.2)",
                transform: "translateY(-5px)",
                backgroundColor: "#fff",
              },
              maxWidth: 300,
              mx: "auto",
              textAlign: "center",
            }}
          >
            <CardContent sx={{ px: 2, py: 3 }}>
              {plan.highlighted && (
                <Typography
                  variant="caption"
                  sx={{
                    position: "absolute",
                    top: 16,
                    right: 16,
                    backgroundColor: "#7a56c5",
                    color: "#fff",
                    padding: "4px 8px",
                    borderRadius: "20px",
                    fontWeight: "bold",
                  }}
                >
                  Most Popular
                </Typography>
              )}
              <Typography variant="h6" fontWeight={600} gutterBottom>
                {plan.title}
              </Typography>
              <Typography variant="h4" fontWeight={700} gutterBottom>
                {plan.price}
              </Typography>
              <Divider sx={{ mb: 2 }} />
              <Typography
                variant="subtitle1"
                color="text.secondary"
                mb={3}
                sx={{ fontSize: "1rem" }}
              >
                {plan.description}
              </Typography>
              <List sx={{ p: 0, m: 0, mb: 3 }}>
                {plan.features.map((feature, i) => (
                  <React.Fragment key={i}>
                    <ListItem sx={{ padding: "4px 0", display: "flex", alignItems: "center" }}>
                      <CheckCircleIcon sx={{ color: "#7a56c5", mr: 1, fontSize: "20px" }} />
                      <ListItemText
                        primary={
                          <Typography variant="body1" fontWeight={500} color="text.primary" sx={{ fontSize: "0.95rem" }}>
                            {feature}
                          </Typography>
                        }
                      />
                    </ListItem>
                    {i < plan.features.length - 1 && <Divider sx={{ margin: "4px 0", ml: 4 }} />}
                  </React.Fragment>
                ))}
              </List>
              <Button
                onClick={() => onChangePlan(plan.title)}
                variant="contained"
                sx={{
                  px: 4,
                  py: 1,
                  fontWeight: 600,
                  borderRadius: 4,
                  bgcolor: "#6f42c1",
                  color: "#fff",
                  "&:hover": { bgcolor: "#5e30a0" },
                }}
              >
                {plan.buttonText}
              </Button>
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
  </Box>
);

export default SubscriptionPlans;
