import React, { ChangeEvent, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Typography, Box, Tabs, Tab, Accordion, AccordionDetails, AccordionSummary, Button, CircularProgress, FormGroup, Checkbox, FormControlLabel, IconButton, Radio, RadioGroup, Slider, TextField, Tooltip } from '@mui/material';
import { ExpandMore, Edit, Save, Delete, HelpOutline } from '@mui/icons-material';
import { Job, fetchJobById, fetchJobInterviewsByJobId, interviewDetail, interviewObj, fetchJobEmailTemplates, EmailTemplates, getEmailTemplates, updateJobEmailTemplate, updateJob } from '../api/api';
import Layout from '../components/Layout';
import JobQuestionsList from '../components/JobQuestionsList';
import InterviewDetail from '../components/InterviewDetail';
import EditableJobDetails from '../components/EditableJobDetails';
import JobCard from '../components/JobCard';
import InterviewSettings from '../components/InterviewSettings';
import EmailTemplatesAccordion from './EmailTemplatesAccordion';

const JobEdit: React.FC = () => {
    const { jobId } = useParams<{ jobId: string }>();
    const [job, setJob] = useState<Job | null>(null);
    const [interviewList, setInterviewList] = useState<interviewDetail[]>([]);
    const [selectedInterview, setSelectedInterview] = useState<interviewDetail | null>(null);
    const [selectedTab, setSelectedTab] = useState(0);
    const [selectedInterviewTab, setSelectedInterviewTab] = useState(0);

    const [isLoading, setIsLoading] = useState(true);
    const [emailTemplates, setemailTemplates] = useState<EmailTemplates[]>([])

    const [applicationReceivedTemplate, setApplicationReceivedTemplate] = useState('');
    const [applicationRejectedTemplate, setApplicationRejectedTemplate] = useState('');
    const [interviewConfirmationTemplate, setInterviewConfirmationTemplate] = useState('');
    const [offerEmailTemplate, setOfferEmailTemplate] = useState('');

    useEffect(() => {
        const fetchEmailTemplates = async () => {
            const emailTemplates = await getEmailTemplates()
            setemailTemplates(emailTemplates)
        }
        fetchEmailTemplates()
    }, [])
    useEffect(() => {
        const loadJobData = async () => {
            try {
                setIsLoading(true);
                if (jobId) {
                    const jobData = await fetchJobById(jobId);
                    const interviews = await fetchJobInterviewsByJobId(jobId);
                    setInterviewList(interviews);
                    setJob(jobData);
                    // TO DO set up email part in settings tab
                    const res = await fetchJobEmailTemplates(jobId);
                    console.log(res)
                    setApplicationReceivedTemplate(res.application_received_template_id)
                    setApplicationRejectedTemplate(res.application_rejected_template_id)
                    setInterviewConfirmationTemplate(res.interview_confirmation_template_id)
                    setOfferEmailTemplate(res.offer_email_template_id)

                }
            } catch (err) {
                console.error('Error loading job details', err);
            } finally {
                setIsLoading(false);
            }
        };

        loadJobData();
    }, [jobId]);

    const handleSaveJobDetails = (updatedJob: Job) => {
        updateJob(updatedJob.id, updatedJob.job_title, updatedJob.description, updatedJob.location, updatedJob.salary, updatedJob.language, updatedJob.requirements)
    };

    const handleJobAction = (action: string) => {
        switch (action) {
            case 'Activate Job':
                // Implement activation logic
                break;
            case 'Deactivate Job':
                // Implement deactivation logic
                break;
            case 'Delete Job':
                // Implement delete logic
                break;
            default:
                break;
        }
    };
    const handleSave = () => {
        // Your save logic here

        if (jobId)
            updateJobEmailTemplate(jobId, applicationReceivedTemplate,
                applicationRejectedTemplate,
                interviewConfirmationTemplate,
                offerEmailTemplate)

    };

    const handleCancel = () => {
        // Your cancel logic here (e.g., reset form or navigate away)
    };

    const handleSelectInterview = (interview: interviewDetail, index: number) => {
        setSelectedInterview(interview);
        setSelectedTab(index);  // Reset to first tab when switching interview
    };

    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
        setSelectedTab(newValue);
    };


    if (isLoading) return <CircularProgress sx={{ display: 'block', margin: 'auto', padding: 4 }} />;

    if (!job) return <div>Job not found</div>;

    return (
        <Layout>
            <Box sx={{ maxWidth: 1200, margin: 'auto' }}>
                <JobCard job={job} onJobAction={handleJobAction} />

                {/* Job Edit Section */}
                <Box sx={{ mt: 4 }}>
                    <Typography
                        variant="h4"
                        sx={{
                            mb: 3,
                            color: '#333',
                            fontWeight: 'bold',
                            textAlign: 'center',
                            textTransform: 'uppercase',
                        }}
                    >
                        Manage Job & Interview Details
                    </Typography>

                    {/* Tab Navigation */}
                    <Tabs value={selectedTab} onChange={handleTabChange} aria-label="Interview stages" centered>
                        <Tab label="Job Details" />
                        {interviewList.map((interview, index) => (
                            <Tab key={interview.id} label={interview.interview_name + " Interview"} onClick={() => handleSelectInterview(interview, index + 1)} />
                        ))}
                        <Tab label="Job Settings" />
                        <Tab label="Email Config" />

                    </Tabs>

                    {/* Content for Selected Tab */}
                    <Box sx={{ mt: 3 }}>
                        {selectedTab === 0 && (
                            <EditableJobDetails job={job} onSave={handleSaveJobDetails} />
                        )}


                        {selectedTab > 0 && selectedTab < interviewList.length + 1 && selectedInterview && (
                            <Box sx={{ mt: 4 }}>
                                {/* Section Title */}
                                <Typography variant="h5" sx={{ mb: 3, fontWeight: 'bold' }}>
                                    {selectedInterview.interview_name} - Interview Setup
                                </Typography>

                                {/* Tabs for Interview Details and Job-Related Questions */}
                                <Tabs
                                    value={selectedInterviewTab}
                                    onChange={(e, newValue) => setSelectedInterviewTab(newValue)}
                                    aria-label="Interview Setup Tabs"
                                    sx={{ mb: 3 }}
                                >
                                    <Tab label="Interview Questions" /> {/* Changed this label */}
                                    <Tab label="Interview Details" />

                                </Tabs>

                                {/* Interview Details Tab Content */}
                                {selectedInterviewTab === 1 && (
                                    <Box>

                                        <InterviewDetail interviews={selectedInterview} />
                                    </Box>
                                )}

                                {/* Job-Related Questions Tab Content */}
                                {jobId && selectedInterviewTab === 0 && (
                                    <Box>
                                        <JobQuestionsList jobId={jobId} interviewId={String(selectedInterview.id)} />
                                    </Box>
                                )}
                            </Box>
                        )}



                        {selectedTab === (interviewList.length + 1) && (
                            <Box sx={{ textAlign: 'center' }}>
                                <InterviewSettings />

                            </Box>
                        )}
                        {selectedTab === (interviewList.length + 2) && (
                            <Box sx={{ textAlign: 'center' }}>
                                <EmailTemplatesAccordion emailTemplates={emailTemplates} applicationReceivedTemplate={applicationReceivedTemplate} applicationRejectedTemplate={applicationRejectedTemplate}
                                    interviewConfirmationTemplate={interviewConfirmationTemplate} offerEmailTemplate={offerEmailTemplate}
                                    setApplicationReceivedTemplate={setApplicationReceivedTemplate} setApplicationRejectedTemplate={setApplicationRejectedTemplate} setInterviewConfirmationTemplate={setInterviewConfirmationTemplate} setOfferEmailTemplate={setOfferEmailTemplate} />
                                {/* Save and Cancel buttons */}
                                <Box sx={{ marginTop: 2 }}>
                                    <Button variant="contained" color="primary" onClick={(handleSave)}>Save</Button>
                                    <Button variant="outlined" color="secondary" onClick={handleCancel} sx={{ marginLeft: 2 }}>Cancel</Button>
                                </Box>
                            </Box>
                        )}
                    </Box>
                </Box>
            </Box>
        </Layout>
    );
};

export default JobEdit;
