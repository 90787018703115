import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Dashboard from './pages/Dashboard';
import CompanyList from './pages/CompanyList';
import CompanyDetail from './pages/CompanyDetail';
import CompanyRegistration from './pages/CompanyRegistration';
import JobList from './pages/JobList';
import JobDetail from './pages/JobDetail';
import JobUpload from './pages/JobUpload';
import IntervieweeList from './pages/IntervieweeList';
import IntervieweeItem from './components/IntervieweeItem';
import UserProfile from './pages/UserProfile';
import JobApplication from './pages/JobApplication';
import JobApplicantsList from './pages/JobApplicantsList';
import Login from './pages/Login';
import { useAuth } from './context/AuthContext';
import AddJob from './pages/AddJob';
import Jobs from './pages/Jobs';
import JobDetails from './pages/JobDetails';
import ApplicantDetails from './pages/ApplicantDetails';
import JobEdit from './pages/JobEdit';
import CompanyDashboard from './pages/CompanyDashboard';
import EmailTemplateEditor from './pages/EmailTemplateEditor';
import SavedTemplatesPage from './pages/SavedTemplatesPage';
import TemplateEditPage from './pages/TemplateEdit';
import TeamManagementPage from './pages/TeamManagementPage';
import SignIn from './pages/SignIn';
import BillingPage from './pages/BillingPage';
import Subscription from './pages/Subscription';
import Applicants from './pages/Applicants';

const AppRouter: React.FC = () => {
    const { isAuthenticated } = useAuth()
    const checkAuth = () => {
        if (isAuthenticated)
            return (
                <>
                    <Route path="/" element={<Dashboard />} />
                    <Route path="/company/dashboard" element={<CompanyDashboard />} />
                    <Route path="/company/team-management" element={<TeamManagementPage  />} />
                    <Route path="/company/billing" element={<BillingPage  />} />
                    <Route path="/company/subscriptions" element={<Subscription  />} />

                    
                    <Route path="/applicants/list" element={<Applicants />} />

                    <Route path="/companies/register" element={<CompanyRegistration />} />
                    <Route path="/companies/:companyId" element={<CompanyDetail />} />
                    <Route path="/companies" element={<CompanyList />} />
                    <Route path="/jobs" element={<JobList />} />
                    <Route path="/list/jobs" element={<Jobs />} />
                    <Route path="/jobs/:jobId" element={<JobDetail />} />
                    <Route path="/jobs/detail/:jobId" element={<JobDetails />} />
                    <Route path="/jobs/edit/:jobId" element={<JobEdit />} />
                    <Route path="/jobs/:jobID/interviewees" element={<IntervieweeList />} />
                    <Route path="/interviewees/:jobID/:userID" element={<IntervieweeItem />} />
                    <Route path="/applicants/:jobID/:userID" element={<ApplicantDetails />} />
                    <Route path="/users/:userID" element={<UserProfile />} />
                    <Route path="/apply/:jobID" element={<JobApplication />} />
                    <Route path="/jobs/:jobID/applicants" element={<JobApplicantsList />} />
                    <Route path="/companies/:companyId/add-job" element={<JobUpload />} />
                    <Route path="/add-job/" element={<AddJob />} />
                    <Route path="/create-email-template/" element={<EmailTemplateEditor />} />
                    <Route path="/email-templates/" element={<SavedTemplatesPage />} />
                    <Route path="/templates/edit/:templateId" element={<TemplateEditPage  />} />

                    
                    

                </>

            );
        return (
            <Route path="*" element={<SignIn />} />
        )
    }
    return (
        <Router>
            <Routes >
                {checkAuth()}
            </Routes>
        </Router>
    )

};

export default AppRouter;
