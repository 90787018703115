import { Delete, HelpOutline } from '@mui/icons-material';
import { Box, Tooltip, Typography, IconButton, TextField, FormGroup, FormControlLabel, Checkbox, RadioGroup, Radio, Slider, Button } from '@mui/material';
import React, { ChangeEvent, useState } from 'react';
import { interviewDetail } from '../api/api';

type Props = {
    interviews: interviewDetail;
};

const InterviewDetail: React.FC<Props> = ({ interviews }) => {
    const [interview, setInterview] = useState(interviews);
    const [originalInterview, setOriginalInterview] = useState(interviews); // Store the original state to revert changes
    const [isEditing, setIsEditing] = useState(false);

    // Revert changes to the original state
    const handleCancelEdit = () => {
        setInterview(originalInterview); // Revert to original data
        setIsEditing(false); // Exit editing mode
    };

    const handleInterviewNameChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setInterview({ ...interview, interview_name: event.target.value });
    };

    const handleToggleAutoQuestionCreationInterviewSetting = (value: boolean) => {
        setInterview({ ...interview, createquestionswithai: value });
    };

    const handleToggleAutoInviteInterviewSetting = (value: boolean) => {
        setInterview({ ...interview, autoinvitebasedonmatch: value });
    };

    const handleToggleFollowUpChange = (value: boolean) => {
        setInterview({ ...interview, enablefollowup: value });
    };

    const handleToggleCandidateQuestions = (value: boolean) => {
        setInterview({ ...interview, allowcandidatequestions: value });
    };

    const handleToggleEmotionalAnalysis = (value: boolean) => {
        setInterview({ ...interview, emotionalanalysis: value });
    };

    const handleAIDtoneChange = (value: string) => {
        setInterview({ ...interview, aitone: value });
    };

    const handleQuestionDifficultyChange = (value: string) => {
        setInterview({ ...interview, questiondifficulty: value });
    };

    const handleRemoveInterview = (id?: number) => {
        // Implement interview removal logic
    };

    const handleInterviewThresholdChange = (value: number | number[]) => {
        if (typeof value === 'number') {
            setInterview({ ...interview, matchthreshold: value });
        }
    };

    const handleSaveInterview = () => {
        // Implement save logic (e.g., API call to update interview details)
        setOriginalInterview(interview); // Update original data after saving
        setIsEditing(false); // Exit editing mode
    };

    const handleEditInterview = () => {
        setIsEditing(true);
    };

    return (
        <Box
            key={interview.id}
            sx={{
                marginBottom: 3,
                padding: 3,
                borderRadius: 2,
                bgcolor: '#ffffff',
                border: '1px solid #e0e0e0',
                boxShadow: 2,
                transition: 'all 0.2s ease',
                '&:hover': { boxShadow: 4 },
            }}
        >
            {/* Interview Header */}
            <Box display="flex" justifyContent="space-between" alignItems="center" marginBottom={2}>
                <Typography variant="h6" fontWeight="500" color="textPrimary">
                    {`${interview.interview_name} Interview`}
                </Typography>
                <Box>
                    {isEditing ? (
                        <>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={handleSaveInterview}
                                sx={{ marginRight: 2 }}
                            >
                                Save
                            </Button>
                            <Button
                                variant="outlined"
                                color="secondary"
                                onClick={handleCancelEdit} // Cancel changes
                            >
                                Cancel
                            </Button>
                        </>
                    ) : (
                        <Button
                            variant="outlined"
                            color="secondary"
                            onClick={handleEditInterview}
                            sx={{ marginRight: 2 }}
                        >
                            Edit
                        </Button>
                    )}
                    <IconButton
                        aria-label="Remove Interview"
                        color="secondary"
                        onClick={() => handleRemoveInterview(interview.id)}
                        sx={{
                            ':hover': {
                                backgroundColor: 'transparent',
                                boxShadow: 2,
                            },
                        }}
                    >
                        <Delete />
                    </IconButton>
                </Box>
            </Box>

            {/* Interview Name */}
            <TextField
                fullWidth
                label="Interview Type (e.g., Technical, Cultural)"
                variant="outlined"
                value={interview.interview_name}
                onChange={handleInterviewNameChange}
                margin="normal"
                size="small"
                sx={{
                    bgcolor: '#f7f7f7',
                    borderRadius: 2,
                    marginBottom: 2,
                }}
                disabled={!isEditing} // Disable when not editing
            />

            {/* Interview Settings */}
            <Box marginTop={2}>
                <FormGroup>
                    {/* AI Generated Questions */}
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={interview.createquestionswithai}
                                onChange={(e) => handleToggleAutoQuestionCreationInterviewSetting(e.target.checked)}
                                disabled={!isEditing} // Disable when not editing
                            />
                        }
                        label={
                            <Box display="flex" alignItems="center" justifyContent="space-between">
                                <Typography variant="body2" color="textSecondary" sx={{ marginRight: 2 }}>
                                    Would you like AI to generate interview questions for this stage?
                                </Typography>
                                <Tooltip title="Enable this option to let AI create tailored questions for this interview stage based on the job description and requirements.">
                                    <HelpOutline fontSize="small" style={{ cursor: 'pointer' }} />
                                </Tooltip>
                            </Box>
                        }
                    />
                    {interview.createquestionswithai && (
                        <Box marginTop={1} paddingLeft="15px">
                            <Typography variant="body2" color="textPrimary" marginBottom={1}>
                                Interview Question Complexity
                            </Typography>
                            <RadioGroup
                                value={interview.questiondifficulty}
                                onChange={(e) => handleQuestionDifficultyChange(e.target.value)}
                                row
                                sx={{
                                    padding: '5px 0', // Add padding around options
                                }}
                            >
                                <FormControlLabel value="easy" control={<Radio disabled={!isEditing} />} label="Easy" sx={{ padding: '5px 0' }} />
                                <FormControlLabel value="medium" control={<Radio disabled={!isEditing} />} label="Medium" sx={{ padding: '5px 0' }} />
                                <FormControlLabel value="hard" control={<Radio disabled={!isEditing} />} label="Hard" sx={{ padding: '5px 0' }} />
                            </RadioGroup>
                        </Box>
                    )}

                    {/* Auto Invite */}
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={interview.autoinvitebasedonmatch}
                                onChange={(e) => handleToggleAutoInviteInterviewSetting(e.target.checked)}
                                disabled={!isEditing} // Disable when not editing
                            />
                        }
                        label={
                            <Box display="flex" alignItems="center" justifyContent="space-between">
                                <Typography variant="body2" color="textSecondary" sx={{ marginRight: 2 }}>
                                    Automatically invite candidates who meet the match criteria
                                </Typography>
                                <Tooltip title="Enable this option to automatically send interview invitations to candidates who match the specified criteria, such as CV or previous interview performance.">
                                    <HelpOutline fontSize="small" style={{ cursor: 'pointer' }} />
                                </Tooltip>
                            </Box>
                        }
                    />

                    {/* Match Threshold (only if auto-invite is enabled) */}
                    {interview.autoinvitebasedonmatch && (
                        <Box marginTop={1} paddingLeft="35px">
                            <Typography variant="body2" color="textPrimary" marginBottom={1}>
                                Candidate Match Threshold
                            </Typography>
                            <Typography variant="body2" color="textSecondary" marginBottom={1}>
                                Match Threshold: {interview.matchthreshold || 0}%
                            </Typography>
                            <Slider
                                value={interview.matchthreshold || 0}
                                onChange={(e, newValue) => handleInterviewThresholdChange(newValue)}
                                aria-labelledby="match-threshold-slider"
                                valueLabelDisplay="auto"
                                step={5}
                                marks
                                min={30}
                                max={100}
                                sx={{
                                    width: '100%',
                                    marginTop: 2,
                                    '& .MuiSlider-thumb': {
                                        backgroundColor: '#fff',
                                    },
                                }}
                                disabled={!isEditing} // Disable when not editing
                            />
                        </Box>
                    )}
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={interview.enablefollowup}
                                onChange={(e) => handleToggleFollowUpChange(e.target.checked)}
                                disabled={!isEditing} // Disable when not editing
                            />
                        }
                        label={
                            <Box display="flex" alignItems="center" justifyContent="space-between">
                                <Typography variant="body2" color="textSecondary" sx={{ marginRight: 2 }}>
                                    Enable Follow-up Reminders for Candidates
                                </Typography>
                                <Tooltip title="Enable this option to send automated follow-up reminders to candidates after the interview">
                                    <HelpOutline fontSize="small" style={{ cursor: 'pointer' }} />
                                </Tooltip>
                            </Box>
                        }
                    />

                    {/* Allow Candidate to Ask Questions */}
                    <Typography variant="body2" color="textSecondary" marginBottom={1}>
                        Allow Candidate to Ask Questions
                    </Typography>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={interview.allowcandidatequestions}
                                onChange={(e) => handleToggleCandidateQuestions(e.target.checked)}
                                disabled={!isEditing} // Disable when not editing
                            />
                        }
                        label={
                            <Typography variant="body2" color="textSecondary">
                                Allow the candidate to ask questions during the interview.
                            </Typography>
                        }
                    />
                    {/* Emotional Analysis */}
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={interview.emotionalanalysis}
                                onChange={(e) => handleToggleEmotionalAnalysis(e.target.checked)}
                                disabled={!isEditing} // Disable when not editing
                            />
                        }
                        label="Enable Emotional Analysis for the Interview"
                    />
                </FormGroup>
            </Box>
        </Box>
    );
};

export default InterviewDetail;
