import React, { useState } from 'react';
import { Box, Card, CardContent, Typography, IconButton, Menu, MenuItem, Chip } from '@mui/material';
import { AttachMoney, DateRange, LocationOn, MoreVert } from '@mui/icons-material';
import { Job } from '../api/api';


interface JobCardProps {
  job: Job;
}

const JobCardMini: React.FC<JobCardProps> = ({ job }) => {


  return (
    <Card
      variant="outlined"
      sx={{
        mb: 2,
        borderRadius: 2,
        boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
        transition: 'transform 0.2s, box-shadow 0.2s',

      }}
    >
      <Box
        sx={{
          backgroundColor: 'primary.light',
          color: 'primary.contrastText',
          px: 2,
          py: 1.5,
          borderTopLeftRadius: 'inherit',
          borderTopRightRadius: 'inherit',
        }}
      >
        <Typography variant="h6" sx={{ fontWeight: 'bold', textTransform: 'capitalize' }}>
          {job.job_title}
        </Typography>
      </Box>

      <CardContent>
        <Box sx={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'space-between' }}>
          {/* Company Name */}
          <Typography variant="subtitle1" sx={{ fontWeight: 600, color: 'text.primary' }}>
            {job.company_name}
          </Typography>

          {/* Action Menu */}

        </Box>

        {/* Job Description */}

        {/* Details Section */}
        <Box
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            gap: 2,
            mt: 2,
            backgroundColor: 'background.paper',
            padding: 1.5,
            borderRadius: 1,
            boxShadow: 'inset 0px 1px 4px rgba(0, 0, 0, 0.1)',
          }}
        >
          {/* Posted Date */}
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <DateRange sx={{ color: 'info.main' }} />
            <Typography variant="body2">Posted: {new Date(job.posted_at).toLocaleDateString()}</Typography>
          </Box>

          {/* Location */}
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <LocationOn sx={{ color: 'warning.main' }} />
            <Typography variant="body2">{job.location || 'Location not specified'}</Typography>
          </Box>

          {/* Salary */}
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <AttachMoney sx={{ color: 'success.main' }} />
            <Typography variant="body2">{job.salary || 'Not disclosed'}</Typography>
          </Box>
        </Box>

        {/* Job Status */}
        <Box sx={{ mt: 2, display: 'flex', alignItems: 'center' }}>
          <Chip
            label={"Active"}
            
            color={job.status !== 0 ? 'success' : 'error'}
            size="small"
            sx={{ fontWeight: 'bold',px:1, textTransform: 'uppercase' }}
          />
        </Box>
        <Typography
          variant="body2"
          sx={{
            mt: 1,
            color: 'text.secondary',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
          }}
        >
          {job.description || 'No description provided.'}
        </Typography>

      </CardContent>
    </Card>
  );
};

export default JobCardMini;
