import React, { useState } from 'react';
import {
    Box,
    Typography,
    Divider,
    Grid,
    TextField,
    Button,
    Card,
    CardContent,
    IconButton,
    Link
} from '@mui/material';
import { Save, Cancel, LocationOn, EditTwoTone } from '@mui/icons-material';
import { Company } from '../api/api';

interface CompanyDetailsCardProps {
    company: Company;
    handleSaveCompany: (formData: any) => void;
}

const DEFAULT_BANNER_URL = 'https://picsum.photos/1200/400?random=5';
const DEFAULT_ICON_URL = 'https://picsum.photos/150/150?random=2';
const CompanyDetailsCard: React.FC<CompanyDetailsCardProps> = ({
    company,
    handleSaveCompany,
}) => {
    const [isEditing, setIsEditing] = useState(false);
    const [formData, setFormData] = useState({
        name: company.name || '',
        description: company.description || '',
        location: company.location || '',
        website_url: company.website_url || '',
        sector: company.sector || '',
        num_employees: company.num_employees || '',
        type: company.type || '',
        contact_info: company.contact_info || '',
    });

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setFormData((prev) => ({ ...prev, [name]: value }));
    };


    return (
        <>
            <Box
                sx={{
                    position: 'relative',
                    height: '150px',
                    backgroundImage: `linear-gradient(to bottom, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.8)), url(${company.banner_url || DEFAULT_BANNER_URL})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    borderRadius: 2,
                    mb: 4,
                    color: '#fff',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'flex-end',
                    padding: 3,
                }}
            >
                {/* Company Icon and Name */}
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                    <Box
                        sx={{
                            width: '100px',
                            height: '100px',
                            borderRadius: '50%',
                            overflow: 'hidden',
                            boxShadow: 3,
                            border: '3px solid #fff',
                            mr: 3,
                        }}
                    >
                        <img
                            src={company.icon_url || DEFAULT_ICON_URL}
                            alt={`${company.name} Icon`}
                            style={{ width: '100%', height: '100%' }}
                        />
                    </Box>
                    <Box>
                        <Typography variant="h4" component="h1" sx={{ fontWeight: 'bold' }}>
                            {company.name || 'Company Name'}
                        </Typography>

                        {/* Company Info - Location and Website Side by Side */}
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 3, mt: 1 }}>
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <LocationOn sx={{ mr: 1, color: '#ffcc00' }} />
                                <Typography variant="body1">
                                    {company.location || <i style={{ color: '#aaa' }}>Location not specified</i>}
                                </Typography>
                            </Box>
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <Link sx={{ mr: 1, color: '#00ccff' }} />
                                <Typography
                                    variant="body1"
                                    component="a"
                                    href={company.website_url || '#'}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    sx={{
                                        color: company.website_url ? '#00ccff' : '#aaa',
                                        textDecoration: company.website_url ? 'underline' : 'none',
                                    }}
                                >
                                    {company.website_url || 'Website not available'}
                                </Typography>
                            </Box>
                        </Box>
                    </Box>
                </Box>

                {/* Floating Settings Button */}
                <IconButton
                    onClick={() => setIsEditing((prev) => !prev)}
                    sx={{
                        position: 'absolute',
                        top: 16,
                        right: 16,
                        backgroundColor: 'rgba(255, 255, 255, 0.2)',
                        color: '#fff',
                        '&:hover': { backgroundColor: 'rgba(255, 255, 255, 0.3)' },
                    }}
                >
                    <EditTwoTone />
                </IconButton>


            </Box>

            <Card variant="outlined" sx={{ mb: 4 }}>
                <CardContent>
                    {isEditing ? (
                        <Box sx={{ mt: 2 }}>
                            <Grid container spacing={3}>
                                {/* Name and Location */}
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        label="Name"
                                        name="name"
                                        fullWidth
                                        value={formData.name}
                                        onChange={handleInputChange}
                                        sx={{ mb: 2 }}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        label="Location"
                                        name="location"
                                        fullWidth
                                        value={formData.location}
                                        onChange={handleInputChange}
                                        sx={{ mb: 2 }}
                                    />
                                </Grid>

                                {/* Website URL and Sector */}
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        label="Website URL"
                                        name="website_url"
                                        fullWidth
                                        value={formData.website_url}
                                        onChange={handleInputChange}
                                        sx={{ mb: 2 }}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        label="Sector"
                                        name="sector"
                                        fullWidth
                                        value={formData.sector}
                                        onChange={handleInputChange}
                                        sx={{ mb: 2 }}
                                    />
                                </Grid>

                                {/* Number of Employees and Company Type */}
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        label="Number of Employees"
                                        name="num_employees"
                                        fullWidth
                                        value={formData.num_employees}
                                        onChange={handleInputChange}
                                        sx={{ mb: 2 }}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        label="Company Type"
                                        name="type"
                                        fullWidth
                                        value={formData.type}
                                        onChange={handleInputChange}
                                        sx={{ mb: 2 }}
                                    />
                                </Grid>

                                {/* Contact Information */}
                                <Grid item xs={12}>
                                    <TextField
                                        label="Contact Information"
                                        name="contact_info"
                                        fullWidth
                                        multiline
                                        rows={2}
                                        value={formData.contact_info}
                                        onChange={handleInputChange}
                                        sx={{ mb: 2 }}
                                    />
                                </Grid>

                                {/* Description */}
                                <Grid item xs={12}>
                                    <TextField
                                        label="Description"
                                        name="description"
                                        fullWidth
                                        multiline
                                        rows={3}
                                        value={formData.description}
                                        onChange={handleInputChange}
                                        sx={{ mb: 2 }}
                                    />
                                </Grid>
                            </Grid>

                            {/* Buttons */}
                            <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 2, mt: 3 }}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() => { handleSaveCompany(formData); setIsEditing(false) }}
                                    sx={{ padding: '8px 16px', fontWeight: 'bold' }}
                                    startIcon={<Save />}
                                >
                                    Save Changes
                                </Button>
                                <Button
                                    variant="outlined"
                                    color="secondary"
                                    onClick={() => setIsEditing(false)}
                                    sx={{ padding: '8px 16px', fontWeight: 'bold' }}
                                    startIcon={<Cancel />}
                                >
                                    Cancel
                                </Button>
                            </Box>
                        </Box>
                    ) : (
                        <Grid container spacing={3}>
                            <Grid item xs={12} md={12}>
                                <Typography variant="h6" gutterBottom>
                                    About Us
                                </Typography>
                                <Divider />
                            </Grid>
                            {/* Static View */}
                            <Grid item xs={12} md={6}>
                                <Typography variant="h6" gutterBottom>
                                    Sector
                                </Typography>
                                <Typography paragraph>{company.sector || 'Not specified'}</Typography>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Typography variant="h6" gutterBottom>
                                    Number of Employees
                                </Typography>
                                <Typography paragraph>{company.num_employees || 'Not specified'}</Typography>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Typography variant="h6" gutterBottom>
                                    Created On
                                </Typography>
                                <Typography paragraph>{company.creation_date || 'Not specified'}</Typography>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Typography variant="h6" gutterBottom>
                                    Company Type
                                </Typography>
                                <Typography paragraph>{company.type || 'Not specified'}</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="h6" gutterBottom>
                                    Contact Information
                                </Typography>
                                <Typography paragraph>{company.contact_info || 'Not specified'}</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="h6" gutterBottom>
                                    About Us
                                </Typography>
                                <Typography paragraph>{company.description || 'The Company where dreams come true'}</Typography>
                            </Grid>
                        </Grid>
                    )}
                </CardContent>
            </Card>

        </>
    );
};

export default CompanyDetailsCard;
