import React from 'react';
import { ThemeProvider } from '@mui/material/styles';
import theme from './theme'; // Import your theme
import { AuthProvider } from './context/AuthContext';
import AppRouter from './Router';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

const App: React.FC = () => {
    return (
        <ThemeProvider theme={theme}>
            <AuthProvider>
               <AppRouter />
            </AuthProvider>
        </ThemeProvider>
    );
};

export default App;
