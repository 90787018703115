import React, { useEffect, useState } from 'react';
import { Container, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, Typography, TableSortLabel, TextField, TablePagination } from '@mui/material';
import { Link } from 'react-router-dom';
import Layout from '../components/Layout';
import { EmailTemplates, fetchPlaceholders, getEmailTemplates, Placeholder } from '../api/api';
 

const SavedTemplatesPage = () => {
    const [templates, setTemplates] = useState<EmailTemplates[]>([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('name');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);

    const replacePlaceholders = (emails: EmailTemplates[], placeholder: Placeholder[]): EmailTemplates[] => {
        return emails.map((email) => {
            placeholder.forEach(({ name, placeholder }) => {
                email.body = email.body.replaceAll(name, placeholder);
                email.subject = email.subject.replaceAll(name, placeholder);
            })
            return email
        }
        )


    };

    useEffect(() => {
        const fetchEmailTemplates = async () => {
            const emailTemplates = await getEmailTemplates()
            const placeHolders = await fetchPlaceholders()
            console.log(placeHolders, "placeHolders")
            console.log("emailTemplates", emailTemplates)
            setTemplates(replacePlaceholders(emailTemplates, placeHolders))
        }
        fetchEmailTemplates()
    }, [])


    // Handle sorting
    const handleRequestSort = (property: string) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    // Sorting function
    const sortTemplates = (array: EmailTemplates[]) => {
        return array.sort((a, b) => {
            if (orderBy === 'name') {
                return order === 'asc' ? a.name.localeCompare(b.name) : b.name.localeCompare(a.name);
            } else if (orderBy === 'subject') {
                return order === 'asc' ? a.subject.localeCompare(b.subject) : b.subject.localeCompare(a.subject);
            }
            else if (orderBy === 'type') {
                return order === 'asc' ? a.type.localeCompare(b.type) : b.type.localeCompare(a.type);
            }
            return 0;
        });
    };

    // Handle search
    const filteredTemplates = templates
        .filter((template) =>
            template.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
            template.subject.toLowerCase().includes(searchTerm.toLowerCase()) || template.type.toLowerCase().includes(searchTerm.toLowerCase()) || template.body.toLowerCase().includes(searchTerm.toLowerCase())
        );

    // Handle pagination change
    const handleChangePage = (event: any, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: any) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    return (
        <Layout>
            <Container maxWidth="md">
                <Typography variant="h4" sx={{ mb: 3 }}>Saved Email Templates</Typography>

                {/* Search Bar */}
                <TextField
                    label="Search Templates"
                    variant="outlined"
                    fullWidth
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    sx={{ mb: 3 }}
                />

                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>
                                    <TableSortLabel
                                        active={orderBy === 'type'}
                                        direction={'asc'}
                                        onClick={() => handleRequestSort('type')}
                                    >
                                        Type
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell>
                                    <TableSortLabel
                                        active={orderBy === 'name'}
                                        direction={'asc'}
                                        onClick={() => handleRequestSort('name')}
                                    >
                                        Name
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell>
                                    <TableSortLabel
                                        active={true}
                                        direction={'asc'}
                                        onClick={() => handleRequestSort('subject')}
                                    >
                                        Subject
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell>Description</TableCell>
                                <TableCell>Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {sortTemplates(filteredTemplates)
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((template) => (
                                    <TableRow key={template.id}>
                                        <TableCell>{template.type}</TableCell>
                                        <TableCell>{template.name}</TableCell>
                                        <TableCell>{template.subject}</TableCell>
                                        <TableCell>{template.body.substring(0, 50)}...</TableCell>
                                        <TableCell>
                                            <Button
                                                size="small"
                                                color="primary"
                                                component={Link}
                                                to={`/templates/edit/${template.id}`}
                                            >
                                                Edit
                                            </Button>
                                            <Button
                                                size="small"
                                                color="error"
                                                onClick={() => alert(`Template with ID ${template.id} deleted!`)}
                                                sx={{ ml: 1 }}
                                            >
                                                Delete
                                            </Button>
                                        </TableCell>
                                    </TableRow>
                                ))}
                        </TableBody>
                    </Table>
                </TableContainer>

                {/* Pagination */}
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={filteredTemplates.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Container>
        </Layout>
    );
};

export default SavedTemplatesPage;
