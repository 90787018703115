import React, { ChangeEvent, useState } from 'react';
import {
    Box,
    Typography,
    IconButton,
    TextField,
    Checkbox,
    FormControlLabel,
    FormGroup,
    RadioGroup,
    FormControl,
    Radio,
    Slider,
    Tooltip,
    Button
} from '@mui/material';
import { Delete, HelpOutline, Save } from '@mui/icons-material';
import { interviewObj } from '../api/api';

interface InterviewSettingsProps {

}

const InterviewSettings: React.FC<InterviewSettingsProps> = ({

}) => {
    const [interviews, setInterviews] = useState<interviewObj[]>([]); // Initial interview with name and one question

    const handleAddInterview = () => {

        setInterviews([...interviews, { name: "", questions: [] }]);
        //setinterviews.length(interviews.length + 1);
    };

    const handleInterviewNameChange = (index: number, event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const newInterviews = [...interviews];
        newInterviews[index].name = event.target.value;
        setInterviews(newInterviews);
    };

    const handleToggleAutoQuestionCreationInterviewSetting = (index: number, value: boolean) => {
        const newInterviews = interviews.map((val, _index) => {
            if (index === _index) {
                val.createQuestionsWithAI = value
            }
            return val
        })
        setInterviews(newInterviews);
    }
    const handleToggleAutoInviteInterviewSetting = (index: number, value: boolean) => {
        const newInterviews = interviews.map((val, _index) => {
            if (index === _index) {
                val.autoInviteBasedOnMatch = value
            }
            return val
        })
        setInterviews(newInterviews);
    }
    const handleToggleFollowUpChange = (index: number, value: boolean) => {
        const newInterviews = interviews.map((val, _index) => {
            if (index === _index) {
                val.enableFollowUp = value
            }
            return val
        })
        setInterviews(newInterviews);
    }

    const handleToggleCandidateQuestions = (index: number, value: boolean) => {
        const newInterviews = interviews.map((val, _index) => {
            if (index === _index) {
                val.allowCandidateQuestions = value
            }
            return val
        })
        setInterviews(newInterviews);
    }

    const handleToggleEmotionalAnalysis = (index: number, value: boolean) => {
        const newInterviews = interviews.map((val, _index) => {
            if (index === _index) {
                val.emotionalAnalysis = value
            }
            return val
        })
        setInterviews(newInterviews);
    }


    const handleAIDtoneChange = (index: number, value: string) => {
        const newInterviews = interviews.map((val, _index) => {
            if (index === _index) {
                val.aiTone = value
            }
            return val
        })
        setInterviews(newInterviews);
    }

    const handleQuestionDifficultyChange = (index: number, value: string) => {
        const newInterviews = interviews.map((val, _index) => {
            if (index === _index) {
                val.questionDifficulty = value
            }
            return val
        })
        setInterviews(newInterviews);
    }



    const handleRemoveInterview = (index: number) => {
        const newInterviews = interviews.filter((val, _index) => index !== _index)
        setInterviews(newInterviews);
    }
    const handleInterviewThresholdChange = (index: number, value: number | number[]) => {

        const newInterviews = interviews.map((val, _index) => {
            if (index === _index) {
                if (typeof value === "number")
                    val.matchThreshold = value
            }
            return val
        })
        setInterviews(newInterviews);
        // const newInterviews = interviews.filter((val, _index) => index !== _index)
        // setInterviews(newInterviews);
    }

    const handleSaveJobDetails = () => {
        console.log("Job details saved");
    };
    return (
        <Box sx={{ textAlign: 'center' }}>
            <Typography variant="h5" sx={{ mb: 2 }}>
                Job Settings
            </Typography>
            <Box sx={{ padding: 3, bgcolor: '#fff' }}>
                {Array.from({ length: interviews.length }, (_, index) => (
                    <Box
                        key={index}
                        sx={{
                            marginBottom: 3,
                            padding: 3,
                            borderRadius: 2,
                            bgcolor: '#ffffff',
                            border: '1px solid #e0e0e0',
                            boxShadow: 2,
                            transition: 'all 0.2s ease',
                            '&:hover': { boxShadow: 4 },
                        }}
                    >
                        {/* Interview Header */}
                        <Box display="flex" justifyContent="space-between" alignItems="center" marginBottom={2}>
                            <Typography variant="h6" fontWeight="500" color="textPrimary">
                                {`${interviews[index].name} Interview`}
                            </Typography>
                            <IconButton
                                aria-label="Remove Interview"
                                color="secondary"
                                onClick={() => handleRemoveInterview(index)}
                                sx={{
                                    ':hover': {
                                        backgroundColor: 'transparent',
                                        boxShadow: 2,
                                    },
                                }}
                            >
                                <Delete />
                            </IconButton>
                        </Box>

                        {/* Interview Name */}
                        <TextField
                            fullWidth
                            label="Interview Type (e.g., Technical, Cultural)"
                            variant="outlined"
                            value={interviews[index].name}
                            onChange={(e) => handleInterviewNameChange(index, e)}
                            margin="normal"
                            size="small"
                            sx={{
                                bgcolor: '#f7f7f7',
                                borderRadius: 2,
                                marginBottom: 2,
                            }}
                        />

                        {/* Interview Settings */}
                        <Box marginTop={2}>
                            <FormGroup>
                                {/* AI Generated Questions */}
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={interviews[index].createQuestionsWithAI}
                                            onChange={(e) =>
                                                handleToggleAutoQuestionCreationInterviewSetting(index, e.target.checked)
                                            }
                                        />
                                    }
                                    label={
                                        <Box display="flex" alignItems="center" justifyContent="space-between">
                                            <Typography variant="body2" color="textSecondary" sx={{ marginRight: 2 }}>
                                                Would you like AI to generate interview questions for this stage?
                                            </Typography>
                                            <Tooltip title="Enable this option to let AI create tailored questions for this interview stage based on the job description and requirements.">
                                                <HelpOutline fontSize="small" style={{ cursor: 'pointer' }} />
                                            </Tooltip>
                                        </Box>
                                    }
                                />
                                {interviews[index].createQuestionsWithAI && (
                                    <Box sx={{ textAlign: 'left' }} marginTop={1} paddingLeft="15px">
                                        <Typography variant="body2" color="textPrimary" marginBottom={1}>
                                            Interview Question Complexity
                                        </Typography>
                                        <RadioGroup
                                            value={interviews[index].questionDifficulty}
                                            onChange={(e) => handleQuestionDifficultyChange(index, e.target.value)}
                                            row
                                            sx={{
                                                padding: '5px 0', // Add padding around options
                                            }}
                                        >
                                            <FormControlLabel value="easy" control={<Radio />} label="Easy" sx={{ padding: '5px 0' }} />
                                            <FormControlLabel value="medium" control={<Radio />} label="Medium" sx={{ padding: '5px 0' }} />
                                            <FormControlLabel value="hard" control={<Radio />} label="Hard" sx={{ padding: '5px 0' }} />
                                        </RadioGroup>
                                    </Box>
                                )}

                                {/* Auto Invite */}
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={interviews[index].autoInviteBasedOnMatch}
                                            onChange={(e) =>
                                                handleToggleAutoInviteInterviewSetting(index, e.target.checked)
                                            }
                                        />
                                    }
                                    label={
                                        <Box display="flex" alignItems="center" justifyContent="space-between">
                                            <Typography variant="body2" color="textSecondary" sx={{ marginRight: 2 }}>
                                                Automatically invite candidates who meet the match criteria
                                            </Typography>
                                            <Tooltip title="Enable this option to automatically send interview invitations to candidates who match the specified criteria, such as CV or previous interview performance.">
                                                <HelpOutline fontSize="small" style={{ cursor: 'pointer' }} />
                                            </Tooltip>
                                        </Box>
                                    }
                                />

                                {/* Match Threshold (only if auto-invite is enabled) */}
                                {interviews[index].autoInviteBasedOnMatch && (
                                    <Box sx={{ textAlign: 'left' }} marginTop={1} paddingLeft="35px">
                                        <Typography variant="body2" color="textPrimary" marginBottom={1}>
                                            Candidate Match Threshold
                                        </Typography>
                                        <Typography variant="body2" color="textSecondary" marginBottom={1}>
                                            Match Threshold: {interviews[index].matchThreshold || 0}%
                                        </Typography>
                                        <Slider
                                            value={interviews[index].matchThreshold || 0}
                                            onChange={(e, newValue) => handleInterviewThresholdChange(index, newValue)}
                                            aria-labelledby="match-threshold-slider"
                                            valueLabelDisplay="auto"
                                            step={5}
                                            marks
                                            min={30}
                                            max={100}
                                            sx={{
                                                width: '100%',
                                                marginTop: 2,
                                                '& .MuiSlider-thumb': {
                                                    backgroundColor: '#fff',
                                                },
                                            }}
                                        />
                                    </Box>
                                )}

                                {/* Other settings */}
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={interviews[index].enableFollowUp}
                                            onChange={(e) => handleToggleFollowUpChange(index, e.target.checked)}
                                        />
                                    }
                                    label="Enable AI to ask follow-up questions based on candidate responses"
                                />

                                <Box marginTop={2} sx={{ textAlign: 'left' }}>
                                    <Typography variant="body2" color="textPrimary" marginBottom={1}>
                                        AI Interview Tone
                                    </Typography>
                                    <RadioGroup
                                        value={interviews[index].aiTone}
                                        onChange={(e) => handleAIDtoneChange(index, e.target.value)}
                                        row
                                    >
                                        <FormControlLabel value="formal" control={<Radio />} label="Formal" />
                                        <FormControlLabel value="casual" control={<Radio />} label="Casual" />
                                        <FormControlLabel value="friendly" control={<Radio />} label="Friendly" />
                                        <FormControlLabel value="motivational" control={<Radio />} label="Motivational" />
                                    </RadioGroup>
                                </Box>

                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={interviews[index].allowCandidateQuestions}
                                            onChange={(e) => handleToggleCandidateQuestions(index, e.target.checked)}
                                        />
                                    }
                                    label="Allow candidate questions during the interview"
                                />

                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={interviews[index].emotionalAnalysis}
                                            onChange={(e) => handleToggleEmotionalAnalysis(index, e.target.checked)}
                                        />
                                    }
                                    label="Enable AI to analyze candidate's emotional state"
                                />
                            </FormGroup>
                        </Box>
                    </Box>
                ))}
                <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    marginTop={3}
                    sx={{ width: '100%' }}
                >
                    {/* Add Interview Button */}
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleAddInterview}
                        sx={{ width: 'auto', marginRight: 2 }}
                    >
                        Add Interview
                    </Button>

                    {/* Save Button */}
                    <Button
                        variant="contained"
                        color="secondary"
                        onClick={handleSaveJobDetails}
                        sx={{ width: 'auto' }}
                    >
                        Save
                    </Button>
                </Box>

            </Box>

        </Box>
    );
};

export default InterviewSettings;
