import React, { useState, ChangeEvent, useEffect } from 'react';
import { Button, TextField, Container, Typography, Alert, CircularProgress, Box, Stepper, Step, StepLabel, IconButton, FormGroup, FormControlLabel, Checkbox, Slider, Tooltip, RadioGroup, Radio, Grid, FormControl, InputLabel, Select, MenuItem, Accordion, AccordionDetails, AccordionSummary, Paper, Divider } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import Layout from '../components/Layout';
import InterviewQuestionsList from '../components/InterviewQuestionsList';
import { EmailTemplates, getEmailTemplates, getInterviewQuestions, interviewObj, newJobAdvert } from '../api/api';
import { useAuth } from '../context/AuthContext';
import { CheckCircle, Delete, ErrorOutline, ExpandMore, HelpOutline } from '@mui/icons-material';
import { replacePlaceholders } from '../utils';
import EmailTemplatesAccordion from './EmailTemplatesAccordion';
import JobDetailsForm from '../components/JobDetailsForm';
import Loading from '../components/Loading';


const AddJobWizard: React.FC = () => {
    const [jobTitle, setJobTitle] = useState('');
    const [description, setDescription] = useState('');
    const [requirements, setRequirements] = useState('');
    const [location, setLocation] = useState('');
    const [salary, setSalary] = useState('');
    const [fetching, setfetching] = useState(false)
    const [language, setLanguage] = useState('en'); // Default language
    const [interviews, setInterviews] = useState<interviewObj[]>([{ name: "", questions: [] }]); // Initial interview with name and one question
    const [activeStep, setActiveStep] = useState(0); // Track the current step
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);
    const navigate = useNavigate();
    const { token } = useAuth(); // Assume these are in your context
    const [emailTemplates, setemailTemplates] = useState<EmailTemplates[]>([])
    const [interviewSteps, setsInterviewSteps] = useState<number>(1)

    const [applicationReceivedTemplate, setApplicationReceivedTemplate] = useState('');
    const [applicationRejectedTemplate, setApplicationRejectedTemplate] = useState('');
    const [interviewConfirmationTemplate, setInterviewConfirmationTemplate] = useState('');
    const [offerEmailTemplate, setOfferEmailTemplate] = useState('');

    useEffect(() => {
        const fetchEmailTemplates = async () => {
            const emailTemplates = await getEmailTemplates()
            setemailTemplates(emailTemplates)
        }
        fetchEmailTemplates()
    }, [])



    const steps = [
        'Job Details',
        ...Array.from({ length: interviews.length }, (_, index) => `Interview ${interviews[index].name ? interviews[index].name : index + 1 }`), // Use dynamic interview names
        'Mail Configuration',
        'Confirmation', // New confirmation step
    ];

    const handleInterviewNameChange = (index: number, event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const newInterviews = [...interviews];
        newInterviews[index].name = event.target.value;
        setInterviews(newInterviews);
    };

    const handleToggleAutoQuestionCreationInterviewSetting = (index: number, value: boolean) => {
        const newInterviews = interviews.map((val, _index) => {
            if (index === _index) {
                val.createQuestionsWithAI = value
            }
            return val
        })
        setInterviews(newInterviews);
    }
    const handleToggleAutoInviteInterviewSetting = (index: number, value: boolean) => {
        const newInterviews = interviews.map((val, _index) => {
            if (index === _index) {
                val.autoInviteBasedOnMatch = value
            }
            return val
        })
        setInterviews(newInterviews);
    }
    const handleToggleFollowUpChange = (index: number, value: boolean) => {
        const newInterviews = interviews.map((val, _index) => {
            if (index === _index) {
                val.enableFollowUp = value
            }
            return val
        })
        setInterviews(newInterviews);
    }

    const handleToggleCandidateQuestions = (index: number, value: boolean) => {
        const newInterviews = interviews.map((val, _index) => {
            if (index === _index) {
                val.allowCandidateQuestions = value
            }
            return val
        })
        setInterviews(newInterviews);
    }

    const handleToggleEmotionalAnalysis = (index: number, value: boolean) => {
        const newInterviews = interviews.map((val, _index) => {
            if (index === _index) {
                val.emotionalAnalysis = value
            }
            return val
        })
        setInterviews(newInterviews);
    }


    const handleAIDtoneChange = (index: number, value: string) => {
        const newInterviews = interviews.map((val, _index) => {
            if (index === _index) {
                val.aiTone = value
            }
            return val
        })
        setInterviews(newInterviews);
    }

    const handleQuestionDifficultyChange = (index: number, value: string) => {
        const newInterviews = interviews.map((val, _index) => {
            if (index === _index) {
                val.questionDifficulty = value
            }
            return val
        })
        setInterviews(newInterviews);
    }



    const handleRemoveInterview = (index: number) => {
        const newInterviews = interviews.filter((val, _index) => index !== _index)
        setInterviews(newInterviews);
    }
    const handleInterviewThresholdChange = (index: number, value: number | number[]) => {

        const newInterviews = interviews.map((val, _index) => {
            if (index === _index) {
                if (typeof value === "number")
                    val.matchThreshold = value
            }
            return val
        })
        setInterviews(newInterviews);
        // const newInterviews = interviews.filter((val, _index) => index !== _index)
        // setInterviews(newInterviews);
    }





    const handleAddInterview = () => {

        setInterviews([...interviews, { name: '', questions: [], aiTone: "formal", questionDifficulty: "medium" }]);
        //setinterviews.length(interviews.length + 1);
    };

    const handleNext = async () => {
        if (activeStep === 0) {

            if (interviews.length < interviewSteps) {
                const temp = { name: '', questions: [], aiTone: "formal", questionDifficulty: "medium" }
                const interviewArray = [];
                for (let index = 0; index < interviewSteps - interviews.length; index++) {
                    interviewArray.push(temp)
                }
                setInterviews([...interviews, ...interviewArray]);
            }
            else if (interviews.length > interviewSteps) {

                const interviewArray = interviews.filter((val) => val)
                for (let index = 0; index < interviews.length - interviewSteps; index++) {
                    interviewArray.pop()
                }
                setInterviews([...interviewArray]);
            }
        }
        if (activeStep === 4) {
            setfetching(true)
            // const response = await getInterviewQuestions(interviews.map((val) => val.name), description, jobTitle, "cd", language)

            // const newInt = interviews.map((val, index) => {
            //     val.questions = response.job_listing.interview_phases[index].interview_questions.map((q) => q.question)
            //     return val
            // })
            // setInterviews(newInt)
            // console.log(newInt)

            setfetching(false)
            setActiveStep((prevStep) => prevStep + 1);
        }
        else if (activeStep === steps.length - 1) {
            handleSubmit(); // Submit the form on the last step
        } else {
            setActiveStep((prevStep) => prevStep + 1);
        }
    };

    const handleBack = () => {
        setActiveStep((prevStep) => prevStep - 1);
    };

    const handleSubmit = async () => {
        if (token)
            newJobAdvert(jobTitle, description, requirements, location, salary, language, token, interviews, applicationReceivedTemplate, applicationRejectedTemplate, interviewConfirmationTemplate, offerEmailTemplate)
        // try {
        //     setLoading(true);
        //     // Add the logic for submitting the form (e.g., call to API)
        //     // For now, we'll just simulate the submission.
        //     setTimeout(() => {
        //         setLoading(false);
        //         navigate('/jobs'); // Redirect to job listings after successful submission
        //     }, 1000);
        // } catch (err) {
        //     console.error(err);
        //     setError('Failed to add job');
        //     setLoading(false);
        // }
    };


    const handleChangeLanguage = (event: ChangeEvent<{ value: unknown }>) => {
        setLanguage(event.target.value as string);
    };



    if (loading) return <Loading />;
    if (error) return <Alert severity="error">{error}</Alert>;

    return (
        <Layout>
            <Container sx={{
                bgcolor: 'background.paper',
                borderRadius: 2,
                boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.1)', px: 2, py: 4, mb: 2,
            }}>
                {/* <Typography variant="h4" gutterBottom sx={{ color: '#6a4c9c', py: 2, textAlign: "center", fontWeight: 'bold', }}>
                    Post a New Job
                </Typography> */}

                <Stepper activeStep={activeStep} alternativeLabel >
                    {steps.map((step, index) => (
                        <Step key={index}>
                            <StepLabel >
                                {step}
                            </StepLabel>
                        </Step>
                    ))}
                </Stepper>


                <Box sx={{

                }}>
                    <form>
                        {/* Step 1: Job Details */}
                        {activeStep === 0 && (
                            <Box>
                                <JobDetailsForm steps={interviewSteps} setSteps={setsInterviewSteps}
                                    description={description} jobTitle={jobTitle} language={language} location={location}
                                    salary={salary} setDescription={setDescription} setJobTitle={setJobTitle} setLanguage={setLanguage}
                                    setLocation={setLocation} setSalary={setSalary}
                                />

                            </Box>
                        )}


                        {/* Step 5: Interview Process */}
                        {activeStep >= 1 && activeStep <= interviewSteps && (
                            !fetching ? (
                                <Box sx={{ py: 2, px: 4, bgcolor: '#fff' }}>
                                    <Typography variant="subtitle1" sx={{ mb: 1, fontWeight: 'bold', color: 'primary.main' }}>
                                        Interview Setup
                                    </Typography>
                                    <Typography variant="subtitle2" sx={{ mb: 1, color: 'text.secondary' }}>
                                        Configure Interview Stages and Settings
                                    </Typography>
                                    <Box
                                        key={activeStep - 1}
                                        sx={{
                                            marginBottom: 1,
                                           
                                            borderRadius: 2,
                                            bgcolor: '#ffffff',

                                            transition: 'all 0.2s ease',
                                        }}
                                    >



                                        {/* Interview Settings */}
                                        <Box  >
                                            <FormGroup>
                                                {/* AI Generated Questions */}
                                                <Grid container spacing={3}>
                                                    <Grid item xs={12} md={6}>
                                                        {/* Interview Header */}
                                                        <Box display="flex" justifyContent="space-between" alignItems="center"  >
                                                            <TextField

                                                                label="Interview Type (e.g., Technical, Cultural)"
                                                                variant="outlined"
                                                                value={interviews[activeStep - 1].name}
                                                                onChange={(e) => handleInterviewNameChange(activeStep - 1, e)}
                                                                margin="normal"
                                                                size="small"
                                                                sx={{
                                                                    flex: 1,
                                                                     borderRadius: 2,
                                                                    mb:2,
                                                                }}
                                                            />

                                                        </Box>

                                                        {/* Interview Name */}
                                                        <FormControlLabel
                                                            control={
                                                                <Checkbox
                                                                    checked={interviews[activeStep - 1].createQuestionsWithAI}
                                                                    onChange={(e) =>
                                                                        handleToggleAutoQuestionCreationInterviewSetting(activeStep - 1, e.target.checked)
                                                                    }
                                                                />
                                                            }
                                                            label={
                                                                <Box sx={{mt:2}} display="flex"   justifyContent="space-between">
                                                                    <Typography variant="body2" color="textSecondary" sx={{ marginRight: 2 }}>
                                                                        Would you like AI to generate interview questions for this stage?
                                                                    </Typography>
                                                                    <Tooltip title="Enable this option to let AI create tailored questions for this interview stage based on the job description and requirements.">
                                                                        <HelpOutline fontSize="small" style={{ cursor: 'pointer' }} />
                                                                    </Tooltip>
                                                                </Box>
                                                            }
                                                        />
                                                        {interviews[activeStep - 1].createQuestionsWithAI && (
                                                            <Box marginTop={0} paddingLeft="35px">
                                                                <Typography variant="caption" color="textPrimary" marginBottom={0}>
                                                                    Interview Question Complexity
                                                                </Typography>
                                                                <RadioGroup
                                                                    value={interviews[activeStep - 1].questionDifficulty}
                                                                    onChange={(e) => handleQuestionDifficultyChange(activeStep - 1, e.target.value)}
                                                                    row
                                                                    sx={{
                                                                        padding: '0px 0',  // Add padding around options
                                                                    }}
                                                                >
                                                                    <FormControlLabel value="easy" control={<Radio />} label="Easy" sx={{ padding: '5px 0' }} />
                                                                    <FormControlLabel value="medium" control={<Radio />} label="Medium" sx={{ padding: '5px 0' }} />
                                                                    <FormControlLabel value="hard" control={<Radio />} label="Hard" sx={{ padding: '5px 0' }} />
                                                                </RadioGroup>
                                                            </Box>
                                                        )}

                                                        {/* Auto Invite */}
                                                        <FormControlLabel
                                                            control={
                                                                <Checkbox
                                                                    checked={interviews[activeStep - 1].autoInviteBasedOnMatch}
                                                                    onChange={(e) =>
                                                                        handleToggleAutoInviteInterviewSetting(activeStep - 1, e.target.checked)
                                                                    }
                                                                />
                                                            }
                                                            label={
                                                                <Box sx={{mt:2}} display="flex"  justifyContent="space-between">
                                                                    <Typography variant="body2" color="textSecondary" sx={{ marginRight: 2 }}>
                                                                        Automatically invite candidates who meet the match criteria
                                                                    </Typography>
                                                                    <Tooltip title="Enable this option to automatically send interview invitations to candidates who match the specified criteria, such as CV or previous interview performance.">
                                                                        <HelpOutline fontSize="small" style={{ cursor: 'pointer' }} />
                                                                    </Tooltip>
                                                                </Box>
                                                            }
                                                        />

                                                        {/* Match Threshold (only if auto-invite is enabled) */}
                                                        {interviews[activeStep - 1].autoInviteBasedOnMatch && (
                                                            <Box marginTop={1} paddingLeft="35px">
                                                                 
                                                                <Typography variant="caption" color="textPrimary" marginBottom={1}>
                                                                Candidate Match Threshold: {interviews[activeStep - 1].matchThreshold || 0}%
                                                                </Typography>
                                                                <Box display={"flex"}>
                                                                    <Slider

                                                                        value={interviews[activeStep - 1].matchThreshold || 0}
                                                                        onChange={(e, newValue) => handleInterviewThresholdChange(activeStep - 1, newValue)}
                                                                        aria-labelledby="match-threshold-slider"
                                                                        valueLabelDisplay="auto"
                                                                        step={5}
                                                                        marks
                                                                        min={30}
                                                                        max={100}
                                                                        sx={{
                                                                            flex: 1,
                                                                            
                                                                            '& .MuiSlider-thumb': {
                                                                                backgroundColor: '#fff',
                                                                            },
                                                                        }}
                                                                    />
                                                                  
                                                                </Box>
                                                            </Box>
                                                        )}
                                                        <FormControlLabel
                                                            control={
                                                                <Checkbox
                                                                    checked={interviews[activeStep - 1].enableFollowUp}
                                                                    onChange={(e) => handleToggleFollowUpChange(activeStep - 1, e.target.checked)}
                                                                />
                                                            }
                                                            label={
                                                                <Box mt={2} display="flex" alignItems="center" justifyContent="space-between">
                                                                    <Typography variant="body2" color="textSecondary" sx={{ marginRight: 2 }}>
                                                                        Enable AI to ask follow-up questions based on candidate responses
                                                                    </Typography>
                                                                </Box>
                                                            }
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} md={6}>
                                                        <Box marginTop={2}>
                                                            <Typography variant="body2" color="textPrimary" marginBottom={1}>
                                                                AI Interview Tone
                                                            </Typography>
                                                            <RadioGroup
                                                                value={interviews[activeStep - 1].aiTone}
                                                                onChange={(e) => handleAIDtoneChange(activeStep - 1, e.target.value)}
                                                                row
                                                            >
                                                                <FormControlLabel value="formal" control={<Radio size='small' />} label="Formal" />
                                                                <FormControlLabel value="casual" control={<Radio  size='small'/>} label="Casual" />
                                                                <FormControlLabel value="friendly" control={<Radio  size='small' />} label="Friendly" />
                                                                <FormControlLabel value="motivational" control={<Radio  size='small'/>} label="Motivational" />
                                                            </RadioGroup>
                                                        </Box>
                                                        <Box >
                                                            <FormControlLabel
                                                                control={
                                                                    <Checkbox
                                                                        checked={interviews[activeStep - 1].allowCandidateQuestions}
                                                                        onChange={(e) => handleToggleCandidateQuestions(activeStep - 1, e.target.checked)}
                                                                    />
                                                                }
                                                                label={
                                                                    <Box display="flex" alignItems="center" justifyContent="space-between">
                                                                        <Typography variant="body2" color="textSecondary" sx={{ marginRight: 2 }}>
                                                                            Allow candidate questions during the interview
                                                                        </Typography>
                                                                    </Box>
                                                                }
                                                            />
                                                        </Box>

                                                        <Box >
                                                            <FormControlLabel
                                                                control={
                                                                    <Checkbox
                                                                        checked={interviews[activeStep - 1].emotionalAnalysis}
                                                                        onChange={(e) => handleToggleEmotionalAnalysis(activeStep - 1, e.target.checked)}
                                                                    />
                                                                }
                                                                label={
                                                                    <Box display="flex" alignItems="center" justifyContent="space-between">
                                                                        <Typography variant="body2" color="textSecondary" sx={{ marginRight: 2 }}>
                                                                            Enable AI to analyze candidate's emotional state
                                                                        </Typography>
                                                                    </Box>
                                                                }
                                                            />
                                                        </Box>
                                                    </Grid>
                                                </Grid>
                                            </FormGroup>
                                        </Box>
                                    </Box>





                                </Box>
                            ) : (
                                <Typography variant="body2" color="textSecondary" sx={{ textAlign: 'center', padding: 3 }}>
                                    Loading interview settings, please wait...
                                </Typography>
                            )
                        )}




                        {/* Dynamic Interview Steps */}
                        {/* {activeStep >= 5 && activeStep < 5 + interviews.length && (
                        <Box>

                            <InterviewQuestionsList onQuestionChange={onQuestionChange} index={activeStep - 5} key={interviews[activeStep - 5].name} title={interviews[activeStep - 5].name} jobQuestions={interviews[activeStep - 5].questions} />
                        </Box>
                    )} */}
                        {activeStep === interviewSteps + 1 && (
                            <EmailTemplatesAccordion emailTemplates={emailTemplates} applicationReceivedTemplate={applicationReceivedTemplate} applicationRejectedTemplate={applicationRejectedTemplate}
                                interviewConfirmationTemplate={interviewConfirmationTemplate} offerEmailTemplate={offerEmailTemplate}
                                setApplicationReceivedTemplate={setApplicationReceivedTemplate} setApplicationRejectedTemplate={setApplicationRejectedTemplate} setInterviewConfirmationTemplate={setInterviewConfirmationTemplate} setOfferEmailTemplate={setOfferEmailTemplate} />
                        )}




                        {/* Step 6: Confirmation (Summary) */}
                        {activeStep === interviewSteps + 2 && (
                            <Box sx={{ bgcolor: '#fff', borderRadius: 2,py:2, px: 4 }}>
                                <Typography variant="subtitle1" sx={{ mb: 1, fontWeight: 'bold', color: 'primary.main' }}>
                                    Summary
                                </Typography>
                                <Typography variant="subtitle2" sx={{ mb: 1, color: 'text.secondary' }}>
                                    Please review the job details and interview process
                                </Typography>


                                <Box sx={{ marginBottom: 0, padding: 1, bgcolor: '#ffffff' }}>
                                    <Typography variant="body1" color="primary" sx={{ marginBottom: 1 }}>
                                        <strong> Job Info</strong>
                                    </Typography>
                                    <Divider sx={{mb:1}} />

                                    <Grid container spacing={1}>
                                        {/* Interview Configuration Settings */}
                                        <Grid item xs={12} sm={6}>
                                            <Typography variant="body2" color="textSecondary">
                                                <strong>Job Title:</strong> {jobTitle}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <Typography variant="body2" color="textSecondary">
                                                <strong>Location:</strong> {location}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <Typography variant="body2" color="textSecondary">
                                                <strong>Language:</strong> {language}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <Typography variant="body2" color="textSecondary">
                                                <strong>Salary:</strong> {salary}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} sm={12}>
                                            <Typography variant="body2" color="textSecondary">
                                                <strong>Description:</strong> {description}
                                            </Typography>

                                        </Grid>
                                    </Grid>

                                </Box>



                                {/* Interview Configuration Settings using Grid Layout */}
                                {interviews.map((interview, index) => (
                                    <Box key={index} sx={{ padding: 1, bgcolor: '#ffffff' }}>
                                        <Typography variant="body1" color="primary" sx={{ marginBottom: 1 }}>
                                            <strong>{interview.name} Interview</strong>
                                        </Typography>
                                        <Divider sx={{mb:1}} />

                                        <Grid container spacing={1}>
                                            {/* Interview Configuration Settings */}
                                            <Grid item xs={12} sm={6}>
                                                <Typography variant="body2" color="textSecondary">
                                                    <strong>AI Generated Questions:</strong> {interview.createQuestionsWithAI ? 'Yes' : 'No'}
                                                </Typography>
                                            </Grid>
                                            {
                                                interview.createQuestionsWithAI ? <Grid item xs={12} sm={6}>
                                                    <Typography variant="body2" color="textSecondary">
                                                        <strong>Question Difficulty:</strong> {interview.questionDifficulty || 'Not Set'}
                                                    </Typography>
                                                </Grid> : <Grid item xs={12} sm={6}> </Grid>
                                            }

                                            <Grid item xs={12} sm={6}>
                                                <Typography variant="body2" color="textSecondary">
                                                    <strong>Auto Invite Candidates:</strong> {interview.autoInviteBasedOnMatch ? 'Yes' : 'No'}
                                                </Typography>
                                            </Grid>
                                            {interview.autoInviteBasedOnMatch ?
                                                <Grid item xs={12} sm={6}>
                                                    <Typography variant="body2" color="textSecondary">
                                                        <strong>Match Threshold:</strong> {interview.matchThreshold}%
                                                    </Typography>
                                                </Grid> : <Grid item xs={12} sm={6}> </Grid>
                                            }
                                            <Grid item xs={12} sm={6}>
                                                <Typography variant="body2" color="textSecondary">
                                                    <strong>Follow-up Questions:</strong> {interview.enableFollowUp ? 'Enabled' : 'Disabled'}
                                                </Typography>
                                            </Grid>

                                            <Grid item xs={12} sm={6}>
                                                <Typography variant="body2" color="textSecondary">
                                                    <strong>AI Tone:</strong> {interview.aiTone || 'Not Set'}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <Typography variant="body2" color="textSecondary">
                                                    <strong>Allow Candidate Questions:</strong> {interview.allowCandidateQuestions ? 'Yes' : 'No'}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <Typography variant="body2" color="textSecondary">
                                                    <strong>Emotional Analysis:</strong> {interview.emotionalAnalysis ? 'Enabled' : 'Disabled'}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                ))}
                            </Box>
                        )}



                        {/* <Typography><strong>Questions:</strong></Typography>
                            <ul>
                                {interview.questions.map((question, qIndex) => (
                                    <li key={qIndex}>{question}</li>
                                ))}
                            </ul> */}

                    </form>
                </Box>
                <Box sx={{ textAlign: "right", gap: 2, mt: 0 }}>
                    <Button
                        variant="outlined"
                        color="primary"
                        onClick={handleBack}
                        disabled={activeStep === 0}
                        style={{ marginRight: '10px' }}
                    >
                        Back
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleNext}
                    >
                        {activeStep === steps.length - 1 ? 'Submit' : 'Next'}
                    </Button>
                </Box>
            </Container>
        </Layout>
    );
};

export default AddJobWizard;
