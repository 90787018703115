import React, { useState, useEffect, useRef } from "react";
import {
  Container,
  Paper,
  Box,
  Typography,
  Button,
  Grid,
  TextField,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
} from "@mui/material";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import Layout from "../components/Layout";
import { saveEmailTemplate, TemplateType, getTemplateTypes } from "../api/api";

const TemplateEditorPage = () => {
  const [selectedTemplate, setSelectedTemplate] = useState("application_received");
  const [emailContent, setEmailContent] = useState({
    subject: "",
    body: "",
    name:"",
  });
  const [selectedPlaceholder, setSelectedPlaceholder] = useState("");
  const [focusedField, setFocusedField] = useState("body"); // Track focus
  const [templateTypes, settemplateTypes] = useState([])
  const quillRef = useRef(null);
  const subjectFieldRef = useRef(null);

  useEffect(() => {
    const fetchTemplateTypes = async () => {
      const res = await getTemplateTypes()
      settemplateTypes(res)
      if (res.length > 0)
        handleTemplateChange(res[0].id)
    }
    const fetchDynamicPlaceHolders = async () => {
      // TO DO 
    }
    fetchTemplateTypes()
    fetchDynamicPlaceHolders()
  }, [])



  const dynamicData = {
    job_title: "Software Engineer",
    company: "Tech Corp",
    candidate_name: "John Doe",
    candidate_email: "john.doe@email.com",
    interview_date: "2024-12-15",
    job_offer_details: "Annual Salary: $80,000",
    application_date: "2024-12-01",
    job_location: "New York, NY",
    company_contact: "hr@techcorp.com",
    recruiter_name: "Jane Smith",
    interview_location: "Tech Corp HQ",
    interview_time: "10:00 AM",
  };

  const handleTemplateChange = (template) => {
    console.log(template)
    setSelectedTemplate(template);

    // Set template-specific content with placeholders
    const subjectTemplate = "[job_title] Application - [company]";
    const bodyTemplate = `
      <p>Dear [candidate_name],</p>
      <p>Your application for the position of [job_title] at [company] has been received on [application_date].</p>
    `;

    setEmailContent({ subject: subjectTemplate, body: bodyTemplate });
  };

  const replacePlaceholders = (content) => {
    let replacedContent = content;
    Object.keys(dynamicData).forEach((key) => {
      const placeholder = `[${key}]`;
      const value = dynamicData[key] || `[${key}]`;
      replacedContent = replacedContent.replaceAll(placeholder, value);
    });
    return replacedContent;
  };

  const handleSave = () => {
    saveEmailTemplate(emailContent.subject, emailContent.body, selectedTemplate,emailContent.name)
  };

  const handleCancel = () => {
    setEmailContent({ subject: "", body: "" });
  };

  const handleBodyChange = (value) => {
    setFocusedField("body"); // Update focus
    setEmailContent({ ...emailContent, body: value });
  };

  const handleSubjectChange = (value) => {
    setFocusedField("subject"); // Update focus
    setEmailContent({ ...emailContent, subject: value });
  };


  const handleTitleChange = (value) => {
    setEmailContent({ ...emailContent, name: value });
  };

  const insertPlaceholder = () => {
    if (!selectedPlaceholder) return;
    console.log(focusedField, "focusedField")
    const placeholder = `[${selectedPlaceholder}]`;
    if (focusedField === "body") {
      // Insert into Quill editor
      const editor = quillRef.current.getEditor();
      const range = editor.getSelection();
      if (range && range.index !== null) {
        editor.insertText(range.index, placeholder);
      }
    } else if (focusedField === "subject") {
      if (subjectFieldRef.current) {
        const cursorPosition = parseInt(subjectFieldRef.current.selectionStart.toString() || emailContent.subject.length);
        const updatedSubject =
          emailContent.subject.slice(0, cursorPosition) +
          placeholder +
          emailContent.subject.slice(cursorPosition);

        setEmailContent({ ...emailContent, subject: updatedSubject });
      }
    }
  };

  return (
    <Layout>
      <Container maxWidth="lg">
        <Box sx={{ display: "flex", height: "100vh", overflow: "hidden" }}>
          {/* Sidebar for Template Selection */}
          <Box
            sx={{
              width: "250px",
              background: "#f4f4f4",
              p: 2,
              borderRight: "1px solid #ddd",
              overflowY: "auto",
            }}
          >
            <Typography variant="h6" sx={{ mb: 2 }}>
              Templates
            </Typography>
            {
              templateTypes.map((val) => { return { label: val.name, value: val.id } }).map((template) => (
                <Button
                  key={template.value}
                  variant={
                    selectedTemplate === template.value ? "contained" : "text"
                  }
                  fullWidth
                  sx={{ justifyContent: "flex-start", mb: 1 }}
                  onClick={() => handleTemplateChange(template.value)}
                >
                  {template.label}
                </Button>
              ))}
          </Box>

          {/* Main Content Area */}
          <Box sx={{ flex: 1, p: 3 }}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                mb: 3,
              }}
            >
              <Typography variant="h4">Email Template Editor</Typography>
              <Box>
                <Button variant="contained" onClick={handleSave} sx={{ mr: 2 }}>
                  Save
                </Button>
                <Button variant="outlined" onClick={handleCancel}>
                  Cancel
                </Button>
              </Box>
            </Box>

            <Grid container spacing={3}>
              <Grid item xs={12} md={12}>
                {/* Email Editor for Subject and Body */}
                <Box>
                  <TextField
                    label="Name"
                    variant="outlined"
                    fullWidth
                    value={emailContent.name}
                    onChange={(e) => handleTitleChange(e.target.value)}
                    sx={{ mb: 2 }}
                  />
                  <TextField
                    label="Subject"
                    variant="outlined"
                    fullWidth
                    value={emailContent.subject}
                    onChange={(e) => handleSubjectChange(e.target.value)}

                    inputRef={subjectFieldRef} // Attach the ref here
                    sx={{ mb: 2 }}
                  />
                  <ReactQuill
                    ref={quillRef}
                    value={emailContent.body}
                    onFocus={() => setFocusedField("body")}
                    onChange={handleBodyChange}
                    placeholder="Start writing your email body..."
                    style={{ height: "300px", marginBottom: "16px" }}
                  />
                  <Box sx={{ display: "flex", alignItems: "center", gap: 2, mt: 6 }}>
                    <FormControl fullWidth>
                      <InputLabel>Insert Placeholder</InputLabel>
                      <Select
                        label="Insert Placeholder"
                        value={selectedPlaceholder}
                        onChange={(e) => setSelectedPlaceholder(e.target.value)}
                      >
                        {Object.keys(dynamicData).map((key) => (
                          <MenuItem key={key} value={key}>
                            {key.replaceAll("_", " ").toUpperCase()}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <Button
                      variant="contained"
                      onClick={insertPlaceholder}
                      sx={{ px: 4, py: 1, whiteSpace: "nowrap" }}
                    >
                      Insert Placeholder
                    </Button>
                  </Box>

                </Box>
              </Grid>

              <Grid item xs={12} md={12}>
                {/* Email Preview */}
                <Box>
                  <Typography variant="h6">Preview</Typography>
                  <Paper
                    sx={{
                      mt: 2,
                      p: 2,
                      border: "1px solid #ddd",
                      maxHeight: "400px",
                      overflowY: "auto",
                    }}
                  >
                    <Typography variant="h6">
                      {replacePlaceholders(emailContent.subject) || "Subject here"}
                    </Typography>
                    <Typography
                      variant="body1"
                      sx={{ mt: 2 }}
                      dangerouslySetInnerHTML={{
                        __html:
                          replacePlaceholders(emailContent.body) ||
                          "Body content here.",
                      }}
                    />
                  </Paper>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Container>
    </Layout>
  );
};

export default TemplateEditorPage;
