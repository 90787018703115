import React, { useEffect, useState } from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    TextField,
    MenuItem,
    Select,
    InputLabel,
    FormControl,
    IconButton,
    Typography,
    Box,
    Menu,
    Button,
    TableSortLabel,
    LinearProgress,
} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { fetchJobs, Job } from '../api/api';
import Layout from '../components/Layout';
import { Link } from 'react-router-dom';
import theme from '../theme';

const Jobs: React.FC = () => {
    const [jobs, setJobs] = useState<Job[]>([]);
    const [filteredJobs, setFilteredJobs] = useState<Job[]>([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [filterStatus, setFilterStatus] = useState(0);
    const [sortField, setSortField] = useState<'posted_at' | 'totalApplicants'>('posted_at');
    const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('asc');
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [selectedJobId, setSelectedJobId] = useState<string | null>(null);

    useEffect(() => {
        const loadJobs = async () => {
            try {
                const jobsData = await fetchJobs();
                setJobs(jobsData);
                setFilteredJobs(jobsData);
            } catch (err) {
                console.error(err);
            }
        };

        loadJobs();
    }, []);

    useEffect(() => {
        // Apply filtering and searching
        let filtered = jobs;

        if (filterStatus) {
            filtered = filtered.filter((job) => job.status === filterStatus);
        }

        if (searchTerm) {
            filtered = filtered.filter(
                (job) =>
                    job.job_title.toLowerCase().includes(searchTerm.toLowerCase()) ||
                    (job.name && job.name.toLowerCase().includes(searchTerm.toLowerCase()))
            );
        }

        setFilteredJobs(filtered);
    }, [searchTerm, filterStatus, jobs]);

    const handleSort = (field: 'posted_at' | 'totalApplicants') => {
        const isAsc = sortField === field && sortOrder === 'asc';
        setSortOrder(isAsc ? 'desc' : 'asc');
        setSortField(field);

        const sorted = [...filteredJobs].sort((a, b) => {
            if (field === 'posted_at') {
                return isAsc
                    ? new Date(a.posted_at).getTime() - new Date(b.posted_at).getTime()
                    : new Date(b.posted_at).getTime() - new Date(a.posted_at).getTime();
            }
            return isAsc
                ? a.totalApplicants - b.totalApplicants
                : b.totalApplicants - a.totalApplicants;
        });

        setFilteredJobs(sorted);
    };

    const handleMenuOpen = (event: React.MouseEvent<HTMLElement>, jobId: string) => {
        setAnchorEl(event.currentTarget);
        setSelectedJobId(jobId);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
        setSelectedJobId(null);
    };
    const calculatePipelineProgress = (job: Job): number => {
        const total =
            (job?.totalApplicants || 0) +
            (job?.inProcess || 0)
        const progress = total ? ((job.interviewCompleted || 0) / total) * 100 : 0;
        return Math.round(progress);
    };



    return (
        <Layout>

            <Box>
                <Typography variant="h4" gutterBottom>
                    Manage Job Listings
                </Typography>
                {/* Search and Filters */}
                <Box display="flex" gap={2} marginBottom={2}>
                    <TextField
                        label="Search Jobs"
                        variant="outlined"
                        fullWidth
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                    />
                    <FormControl sx={{ width: 300 }}>
                        <InputLabel>Status</InputLabel>
                        <Select
                            label="Status"
                            value={filterStatus}
                            onChange={(e) => setFilterStatus(Number(e.target.value))}
                        >
                            <MenuItem value={0}>All</MenuItem>
                            <MenuItem value={1}>Active</MenuItem>
                            <MenuItem value={3}>Closed</MenuItem>
                            <MenuItem value={2}>Draft</MenuItem>
                        </Select>
                    </FormControl>
                </Box>

                {/* Job Table */}
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>
                                    <Typography variant="subtitle2" fontWeight="bold">
                                        Job Title
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography variant="subtitle2" fontWeight="bold">
                                        Company
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography variant="subtitle2" fontWeight="bold">
                                        Location
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography variant="subtitle2" fontWeight="bold">
                                        Salary
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <TableSortLabel
                                        active={sortField === 'posted_at'}
                                        direction={sortOrder}
                                        onClick={() => handleSort('posted_at')}
                                    >
                                        <Typography variant="subtitle2" fontWeight="bold">
                                            Posted Date
                                        </Typography>
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell>
                                    <Typography variant="subtitle2" fontWeight="bold">
                                        Status
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <TableSortLabel
                                        active={sortField === 'totalApplicants'}
                                        direction={sortOrder}
                                        onClick={() => handleSort('totalApplicants')}
                                    >
                                        <Typography variant="subtitle2" fontWeight="bold">
                                            Total Applicants
                                        </Typography>
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell>
                                    <Typography variant="subtitle2" fontWeight="bold">
                                        Interviewing
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography variant="subtitle2" fontWeight="bold">
                                        Completed Interviews
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography variant="subtitle2" fontWeight="bold">
                                        Actions
                                    </Typography>
                                </TableCell>
                            </TableRow>

                        </TableHead>
                        <TableBody>
                            {filteredJobs.map((job) => (
                                <TableRow sx={{
                                    '&:hover': {
                                        backgroundColor: theme.palette.action.hover,
                                    },
                                }} key={job.id}>
                                    <TableCell>{job.job_title}</TableCell>
                                    <TableCell>{job.name || 'N/A'}</TableCell>
                                    <TableCell>{job.location || 'N/A'}</TableCell>
                                    <TableCell>{job.salary || 'N/A'}</TableCell>
                                    <TableCell>
                                        {new Date(job.posted_at).toLocaleDateString()}
                                    </TableCell>
                                    <TableCell>
                                        <Typography
                                            color={
                                                job.status === 1
                                                    ? 'green'
                                                    : job.status === 0
                                                        ? 'red'
                                                        : 'gray'
                                            }
                                        >
                                            {job.status}
                                        </Typography>
                                    </TableCell>
                                    <TableCell>{job.totalApplicants || 0}</TableCell>
                                    <TableCell>{job.inProcess || 0}</TableCell>
                                    <TableCell>{job.interviewCompleted || 0}</TableCell>

                                    <TableCell>
                                        <IconButton
                                            aria-controls={`menu-${job.id}`}
                                            aria-haspopup="true"
                                            onClick={(e) => handleMenuOpen(e, job.id.toString())}
                                        >
                                            <MoreVertIcon />
                                        </IconButton>
                                        <Menu
                                            id={`menu-${job.id}`}
                                            anchorEl={anchorEl}
                                            open={Boolean(anchorEl) && selectedJobId === job.id.toString()}
                                            onClose={handleMenuClose}
                                        >
                                            <MenuItem component={Link} to={`/jobs/detail/${job.id}`}> View Job</MenuItem>
                                            <MenuItem>
                                                {job.status === 1 ? 'Unpublish' : 'Publish'}
                                            </MenuItem>
                                            <MenuItem component={Link} to={`/jobs/edit/${job.id}`}>Edit Job</MenuItem>
                                            <MenuItem>Delete Job</MenuItem>
                                        </Menu>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
        </Layout>
    );
};

export default Jobs;
