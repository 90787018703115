import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Card, CardContent, Typography, Button, Box, Grid, Table, TableBody, TableCell, TableHead, TableRow, TableContainer, Paper, IconButton, Menu, MenuItem, TextField, FormControl, InputLabel, Select, Checkbox, Tabs, Tab, Accordion, AccordionDetails, AccordionSummary, Chip } from '@mui/material';
import { LocationOn, AttachMoney, DateRange, MoreVert, ArrowForward, ExpandMore, Favorite } from '@mui/icons-material';
import { Job, Interviewee, fetchJobById, fetchIntervieweesWithJobId, fetchApplicantsWithJobId, Applicant, fetchIntervieweesEvaluationsWithJobId, IntervieweeEvaluation, fetchJobInterviewsByJobId, interviewDetail, fetchJobEmailTemplates, SelectedCandidates, fetchSelectedCandidatesForTheJob } from '../api/api';
import Layout from '../components/Layout';
import EvaluationGraph from '../components/Evaluation';
import JobQuestionsList from '../components/JobQuestionsList';
import InterviewDetail from '../components/InterviewDetail';
import JobDescriptionAccordion from '../components/JobDescriptionAccordion';
import JobStatusChip from '../components/JobStatusChip';
import JobCard from '../components/JobCard';
import theme from '../theme';
const JobDetails: React.FC = () => {
    const { jobId } = useParams<{ jobId: string }>();
    const [job, setJob] = useState<Job | null>(null);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [anchorElMenu, setAnchorElMenu] = useState<null | HTMLElement>(null);
    const [interviewList, setinterviewList] = useState<interviewDetail[]>([])
    const [selectedInterview, setSelectedInterview] = useState<interviewDetail>();  // Track selected interview

    const [applicants, setApplicants] = useState<Applicant[] | undefined>(undefined);
    const [selectedTab, setSelectedTab] = useState(0); // State to control tab selection
    const [selectedInterviewSetupTab, setsSelectedInterviewSetupTab] = useState(0); // State to control tab selection

    const [interviewees, setInterviewees] = useState<IntervieweeEvaluation[]>([])
    const navigate = useNavigate();
    const [selectedCandidates, setselectedCandidates] = useState<SelectedCandidates[]>([])
    useEffect(() => {
        const loadJobData = async () => {
            try {
                if (jobId) {
                    const jobData = await fetchJobById(jobId);
                    const intervieweesData = await fetchIntervieweesEvaluationsWithJobId(jobId);
                    const applicantsData = await fetchApplicantsWithJobId(jobId);
                    setInterviewees(intervieweesData)
                    const interviews = await fetchJobInterviewsByJobId(jobId);
                    console.log("interviewList", interviews)
                    setinterviewList(interviews)
                    setJob(jobData);
                    setApplicants(applicantsData);

                    const resPrimeCandits = await fetchSelectedCandidatesForTheJob(jobId)
                    setselectedCandidates(resPrimeCandits)

                }
            } catch (err) {
                console.error('Error loading job details', err);
            }
        };

        loadJobData();
    }, [jobId]);

    const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    // Placeholder for menu actions (Activate/Deactivate/Delete)
    const handleJobAction = (action: string) => {
        switch (action) {
            case 'Activate Job':
                // Implement activation logic
                break;
            case 'Deactivate Job':
                // Implement deactivation logic
                break;
            case 'Delete Job':
                // Implement delete logic
                break;
            default:
                break;
        }
    };

    // Placeholder for handling the checkbox state
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>, applicantId: string) => {
        // Logic to handle checkbox change
    };

    // Placeholder for navigating to applicant details
    const handleNavigateToDetails = (applicantId: string) => {
        navigate(`/applicants/${jobId}/${applicantId}`);

        // Implement navigation to applicant details
    };
    // Handle selecting a specific interview
    const handleSelectInterview = (interview: interviewDetail, index: number) => {
        setSelectedInterview(interview);
        setsSelectedInterviewSetupTab(index);  // Reset to first tab when switching interview
    };


    const handleSelectedInterviewSetupTab = (event: React.SyntheticEvent, newValue: number) => {
        setsSelectedInterviewSetupTab(newValue);
    };
    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
        setSelectedTab(newValue);
    };


    const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElMenu(event.currentTarget);
    };
    const handleMenuItemClose = () => {
        setAnchorElMenu(null);
    };

    const handleRejectApplicant = (applicantId: number) => {
        // Implement logic to reject the applicant
    };
    const handleMoveToNextStage = (applicantId: number) => {
        // Implement logic to move applicant to the next stage
    };





    if (!job) return <div>Loading...</div>;

    return (
        <Layout>
            <Box>
                <JobCard job={job} onJobAction={handleJobAction} />


                <Box sx={{ mb: 4 }}>
                    {/* Section Title */}
                    <Typography
                        variant="subtitle2"
                        sx={{
                            color: 'text.primary', // Default theme color for consistency
                            fontWeight: 'bold', // Slight emphasis for importance
                            textAlign: 'left', // Keeps it centered
                            textTransform: 'uppercase', // Adds structure to the title
                        }}
                    >
                        Candidate Management
                    </Typography>
                    <Typography
                        variant="caption"
                        sx={{
                            // Margin below subtitle for spacing from content
                            color: 'text.secondary', // Lighter text for less emphasis
                            textAlign: 'left', // Consistent with the title alignment
                        }}
                    >
                        Manage applicants across different stages of the hiring process.
                    </Typography>

                    {/* Tabs for Interview Stages */}
                    <Tabs
                        value={selectedTab}
                        onChange={handleTabChange}
                        aria-label="Interview stages"
                        sx={{
                            mt: 1,
                            '& .MuiTab-root': {
                                textTransform: 'capitalize', // Makes labels more readable
                                fontWeight: 500,
                                fontSize: '1rem',
                                color: 'text.secondary',
                                '&.Mui-selected': {
                                    color: 'primary.main',
                                    fontWeight: 'bold', // Highlights selected tab
                                },
                            },
                            '& .MuiTabs-indicator': {
                                backgroundColor: 'primary.main', // Consistent theme color
                                height: '3px', // Thicker indicator for emphasis
                            },
                        }}
                    >
                        {/* Static Tab */}
                        <Tab label="Applied" />
                        {/* Dynamic Tabs for Interviews */}
                        {interviewList.map((interview, index) => (
                            <Tab
                                key={interview.id}
                                label={`${interview.interview_name} Interview`}
                                onClick={() => handleSelectInterview(interview, index + 1)}
                            />
                        ))}
                        {/* Final Tab */}
                        <Tab label="Shortlisted" />
                    </Tabs>


                    {/* Applicants List */}
                    {selectedTab === 0 &&

                        <TableContainer sx={{ p: 0 }}>
                            <Table >
                                <TableHead>
                                    <TableRow sx={{ backgroundColor: "#f5f5f5" }}>
                                        <TableCell sx={{ fontWeight: 'bold' }}>Status</TableCell>
                                        <TableCell sx={{ fontWeight: 'bold' }}>Name</TableCell>
                                        <TableCell sx={{ fontWeight: 'bold' }}>Email</TableCell>
                                        <TableCell sx={{ fontWeight: 'bold' }}>Phone</TableCell>
                                        <TableCell sx={{ fontWeight: 'bold' }}>Salary</TableCell>
                                        <TableCell sx={{ fontWeight: 'bold' }}>Location</TableCell>
                                        <TableCell sx={{ fontWeight: 'bold' }}>Match Score</TableCell>
                                        <TableCell sx={{ fontWeight: 'bold' }}>Summary</TableCell>
                                        <TableCell sx={{ fontWeight: 'bold' }}>Detail</TableCell>
                                        <TableCell sx={{ fontWeight: 'bold' }}>Action</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {applicants && applicants.map(applicant => (
                                        <>
                                            <TableRow
                                                sx={{
                                                    '&:hover': {
                                                        cursor: "pointer", // Prevents pointer cursor
                                                        backgroundColor: theme.palette.action.hover,
                                                    },
                                                }} key={applicant.id}>
                                                <TableCell onClick={() => handleNavigateToDetails(String(applicant.id))}>
                                                    <Chip
                                                        size="small"
                                                        label={applicant.status}
                                                        sx={{
                                                            padding: 1, // Extra padding for better spacing
                                                            backgroundColor: applicant.status === "Invited" ? "success.light" : "error.light", // Subtle theme colors
                                                            color: applicant.status === "Invited" ? "success.contrastText" : "error.contrastText", // Text color based on theme
                                                            fontWeight: 600, // Slightly bolder for emphasis
                                                        }}
                                                    />
                                                </TableCell>
                                                <TableCell onClick={() => handleNavigateToDetails(String(applicant.id))}>{applicant.name}</TableCell>
                                                <TableCell onClick={() => handleNavigateToDetails(String(applicant.id))}>{applicant.email}</TableCell>
                                                <TableCell onClick={() => handleNavigateToDetails(String(applicant.id))}>{applicant.phone}</TableCell>
                                                <TableCell onClick={() => handleNavigateToDetails(String(applicant.id))}>{applicant.salary}</TableCell>
                                                <TableCell onClick={() => handleNavigateToDetails(String(applicant.id))}>{applicant.location}</TableCell>
                                                <TableCell onClick={() => handleNavigateToDetails(String(applicant.id))}>{applicant.score}</TableCell>
                                                <TableCell onClick={() => handleNavigateToDetails(String(applicant.id))}>
                                                    <Typography variant="body2">{applicant.note || 'No evaluation summary'}</Typography>
                                                </TableCell>
                                                <TableCell onClick={() => handleNavigateToDetails(String(applicant.id))} >
                                                    <Typography variant="body2">{applicant.cv_evaluation.summary || 'No evaluation summary'}</Typography>
                                                </TableCell>
                                                <TableCell >
                                                    <IconButton

                                                        onClick={handleMenuClick}
                                                    >
                                                        <MoreVert />
                                                    </IconButton>
                                                    <Menu
                                                        sx={{
                                                            '& .MuiPaper-root': {
                                                                boxShadow: 'none', // Remove box shadow
                                                                border: '1px solid #ccc', // Add border (adjust the color as needed)
                                                                borderRadius: '4px', // Optional: Add rounded corners
                                                            },
                                                        }}
                                                        anchorEl={anchorElMenu}
                                                        open={Boolean(anchorElMenu)}
                                                        onClose={handleMenuItemClose}
                                                    >
                                                        <MenuItem onClick={() => { handleMoveToNextStage(applicant.id); handleMenuItemClose(); }}>Invite to Interview</MenuItem>
                                                        <MenuItem onClick={() => { handleRejectApplicant(applicant.id); handleMenuItemClose(); }}>Reject</MenuItem>
                                                    </Menu>
                                                </TableCell>

                                            </TableRow>

                                        </>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>

                    }
                    {
                        (selectedTab > 0 && selectedTab < interviewList.length + 1) &&
                        <TableContainer sx={{ p: 0 }}>
                            <Table >
                                <TableHead>
                                    <TableRow sx={{ backgroundColor: "#f5f5f5" }}>
                                        <TableCell sx={{ fontWeight: 'bold' }} >Status</TableCell>
                                        <TableCell sx={{ fontWeight: 'bold' }}>Name</TableCell>
                                        <TableCell sx={{ fontWeight: 'bold' }}>Email</TableCell>
                                        <TableCell sx={{ fontWeight: 'bold' }}>Score</TableCell>
                                        <TableCell sx={{ fontWeight: 'bold' }}>CV Score</TableCell>
                                        <TableCell sx={{ fontWeight: 'bold' }}>Comment</TableCell>
                                        <TableCell sx={{ fontWeight: 'bold' }}>Summary</TableCell>
                                        <TableCell sx={{ fontWeight: 'bold' }}>Action</TableCell>

                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {interviewees && interviewees.filter((val) => val.interview_id === interviewList[selectedTab - 1].id).map(applicant => (
                                        <TableRow
                                            sx={{
                                                '&:hover': {
                                                    cursor: "pointer", // Prevents pointer cursor
                                                    backgroundColor: theme.palette.action.hover,
                                                },
                                            }} key={applicant.user_id}>
                                            <TableCell onClick={() => handleNavigateToDetails(String(applicant.user_id))}
                                            >
                                                <Chip
                                                    size="small"
                                                    label={applicant.status}
                                                    sx={{
                                                        padding: 1, // Extra padding for better spacing
                                                        backgroundColor: applicant.status === "Completed" ? "success.light" : "error.light", // Subtle theme colors
                                                        color: applicant.status === "Completed" ? "success.contrastText" : "error.contrastText", // Text color based on theme
                                                        fontWeight: 600, // Slightly bolder for emphasis
                                                    }}
                                                />
                                            </TableCell>

                                            <TableCell onClick={() => handleNavigateToDetails(String(applicant.user_id))}>{applicant.user_name}</TableCell>
                                            <TableCell onClick={() => handleNavigateToDetails(String(applicant.user_id))}>{applicant.email}</TableCell>
                                            <TableCell onClick={() => handleNavigateToDetails(String(applicant.user_id))}>{applicant.response_evaluation?.score}</TableCell>
                                            <TableCell onClick={() => handleNavigateToDetails(String(applicant.user_id))}>{applicant.cv_evaluation?.overall.score}</TableCell>
                                            <TableCell onClick={() => handleNavigateToDetails(String(applicant.user_id))}>{applicant.recommendations}</TableCell>
                                            <TableCell onClick={() => handleNavigateToDetails(String(applicant.user_id))}>{applicant.cv_evaluation?.comments}</TableCell>

                                            <TableCell>
                                                <IconButton

                                                    onClick={handleMenuClick}
                                                >
                                                    <MoreVert />
                                                </IconButton>
                                                <Menu
                                                    sx={{
                                                        '& .MuiPaper-root': {
                                                            boxShadow: 'none', // Remove box shadow
                                                            border: '1px solid #ccc', // Add border (adjust the color as needed)
                                                            borderRadius: '4px', // Optional: Add rounded corners
                                                        },
                                                    }}
                                                    anchorEl={anchorElMenu}
                                                    open={Boolean(anchorElMenu)}
                                                    onClose={handleMenuItemClose}
                                                >
                                                    <MenuItem onClick={() => { handleMoveToNextStage(applicant.user_id); handleMenuItemClose(); }}>Move to Next Stage</MenuItem>
                                                    <MenuItem onClick={() => { handleRejectApplicant(applicant.user_id); handleMenuItemClose(); }}>Reject</MenuItem>
                                                </Menu>
                                            </TableCell>

                                        </TableRow>

                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>

                    }
                    {
                        selectedTab === interviewList.length + 1 &&
                        <>
                            <TableContainer sx={{ p: 0 }}>
                                <Table >
                                    <TableHead>
                                        <TableRow sx={{ backgroundColor: "#f5f5f5" }}>
                                            <TableCell sx={{ fontWeight: 'bold' }}>Status</TableCell>
                                            <TableCell sx={{ fontWeight: 'bold' }}>Name</TableCell>
                                            <TableCell sx={{ fontWeight: 'bold' }}>Email</TableCell>
                                            <TableCell sx={{ fontWeight: 'bold' }}>Phone</TableCell>
                                            <TableCell sx={{ fontWeight: 'bold' }}>Salary</TableCell>
                                            <TableCell sx={{ fontWeight: 'bold' }}>Location</TableCell>
                                            <TableCell sx={{ fontWeight: 'bold' }}>Match Score</TableCell>
                                            <TableCell sx={{ fontWeight: 'bold' }}>Rank</TableCell>
                                            <TableCell sx={{ fontWeight: 'bold' }}>Summary</TableCell>
                                            <TableCell sx={{ fontWeight: 'bold' }}>Action</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {selectedCandidates && selectedCandidates.map((applicant) => (
                                            <TableRow
                                                sx={{
                                                    '&:hover': {
                                                        cursor: "pointer", // Prevents pointer cursor
                                                        backgroundColor: theme.palette.action.hover,
                                                    },
                                                }} key={applicant.id}>
                                                <TableCell onClick={() => handleNavigateToDetails(String(applicant.id))} sx={{ borderBottom: 'none' }}>
                                                    <Chip
                                                        size="small"
                                                        label={applicant.status}
                                                        sx={{
                                                            padding: 1, // Extra padding for better spacing
                                                            backgroundColor: applicant.status === "Selected" ? "success.light" : "error.light", // Subtle theme colors
                                                            color: applicant.status === "Selected" ? "success.contrastText" : "error.contrastText", // Text color based on theme
                                                            fontWeight: 600, // Slightly bolder for emphasis
                                                        }}
                                                    />
                                                </TableCell>
                                                <TableCell onClick={() => handleNavigateToDetails(String(applicant.id))}  sx={{ borderBottom: 'none' }}>{applicant.candidate_name}</TableCell>
                                                <TableCell onClick={() => handleNavigateToDetails(String(applicant.id))} sx={{ borderBottom: 'none' }}>{applicant.email}</TableCell>
                                                <TableCell onClick={() => handleNavigateToDetails(String(applicant.id))} sx={{ borderBottom: 'none' }}>{applicant.phone}</TableCell>
                                                <TableCell onClick={() => handleNavigateToDetails(String(applicant.id))} sx={{ borderBottom: 'none' }}>{applicant.salary}</TableCell>
                                                <TableCell onClick={() => handleNavigateToDetails(String(applicant.id))} sx={{ borderBottom: 'none' }}>{applicant.location}</TableCell>
                                                <TableCell onClick={() => handleNavigateToDetails(String(applicant.id))} sx={{ borderBottom: 'none' }}>{applicant.score}</TableCell>
                                                <TableCell onClick={() => handleNavigateToDetails(String(applicant.id))} sx={{ borderBottom: 'none' }}>
                                                <Typography variant="body2">{applicant.rank}</Typography>
                                                </TableCell>
                                                <TableCell onClick={() => handleNavigateToDetails(String(applicant.id))} sx={{ borderBottom: 'none' }}>
                                                    <Typography variant="body2">{applicant.notes}</Typography>
                                                </TableCell>
                                             
                                                <TableCell sx={{ borderBottom: 'none' }}>
                                                    <IconButton onClick={handleMenuClick}>
                                                        <MoreVert />
                                                    </IconButton>
                                                    <Menu
                                                        sx={{
                                                            '& .MuiPaper-root': {
                                                                boxShadow: 'none', // Remove box shadow
                                                                border: '1px solid #ccc', // Add border (adjust the color as needed)
                                                                borderRadius: '4px', // Optional: Add rounded corners
                                                            },
                                                        }}
                                                        anchorEl={anchorElMenu}
                                                        open={Boolean(anchorElMenu)}
                                                        onClose={handleMenuItemClose}
                                                    >
                                                        <MenuItem onClick={() => { handleMoveToNextStage(applicant.id); handleMenuItemClose(); }}>Invite to Interview</MenuItem>
                                                        <MenuItem onClick={() => { handleRejectApplicant(applicant.id); handleMenuItemClose(); }}>Reject</MenuItem>
                                                    </Menu>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>

                        </>
                    }



                </Box>
            </Box>
        </Layout >
    );
};

export default JobDetails;
