import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
    Typography,
    Box,
    CardContent,
    Button,
    Divider,
    CircularProgress,
    Alert,
    Grid,
    Tab,
    Tabs,
    BoxProps,
    List,
    Step,
    StepLabel,
    Stepper,
    Chip,
} from '@mui/material';
import { ArrowForward, Block, CheckCircle, RadioButtonUnchecked } from '@mui/icons-material';
import { fetchJobById, fetchCompanyById, fetchApplicantsUserWithJobId, Applicant, Job, fetchIntervieweesEvaluationsWithJobId, fetchIntervieweesOfUserWithJobId, Interviewee, fetchEvaluations, inviteUserToNextStep, fetchApplicantsDetailWithJobId, ApplicantDetailResult, fetchJobInterviewsByJobId, interviewDetail, fetchCandidateForTheJob, SelectedCandidates, fetchPendingActions, PendingAction } from '../api/api';
import Layout from '../components/Layout';
import CVEvaluationSummary from '../components/CVEvaluationSummary';
import ResponseItem from '../components/ResponseItem';
import EvaluationSummary from '../components/EvaluationSummary';
import { Evaluation } from '../components/IntervieweeItem';
import JobCardMini from '../components/JobCardMini';
import ApplicantCard from '../components/ApplicantCard';
import Loading from '../components/Loading';
import PendingActionCard from '../components/PendingActionCard';

interface ApplicantDetailProps extends BoxProps {
    applicant: any;
    job: any;
    company: any;
}

const ApplicantDetail: React.FC = () => {
    const { userID, jobID } = useParams<{ jobID: string; userID: string }>();
    const navigate = useNavigate();

    const [applicant, setApplicant] = useState<Applicant>();
    const [job, setJob] = useState<Job>();
    const [company, setCompany] = useState<any>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);
    const [tabValue, setTabValue] = useState(0);
    const [interviewee, setInterviewee] = useState<Interviewee | undefined>(undefined);
    const [evaluation, setEvaluation] = useState<Evaluation | null>(null);
    const [applicantDetails, setapplicantDetails] = useState<ApplicantDetailResult>()
    const [interviewList, setinterviewList] = useState<interviewDetail[]>([])
    const [candidateResult, setcandidateResult] = useState<SelectedCandidates>()
    const [pendingAction, setPendingAction] = useState<PendingAction>()
    useEffect(() => {
        const loadApplicantDetails = async () => {
            try {
                if (jobID && userID) {
                    // Fetch Applicant and Job details
                    const applicantData = await fetchApplicantsUserWithJobId(jobID, Number(userID));
                    setApplicant(applicantData);
                    const applicantDetail = await fetchApplicantsDetailWithJobId(jobID, Number(userID));
                    setapplicantDetails(applicantDetail)
                    const interviews = await fetchJobInterviewsByJobId(jobID);
                    setinterviewList(interviews)
                    const evalData = await fetchIntervieweesOfUserWithJobId(jobID, Number(userID));
                    const candidateResult = await fetchCandidateForTheJob(jobID, userID)
                    setcandidateResult(candidateResult)
                    setInterviewee(evalData[0])
                    const jobData = await fetchJobById(jobID);
                    setJob(jobData);
                    const applicantPendingActions = await fetchPendingActions({ applicantId: userID });
                    if (applicantPendingActions.length > 0)
                        setPendingAction(applicantPendingActions[0])
                    if (jobData.company_id) {
                        const companyData = await fetchCompanyById(jobData.company_id);
                        setCompany(companyData);
                    }
                }
            } catch (error) {
                setError('Failed to load applicant details');
            } finally {
                setLoading(false);
            }
        };

        loadApplicantDetails();
    }, [userID, jobID]);

    useEffect(() => {
        const fetchEvaluation = async () => {
            try {
                if (jobID === undefined || userID === undefined) return
                const response = await fetchEvaluations(jobID, userID)
                console.log("eval", response)
                setEvaluation(response);
            } catch (err) {
                console.error('Error fetching evaluation:', err);
                //setError('Failed to fetch evaluation data.');
            } finally {
                setLoading(false);
            }
        };
        fetchEvaluation();
    }, [jobID, userID]);

    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
        setTabValue(newValue);
    };

    const advanceUserToNextStep = () => {
        if (jobID && userID)
            inviteUserToNextStep(jobID, userID)
    }
    const sendAnOfferMail = () => {

    }

    const renderActionButton = () => {
        let actionButton = (
            <Button
                variant="contained"
                color="primary"
                endIcon={<ArrowForward />}
                onClick={advanceUserToNextStep}
                sx={{
                    fontWeight: 'bold',
                    padding: '8px 16px',
                    textTransform: 'none',
                    borderRadius: 3,
                }}
            >
                Advance to Next Stage
            </Button>
        )
        let declineActionButton = (<Button
            variant="outlined"
            color="error"
            startIcon={<Block />}
            onClick={() => navigate(`/reject-applicant/${jobID}/${userID}`)}
            sx={{
                fontWeight: 'bold',
                padding: '8px 16px',
                textTransform: 'none',
                borderRadius: 3,
            }}
        >
            Decline
        </Button>
        )


        if (candidateResult) {
            switch (candidateResult.status) {
                case "Selected":
                case "Not Selected":
                    actionButton = (<Button
                        variant="contained"
                        color="primary"
                        endIcon={<ArrowForward />}
                        onClick={sendAnOfferMail}
                        sx={{
                            fontWeight: 'bold',
                            padding: '8px 16px',
                            textTransform: 'none',
                            borderRadius: 3,
                        }}
                    >
                        Send an Offer
                    </Button>
                    )
                    break;

                default:
                    actionButton = (
                        <Button
                            variant="contained"
                            color="primary"
                            endIcon={<ArrowForward />}
                            onClick={advanceUserToNextStep}
                            sx={{
                                fontWeight: 'bold',
                                padding: '8px 16px',
                                textTransform: 'none',
                                borderRadius: 3,
                            }}
                        >
                            Advance to Next Stage
                        </Button>
                    )
                    break;
            }
        }

        return (
            <Box
                sx={{
                    mb: 3,
                    display: "flex",
                    justifyContent: { xs: 'center', md: 'flex-end' },
                    gap: 2,
                }}
            >
                {actionButton}
                {declineActionButton}
            </Box>
        )
    }

    if (loading || !applicant || !job) return <Loading />;
    if (error) return <Alert severity="error">{error}</Alert>;

    return (
        <Layout>
            {applicant && job && (
                <Box sx={{ pt: 1 }}>




                    <Grid container spacing={3}>
                        {/* Left Panel: Applicant Information */}


                        {/* Right Panel: CV Evaluation & Resume */}
                        {/* Right Panel: CV and Cover Letter */}




                        <Grid item xs={12} md={4}>
                          
                            <ApplicantCard jobTitle={job.job_title} applicant={applicant} />
                            <Box mt={2} />
                            <JobCardMini  job={job} />


                        </Grid>

                        <Grid item xs={12} md={8}>
                        {renderActionButton()}
 

                            <Box>



                                <Typography
                                    variant="h4"
                                    sx={{
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                        gap: 2,
                                        fontWeight: 'bold',
                                        marginBottom: 1
                                    }}
                                >

                                    {candidateResult && (
                                        <Box>
                                            <Chip
                                                size="small"
                                                label={candidateResult?.status === "Selected" ? "Waiting For Offer" : "Under Review"}
                                                sx={{
                                                    backgroundColor: candidateResult?.status === "Selected" ? "success.main" : "warning.light", // Stronger green for selected
                                                    color: candidateResult?.status === "Selected" ? "white" : "warning.contrastText", // Contrasting text color
                                                    fontWeight: 700, // Bolder text for emphasis
                                                    borderRadius: "12px", // Rounded edges
                                                    padding: "8px 16px", // Extra padding for visibility

                                                    textTransform: "uppercase", // Consistent text styling
                                                    letterSpacing: "0.1em", // Improved readability
                                                    cursor: "default", // No pointer cursor
                                                    transition: "all 0.3s ease", // Smooth transition
                                                    "&::before": candidateResult?.status === "Selected"
                                                        ? {
                                                            content: `"🌟"`, // Star emoji for extra highlight
                                                            marginRight: "8px",
                                                        }
                                                        : undefined,
                                                }}
                                            />
                                        </Box>
                                    )}
                                </Typography>



                            </Box>


                            <Box sx={{}}>
                                <CardContent sx={{ p: 0 }}>
                                    <Grid item xs={12} md={12}>
                                        {/* Stepper Progress */}
                                        <Box sx={{ mb: 3 }}>
                                            <Typography sx={{ textAlign: "center", mb: 3, fontWeight: 'bold' }} variant="subtitle2" color="text.primary">
                                                Interview Stages
                                            </Typography>

                                            {applicantDetails && applicantDetails.interviewResult && (
                                                <Stepper activeStep={1}>
                                                    {/* Initial Step: Applied */}
                                                    {['Applied'].map((label, index) => (
                                                        <Step key={label}>
                                                            <StepLabel
                                                                icon={<CheckCircle color="primary" />}
                                                                sx={{
                                                                    color: 'text.primary',
                                                                    fontWeight: 'medium',
                                                                }}

                                                            >
                                                                {label}
                                                            </StepLabel>
                                                        </Step>
                                                    ))}

                                                    {/* Dynamically render the interview stages */}
                                                    {applicantDetails.interviewResult.map((val, index) => (
                                                        <Step key={val.id}>
                                                            <StepLabel
                                                                icon={
                                                                    applicantDetails.applicantResult.some(
                                                                        (result) => result.interview_stage_id === val.id
                                                                    ) ? (
                                                                        <CheckCircle color="primary" />
                                                                    ) : (
                                                                        <RadioButtonUnchecked color="action" />
                                                                    )
                                                                }
                                                                sx={{
                                                                    color: 'text.primary',
                                                                    fontWeight: 'medium',
                                                                }}
                                                            >
                                                                {val.interview_name}

                                                            </StepLabel>
                                                        </Step>
                                                    ))}
                                                </Stepper>
                                            )}
                                        </Box>
                                        {/* Action Buttons */}
                                        {pendingAction && jobID &&
                                            <PendingActionCard job_title={job.job_title} jobID={jobID} candidateResult={candidateResult} applicant={applicant} pendingAction={pendingAction} />
                                        }
                                    </Grid>


                                    {/* Tab Section for Additional Information */}
                                    <Box sx={{ marginTop: 3 }}>
                                        <Tabs sx={{
                                            mt: 1,
                                            '& .MuiTab-root': {
                                                textTransform: 'capitalize', // Makes labels more readable
                                                fontWeight: 500,
                                                fontSize: '1rem',
                                                color: 'text.secondary',
                                                '&.Mui-selected': {
                                                    color: 'primary.main',
                                                    fontWeight: 'bold', // Highlights selected tab
                                                },
                                            },
                                            '& .MuiTabs-indicator': {
                                                backgroundColor: 'primary.main', // Consistent theme color
                                                height: '3px', // Thicker indicator for emphasis
                                            },
                                        }}
                                            value={tabValue} onChange={handleTabChange} aria-label="applicant details tabs">
                                            <Tab label="CV Eval" />
                                            {
                                                interviewList.map((val) => <Tab key={val.id} label={val.interview_name} />)
                                            }
                                            <Tab label="Resume" />
                                        </Tabs>

                                        {/* Tab Panel 1: Cover Letter */}
                                        {tabValue === 0 && (
                                            <Box sx={{ marginTop: 2 }}>
                                                <Typography variant="h6" color="textSecondary">CV Evaluation</Typography>
                                                <CVEvaluationSummary evaluation={applicant.cv_evaluation} />
                                                <Divider sx={{ marginTop: 2, marginBottom: 2 }} />
                                                <Typography variant="h6" color="textSecondary">Interview Evaluation Summary</Typography>

                                                {evaluation && <EvaluationSummary evaluation={evaluation} />}
                                            </Box>
                                        )}

                                        {/* Tab Panel 2: Interview Feedback */}
                                        {tabValue >= 1 && tabValue < interviewList.length + 1 && (
                                            <>

                                                {/* Responses Section */}
                                                <Box sx={{ marginTop: 2 }}>
                                                    <Typography variant="h6" color="textSecondary">Interview Responses</Typography>

                                                    <List>
                                                        {interviewee?.responses.filter((val) => val.interviewID === interviewList[tabValue - 1].id).map((response, index) => (
                                                            <Box key={index}>
                                                                <ResponseItem
                                                                    response={response}
                                                                    userID={String(userID)}
                                                                    jobID={String(jobID)}
                                                                    questionID={response.questionID}
                                                                    evaluation={evaluation?.response_evaluation.details[response.questionID]}
                                                                />
                                                                {index < (interviewee.responses.length - 1) && <Divider />}
                                                            </Box>
                                                        ))}
                                                    </List>

                                                </Box>
                                            </>
                                        )}

                                        {/* Tab Panel 3: Notes */}
                                        {tabValue === interviewList.length + 1 && (
                                            <Box sx={{ marginTop: 2 }}>
                                                {applicant.resume && applicant.resume.endsWith('.pdf') ? (
                                                    <iframe
                                                        src={applicant.resume}
                                                        width="100%"
                                                        height="600px"
                                                        style={{ border: 'none', borderRadius: '8px' }}
                                                        title="Resume PDF"
                                                    />
                                                ) : (
                                                    <Typography variant="body2" color="textSecondary">
                                                        The resume is not a PDF file, or it could not be loaded.
                                                    </Typography>
                                                )}
                                            </Box>
                                        )}
                                    </Box>



                                </CardContent>
                            </Box>
                        </Grid>

                    </Grid>




                </Box>
            )
            }
        </Layout >
    );
};

export default ApplicantDetail;
