import React, { useEffect, useState } from 'react';
import {
    fetchJobsByCompany,
    updateCompany,
    Job,
    Company,
    fetchCompanyWithToken
} from '../api/api';
import {
    Container,
    Typography,
    Box,
    CircularProgress,
    Alert,
} from '@mui/material';
import Layout from '../components/Layout';
import JobListingsSection from '../components/JobListingsSection';
import { useAuth } from '../context/AuthContext';
import CompanyDetailsCard from '../components/CompanyDetailsCard';
import JobAnalytics from '../components/JobAnalytics';
import Loading from '../components/Loading';


const CompanyDashboard: React.FC = () => {
    const { token } = useAuth();
    const [company, setCompany] = useState<Company | null>(null);
    const [jobs, setJobs] = useState<Job[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);


    useEffect(() => {
        const loadCompany = async () => {
            try {
                if (token) {
                    const companyData = await fetchCompanyWithToken(token);
                    setCompany(companyData);

                }
            } catch (error) {
                setError('Failed to load company details or jobs');
            } finally {
                setLoading(false);
            }
        };

        loadCompany();
    }, [token]);

    useEffect(() => {
        const loadJobs = async () => {
            try {
                if (company?.id) {
                    const companyData = await fetchJobsByCompany(company.id);
                    setJobs(companyData);

                }
            } catch (error) {
                setError('Failed to load company details or jobs');
            } finally {
                setLoading(false);
            }
        };

        loadJobs();
    }, [company]);

    const handleSaveCompany = async (formData: any) => {
        try {
            if (company) {
                const updatedCompany = await updateCompany(Number(company.id), {
                    name: formData.name,
                    description: formData.description,
                    location: formData.location,
                    website_url: formData.website_url,
                    sector: formData.sector,
                    num_employees: formData.num_employees,
                    type: formData.type,
                    contact_info: formData.contact_info,
                });
                setCompany(updatedCompany);
                setError(null);
            }
        } catch (error) {
            setError('Failed to update company');
        }
    };


    if (loading) return <Loading />;
    if (error) return <Alert severity="error">{error}</Alert>;

    return (
        <Layout>
            <Container maxWidth="lg" sx={{ mt: 4 }}>
                {company ? (
                    <Box>
                        {/* Banner */}
                        <CompanyDetailsCard handleSaveCompany={handleSaveCompany} company={company} />

                        {/* Job Listings */}
                        <JobAnalytics />
                        <JobListingsSection jobs={jobs} />
                    </Box>
                ) : (
                    <Typography>Company not found</Typography>
                )}
            </Container>
        </Layout>
    );
};

export default CompanyDashboard;
