import React, { ReactNode, useState } from 'react';
import { AppBar, Toolbar, Typography, Container, Button, Drawer, List, ListItem, ListItemText, IconButton, Divider, Collapse, InputBase, Badge, Box, Chip, Menu, MenuItem, Avatar } from '@mui/material';
import { Link } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import MenuIcon from '@mui/icons-material/Menu';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { Close, MarkChatRead, MarkUnreadChatAlt, Notifications, Search } from '@mui/icons-material';
import { formatDistanceToNow } from 'date-fns';
import { useMediaQuery } from '@mui/material';
import SearchCard from './SearchCard';
import Footer from './Footer';



interface LayoutProps {
    children: ReactNode;
}

const Layout: React.FC<LayoutProps> = ({ children }) => {
    const { logout } = useAuth();
    const [open, setOpen] = useState(false);
    const [jobsMenuOpen, setJobsMenuOpen] = useState(false);
    const [applicantsMenuOpen, setApplicantsMenuOpen] = useState(false);
    const [settingsMenuOpen, setSettingsMenuOpen] = useState(false);
    const [companyMenuOpen, setcompanyMenuOpen] = useState(false);
    const [reportsMenuOpen, setReportsMenuOpen] = useState(false);

    const [unreadNotifications, setUnreadNotifications] = React.useState(3); // Example count
    const [notifications, setNotifications] = useState([
        { id: 1, text: "New job posted", status: "unread", urgency: "high", timestamp: new Date() },
        { id: 2, text: "Interview scheduled", status: "read", urgency: "medium", timestamp: new Date() },
        { id: 3, text: "Candidate application received", status: "unread", urgency: "low", timestamp: new Date() },
    ]);
    const [notificationDrawerOpen, setNotificationDrawerOpen] = React.useState(false); // To control Drawer open/close
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const isMobile = useMediaQuery('(max-width:600px)');


    const handleMenuOpen = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleMarkAsRead = (notificationId: number) => {
        setNotifications((prevNotifications) =>
            prevNotifications.map((notification) =>
                notification.id === notificationId ? { ...notification, status: "read" } : notification
            )
        );
    };

    const handleMarkAsUnread = (notificationId: number) => {
        setNotifications((prevNotifications) =>
            prevNotifications.map((notification) =>
                notification.id === notificationId ? { ...notification, status: "unread" } : notification
            )
        );
    };

    const handleMarkAllAsRead = () => {
        setNotifications((prevNotifications) =>
            prevNotifications.map((notification) => ({ ...notification, status: "read" }))
        );
    };

    const getUrgencyColor = (urgency: string) => {
        switch (urgency) {
            case "high":
                return "error";
            case "medium":
                return "warning";
            case "low":
                return "primary";
            default:
                return "default";
        }
    };

    // Toggle reports menu
    const toggleReportsMenu = () => {
        setReportsMenuOpen(!reportsMenuOpen);
    };

    const toggleCompanyMenu = () => {
        setcompanyMenuOpen(!companyMenuOpen);
    };

    // Toggle drawer open/close
    const toggleDrawer = () => setOpen(!open);

    // Toggle submenu open/close
    const toggleJobsMenu = () => setJobsMenuOpen(!jobsMenuOpen);
    const toggleApplicantsMenu = () => setApplicantsMenuOpen(!applicantsMenuOpen);
    const toggleSettingsMenu = () => setSettingsMenuOpen(!settingsMenuOpen);

    const handleNotificationClick = () => {
        setNotificationDrawerOpen(true); // Open the notification drawer
    };

    const handleCloseNotificationDrawer = () => {
        setNotificationDrawerOpen(false); // Close the notification drawer
    };


    return (
        <>
            {/* AppBar for the top of the page */}
            <AppBar position="sticky">
                <Toolbar style={{ backgroundColor: "#6a4c9c", padding: "0 16px" }}>
                    {/* Menu Icon */}
                    <IconButton color="inherit" onClick={toggleDrawer} edge="start">
                        <MenuIcon />
                    </IconButton>

                    {/* Title */}
                    <Typography style={{ fontWeight: 'bold', flex: 1, display: isMobile ? 'none' : 'block' }}>
                        OptiHire AI
                    </Typography>
                    <SearchCard />
                    <Divider sx={{ display: isMobile ? 'none' : 'block', flexGrow: 1, height: 28, m: 0.5 }} orientation="vertical" />

                    {/* Notifications Icon Button */}
                    <IconButton color="primary" onClick={handleNotificationClick} sx={{ position: 'relative' }}>
                        <Notifications sx={{ color: "white" }} />
                        {unreadNotifications > 0 && (
                            <Badge
                                badgeContent={unreadNotifications}
                                color="error"
                                sx={{ position: "absolute", top: 10, right: 10 }}
                            />
                        )}
                    </IconButton>

                    <Avatar
                        onClick={(e) => { handleMenuOpen(e) }}
                        src={"/default-avatar.png"}
                        alt={"user.name"}
                        sx={{
                            cursor: "pointer",
                            marginLeft: 2,
                            width: 36,
                            height: 36,
                            border: "2px solid white",
                            "&:hover": { borderColor: "#3f51b5" },
                        }}
                    />
                    {/* Divider */}


                </Toolbar>
            </AppBar>
            {/* Account Menu */}
            <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleMenuClose}>
                <MenuItem onClick={handleMenuClose}>Profile</MenuItem>
                <MenuItem onClick={handleMenuClose}>Settings</MenuItem>
                <MenuItem onClick={logout}>Logout</MenuItem>
            </Menu>

            {/* Drawer for the left-side menu */}
            <Drawer
                anchor="left"
                open={open}
                onClose={toggleDrawer}
                variant="temporary"
                sx={{
                    '& .MuiDrawer-paper': {
                        width: 250,
                        backgroundColor: "#6a4c9c", // New, softer purple background
                        padding: '16px',
                        boxShadow: '4px 0px 10px rgba(0,0,0,0.1)',
                        color: 'white', // White text for better contrast
                    },
                }}
            >
                <List>
                    <Typography variant="h6" style={{ fontWeight: 'bold', marginBottom: 20, color: '#fff' }}>
                        Company Name
                    </Typography>
                    <ListItem component={Link} to="/" onClick={() => setOpen(false)} style={styles.menuItem}>
                        <ListItemText primary="Home" />
                    </ListItem>

                    {/* Company Section */}
                    <ListItem onClick={toggleCompanyMenu} style={styles.menuItem}>
                        <ListItemText primary="Company" />
                        {companyMenuOpen ? <ExpandLess style={{ color: 'white' }} /> : <ExpandMore style={{ color: 'white' }} />}
                    </ListItem>
                    <Collapse in={companyMenuOpen} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                            <ListItem component={Link} to="/company/dashboard" onClick={() => setOpen(false)} style={styles.subMenuItem}>
                                <ListItemText primary="Company Info" />
                            </ListItem>

                            <ListItem component={Link} to="/company/team-management" onClick={() => setOpen(false)} style={styles.subMenuItem}>
                                <ListItemText primary="Team Management" />
                            </ListItem>
                            <ListItem component={Link} to="/company/billing" onClick={() => setOpen(false)} style={styles.subMenuItem}>
                                <ListItemText primary="Billing" />
                            </ListItem>
                            <ListItem component={Link} to="/company/subscriptions" onClick={() => setOpen(false)} style={styles.subMenuItem}>
                                <ListItemText primary="Subscriptions" />
                            </ListItem>
                        </List>
                    </Collapse>
                    {/* Jobs Section with Submenu */}
                    <ListItem onClick={toggleJobsMenu} style={styles.menuItem}>
                        <ListItemText primary="Jobs" />
                        {jobsMenuOpen ? <ExpandLess style={{ color: 'white' }} /> : <ExpandMore style={{ color: 'white' }} />}
                    </ListItem>
                    <Collapse in={jobsMenuOpen} timeout="auto" unmountOnExit>


                        <ListItem component={Link} to="/add-job/" onClick={() => setOpen(false)} style={styles.subMenuItem}>
                            <ListItemText primary="Add New Job" />
                        </ListItem>
                        <ListItem component={Link} to="/list/jobs" onClick={() => setOpen(false)} style={styles.subMenuItem}>
                            <ListItemText primary="List Jobs" />
                        </ListItem>
                        <ListItem component={Link} to="/jobs/active" onClick={() => setOpen(false)} style={styles.subMenuItem}>
                            <ListItemText primary="Active Jobs" />
                        </ListItem>
                        <ListItem component={Link} to="/jobs/closed" onClick={() => setOpen(false)} style={styles.subMenuItem}>
                            <ListItemText primary="Closed Jobs" />
                        </ListItem>
                    </Collapse>

                    {/* Applicants Section with Submenu */}
                    <ListItem onClick={toggleApplicantsMenu} style={styles.menuItem}>
                        <ListItemText primary="Applicants" />
                        {applicantsMenuOpen ? <ExpandLess style={{ color: 'white' }} /> : <ExpandMore style={{ color: 'white' }} />}
                    </ListItem>
                    {applicantsMenuOpen && (
                        <>
                            <ListItem component={Link} to="/applicants/list" onClick={() => setOpen(false)} style={styles.subMenuItem}>
                                <ListItemText primary="List All Applicants" />
                            </ListItem>
                            <ListItem component={Link} to="/applicants/interviewing" onClick={() => setOpen(false)} style={styles.subMenuItem}>
                                <ListItemText primary="Interviewing" />
                            </ListItem>
                            <ListItem component={Link} to="/applicants/hired" onClick={() => setOpen(false)} style={styles.subMenuItem}>
                                <ListItemText primary="Hired" />
                            </ListItem>
                            <ListItem component={Link} to="/applicants/rejected" onClick={() => setOpen(false)} style={styles.subMenuItem}>
                                <ListItemText primary="Rejected" />
                            </ListItem>
                        </>
                    )}

                    <ListItem onClick={toggleReportsMenu} style={styles.menuItem}>
                        <ListItemText primary="Report" />
                        {reportsMenuOpen ? <ExpandLess style={{ color: 'white' }} /> : <ExpandMore style={{ color: 'white' }} />}
                    </ListItem>
                    <Collapse in={reportsMenuOpen} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>

                            <ListItem component={Link} to="/company/reports/job-performance" onClick={() => setOpen(false)} style={styles.subMenuItem}>
                                <ListItemText primary="Job Performance Report" />
                            </ListItem>
                            <ListItem component={Link} to="/company/reports/applicants" onClick={() => setOpen(false)} style={styles.subMenuItem}>
                                <ListItemText primary="Applicant Report" />
                            </ListItem>

                            <ListItem component={Link} to="/company/reports/company-growth" onClick={() => setOpen(false)} style={styles.subMenuItem}>
                                <ListItemText primary="Company Growth Report" />
                            </ListItem>

                        </List>
                    </Collapse>
                    {/* Settings Section with Submenu */}
                    <ListItem onClick={toggleSettingsMenu} style={styles.menuItem}>
                        <ListItemText primary="Settings" />
                        {settingsMenuOpen ? <ExpandLess style={{ color: 'white' }} /> : <ExpandMore style={{ color: 'white' }} />}
                    </ListItem>
                    {settingsMenuOpen && (
                        <>
                            <ListItem component={Link} to="/create-email-template" onClick={() => setOpen(false)} style={styles.subMenuItem}>
                                <ListItemText primary="New Email Templates" />
                            </ListItem>
                            <ListItem component={Link} to="/email-templates" onClick={() => setOpen(false)} style={styles.subMenuItem}>
                                <ListItemText primary="List Email Templates" />
                            </ListItem>
                            <ListItem component={Link} to="/settings/account" onClick={() => setOpen(false)} style={styles.subMenuItem}>
                                <ListItemText primary="Account Management" />
                            </ListItem>
                            <ListItem component={Link} to="/settings/branding" onClick={() => setOpen(false)} style={styles.subMenuItem}>
                                <ListItemText primary="Company Branding" />
                            </ListItem>
                        </>
                    )}

                    <Divider style={{ margin: '16px 0', backgroundColor: '#ddd' }} />

                    <ListItem onClick={logout} style={styles.menuItem}>
                        <ListItemText primary="Logout" />
                    </ListItem>
                </List>
            </Drawer>
            {/* Notifications Drawer */}
            <Drawer
                anchor="right"
                open={notificationDrawerOpen}
                onClose={handleCloseNotificationDrawer}
                sx={{
                    width: 350,
                    flexShrink: 0,
                    "& .MuiDrawer-paper": {
                        width: 350,
                        padding: 2,
                        backgroundColor: "white",
                        boxShadow: 3,
                    },
                }}
            >
                <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                    <Typography variant="h6" sx={{ marginBottom: 1 }}>
                        Notifications
                    </Typography>
                    <IconButton onClick={handleCloseNotificationDrawer} color="primary">
                        <Close />
                    </IconButton>
                </Box>

                {/* Mark All Read Button */}
                <Button
                    variant="outlined"
                    color="primary"
                    fullWidth
                    sx={{ marginBottom: 2 }}
                    onClick={handleMarkAllAsRead}
                >
                    Mark All as Read
                </Button>

                {notifications.map((notification) => (
                    <React.Fragment key={notification.id}>
                        <Box
                            sx={{
                                padding: 1,
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                backgroundColor: notification.status === "unread" ? "#f4f4f4" : "transparent",
                                "&:hover": {
                                    backgroundColor: "#e0e0e0",
                                },
                            }}
                        >
                            <Typography variant="body2" sx={{ flexGrow: 1 }}>
                                {notification.text}
                                <Typography variant="caption" color="textSecondary" sx={{ display: "block" }}>
                                    {formatDistanceToNow(new Date(notification.timestamp))} ago
                                </Typography>
                            </Typography>
                            <Chip
                                label={notification.urgency.charAt(0).toUpperCase() + notification.urgency.slice(1)}
                                color={getUrgencyColor(notification.urgency)}
                                size="small"
                                sx={{ marginRight: 1 }}
                            />
                            <IconButton

                                onClick={() =>
                                    notification.status === "unread"
                                        ? handleMarkAsRead(notification.id)
                                        : handleMarkAsUnread(notification.id)
                                }
                            >
                                {notification.status === "unread" ? <MarkChatRead /> : <MarkUnreadChatAlt />}
                            </IconButton>
                        </Box>
                        <Divider />
                    </React.Fragment>
                ))}
            </Drawer>
            {/* Main content container */}
            <Container style={{minHeight:550, paddingTop: '20px' }}>
                {children}
            </Container>
            <Footer />

        </>
    );
};

// Styles for the menu and submenus
const styles = {
    menuItem: {
        padding: '10px 20px',
        fontSize: '16px',
        color: 'white',
        '&:hover': {
            backgroundColor: '#7b5fb6', // Lighter purple on hover
            cursor: 'pointer',
        },
    },
    subMenuItem: {
        paddingLeft: '40px',
        fontSize: '14px',
        color: '#ddd', // Lighter color for submenus
        '&:hover': {
            backgroundColor: '#4a3e74',
            cursor: 'pointer',
        },
    },
};

export default Layout;
