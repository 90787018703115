import React from "react";
import { Box, Card, CardContent, Typography, Grid } from "@mui/material";
import TrendingUpRoundedIcon from "@mui/icons-material/TrendingUpRounded";

interface SummaryCardProps {
  title: string;
  value: string;
  increaseRate: number;
  iconColor: string;
}

const SummaryCard: React.FC<SummaryCardProps> = ({ title, value, increaseRate, iconColor }) => {
  return (
    <Card
      sx={{
        display: "flex",
        alignItems: "center",
        p: 0,
        borderRadius: 2,
        boxShadow: 2.5,
        transition: "0.3s",
        "&:hover": {
          boxShadow: 4,
          transform: "scale(1.02)",
        },
      }}
    >
      <Grid container sx={{ flex: 1 }}>
        <CardContent sx={{ flex: 1, p: 2 }}>
          <Typography variant="subtitle1" color="text.secondary" gutterBottom>
            {title}
          </Typography>
          <Typography variant="h5" fontWeight="bold" color="text.primary">
            {value}
          </Typography>
          <Box
            sx={{
              mt: 1,
              display: "flex",
              alignItems: "center",
            }}
          >
            <TrendingUpRoundedIcon
              sx={{
                fontSize: 15,
                backgroundColor: iconColor,
                borderRadius: "50%",
                padding: 0.5,
                mr: 1,
                color: "white",
              }}
            />
            <Typography variant="body1" color={iconColor}>
              +{increaseRate}% Inc
            </Typography>
          </Box>
        </CardContent>
      </Grid>
    </Card>
  );
};

export default SummaryCard;
