import React from "react";
import { Box, Typography, Chip } from "@mui/material";
import { Applicant,  SelectedCandidates } from "../api/api";

interface PendingActionProps {
    applicant: Applicant
    pendingAction: {
        aiComment?: string;
        action?: string;
        status?: string;
    };
    jobID: string;
    candidateResult?: SelectedCandidates,
    job_title: string
}

const PendingActionCard: React.FC<PendingActionProps> = ({ job_title, candidateResult, jobID, applicant, pendingAction }) => {

     
    return (
        <Box
            sx={{
                marginBottom: 2,
                padding: 2,
                borderRadius: 2,
                backgroundColor: "background.paper",
            }}
        >
            <Box>

              
                {/* AI Comment */}
                {pendingAction?.aiComment && (
                    <Typography variant="body2" color="text.secondary" sx={{ marginBottom: 1 }}>
                        <strong>AI Comment:</strong> {pendingAction.aiComment}
                    </Typography>
                )}

                {/* Action */}
                {pendingAction?.action && (
                    <Typography variant="body2" sx={{ marginBottom: 1 }}>
                        <strong>Action:</strong> {pendingAction.action}
                    </Typography>
                )}

                {/* Status */}
                {pendingAction?.status && (
                    <Box sx={{ marginTop: 1 }}>
                        <Chip
                            label={pendingAction.status}
                            size="small"
                            sx={{
                                fontWeight: 600,
                                textTransform: "uppercase",
                                backgroundColor:
                                    pendingAction.status === "Pending"
                                        ? "warning.light"
                                        : pendingAction.status === "Resolved"
                                            ? "success.light"
                                            : "error.light",
                                color:
                                    pendingAction.status === "Pending"
                                        ? "warning.contrastText"
                                        : pendingAction.status === "Resolved"
                                            ? "success.contrastText"
                                            : "error.contrastText",
                                padding: "6px 12px",
                                borderRadius: "16px",
                            }}
                        />
                        <Typography
                            variant="body2"
                            color="text.secondary"
                            sx={{ marginTop: 0.5, fontStyle: "italic" }}
                        >
                            {pendingAction.status === "Pending"
                                ? "Pending user approval for invitation or rejection."
                                : pendingAction.status === "Resolved"
                                    ? "Action has been successfully resolved."
                                    : "Action could not be completed or was declined."}
                        </Typography>
                    </Box>
                )}

            </Box>
        </Box>
    );
};

export default PendingActionCard;
