import React, { useEffect, useState } from 'react';
import {
    Button, TextField, Snackbar, CircularProgress, Dialog, DialogActions, DialogContent,
    DialogTitle, Table, TableBody, TableCell, TableHead, TableRow, Alert,
    MenuItem, Select, InputLabel, FormControl, styled, FormHelperText, Box, IconButton, Menu, TableContainer, Paper
} from '@mui/material';
import Layout from '../components/Layout';
import { MoreVert } from '@mui/icons-material';
import theme from '../theme';
import { addTeamMembers, deleteTeamMembers, getTeamMembers, TeamMembers, updateTeamMembers } from '../api/api';

// Dummy data for team members

const TeamMemberForm = ({ onSubmit, memberToEdit }: { onSubmit: any, memberToEdit?: any }) => {
    const [loading, setLoading] = useState(false);
    const [role, setRole] = useState(memberToEdit?.role || '');
    const [status, setStatus] = useState(memberToEdit?.status || 'Active');
    const [name, setName] = useState(memberToEdit?.name || '');
    const [email, setEmail] = useState(memberToEdit?.email || '');
    const [error, setError] = useState<string | null>(null);
    const [password, setpassword] = useState("")


    const handleSubmit = async () => {
        setError(null); // Clear any previous error
        if (!name || !email || !role) {
            setError('All fields are required');
            return;
        }

        setLoading(true);
        try {
            await onSubmit({ name, password, email, role, status });
        } catch (error) {
            setError('Failed to save changes');
        } finally {
            setLoading(false);
        }
    };

    return (
        <Box sx={{ width: 500 }}>
            <div>
                <TextField
                    label="Name"
                    variant="outlined"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    fullWidth
                    margin="normal"
                    error={!!error}
                />
            </div>
            <div>
                <TextField
                    label="Email"
                    variant="outlined"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    fullWidth
                    margin="normal"
                    error={!!error}
                />
            </div>
            <div>
                <TextField
                    label="Password"
                    type={"password"}
                    variant="outlined"
                    value={password}
                    onChange={(e) => setpassword(e.target.value)}
                    fullWidth
                    margin="normal"
                    error={!!error}
                />
            </div>

            <div>
                <FormControl fullWidth margin="normal" error={!!error}>
                    <InputLabel>Status</InputLabel>
                    <Select
                        value={status}
                        onChange={(e) => setStatus(e.target.value)}
                        label="Status"
                    >
                        <MenuItem value="Active">Active</MenuItem>
                        <MenuItem value="Inactive">Inactive</MenuItem>
                    </Select>
                    {error && <FormHelperText>{error}</FormHelperText>}
                </FormControl>
            </div>

            <div>
                <FormControl fullWidth margin="normal" error={!!error}>
                    <InputLabel>Role</InputLabel>
                    <Select
                        value={role}
                        onChange={(e) => setRole(e.target.value)}
                        label="Role"
                    >
                        <MenuItem value="Admin">Admin</MenuItem>
                        <MenuItem value="Hiring Manager">Hiring Manager</MenuItem>
                        <MenuItem value="Recruiter">Recruiter</MenuItem>
                        <MenuItem value="Interviewer">Interviewer</MenuItem>
                    </Select>
                    {error && <FormHelperText>{error}</FormHelperText>}
                </FormControl>
            </div>

            <Button
                onClick={handleSubmit}
                variant="contained"
                color="primary"
                fullWidth
                disabled={loading}
                sx={{ marginTop: 2 }}
            >
                {loading ? <CircularProgress size={24} /> : 'Save Changes'}
            </Button>
        </Box>
    );
};

const TeamMemberPage = () => {
    const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });
    const [searchTerm, setSearchTerm] = useState('');
    const [teamMembers, setTeamMembers] = useState<TeamMembers[]>([]);
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [memberToDelete, setMemberToDelete] = useState<string | null>(null);
    const [addDialogOpen, setAddDialogOpen] = useState(false);
    const [editDialogOpen, setEditDialogOpen] = useState(false);
    const [memberToEdit, setMemberToEdit] = useState<any>(null);
    const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
    const [memberForMenu, setMemberForMenu] = useState<any>(null);

    const handleMenuOpen = (event: React.MouseEvent<HTMLElement>, member: any) => {
        setMenuAnchorEl(event.currentTarget);
        setMemberForMenu(member);
    };

    const handleMenuClose = () => {
        setMenuAnchorEl(null);
        setMemberForMenu(null);
    };

    useEffect(() => {
        const fetchTeamMembers = async () => {
            const res = await getTeamMembers()
            setTeamMembers(res)
        }
        fetchTeamMembers()
    }, [])


    const handleSubmit = async (values: any) => {
        try {
            if (values.name && values.email && values.password) {
                console.log(values)
                const { email, name, password, role, status } = values
                const res = await addTeamMembers(name, email, password, role, status)
                setTeamMembers([...teamMembers, { ...values, id: teamMembers.length + 1 }]);
                setSnackbar({ open: true, message: 'Team member added successfully!', severity: 'success' });
                setAddDialogOpen(false);
            } else {
                setSnackbar({ open: true, message: 'Name ,Email and Passwords are required fields', severity: 'error' });
            }
        } catch (error) {
            setSnackbar({ open: true, message: 'Failed to add team member', severity: 'error' });
        }
    };

    const handleEdit = (member: any) => {
        setMemberToEdit(member);
        setEditDialogOpen(true);
    };

    const handleDelete = async () => {
        if (memberToDelete !== null) {
            try {
                await deleteTeamMembers(memberToDelete)
                setTeamMembers(teamMembers.filter((member) => member.id !== memberToDelete));
                setDeleteDialogOpen(false);
                setSnackbar({ open: true, message: 'Team member deleted successfully!', severity: 'success' });
            } catch (error) {
                setDeleteDialogOpen(false);
                setSnackbar({ open: true, message: 'Error While deleting member!', severity: 'error' });

            }

        }
    };

    const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(e.target.value);
    };

    const filteredMembers = teamMembers.filter(
        (member) =>
            member.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
            member.role.toLowerCase().includes(searchTerm.toLowerCase())
    );



    return (
        <Layout>
            <Box>
                <h2>Create and Manage Team Members</h2>

                {/* Button to open the Add Team Member modal */}
                <Button variant="contained" color="primary" onClick={() => setAddDialogOpen(true)}>
                    Add Team Member
                </Button>

                <Snackbar

                    open={snackbar.open}
                    autoHideDuration={3000}
                    onClose={() => setSnackbar({ ...snackbar, open: false })}
                >
                    <Alert severity={snackbar.severity === "success" ? "success" : "error"} >{snackbar.message}</Alert>
                </Snackbar>

                <TextField
                    label="Search Team Members"
                    variant="outlined"
                    value={searchTerm}
                    onChange={handleSearchChange}
                    fullWidth
                    margin="normal"
                />
                <TableContainer component={Paper}>

                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Name</TableCell>
                                <TableCell>Email</TableCell>
                                <TableCell>Role</TableCell>
                                <TableCell>Status</TableCell>
                                <TableCell>Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {filteredMembers.map((member) => {
                                return (
                                    <TableRow sx={{
                                        '&:hover': {
                                            backgroundColor: theme.palette.action.hover,
                                        },
                                    }} key={member.id}>
                                        <TableCell>{member.name}</TableCell>
                                        <TableCell>{member.email}</TableCell>
                                        <TableCell>{member.role}</TableCell>
                                        <TableCell>{member.status}</TableCell>
                                        <TableCell>
                                            {/* Actions Menu */}
                                            <IconButton
                                                aria-controls={`menu-${member.id}`}
                                                aria-haspopup="true"
                                                onClick={(event) => handleMenuOpen(event, member)}
                                            >
                                                <MoreVert />
                                            </IconButton>

                                            <Menu
                                                id={`menu-${member.id}`}
                                                anchorEl={menuAnchorEl ? menuAnchorEl : undefined}
                                                open={Boolean(menuAnchorEl) && memberForMenu?.id === member.id} // Ensure it opens only for the correct member
                                                onClose={handleMenuClose}
                                            >
                                                <MenuItem onClick={() => {
                                                    handleMenuClose();
                                                    handleEdit(memberForMenu);
                                                }}>
                                                    Edit
                                                </MenuItem>
                                                <MenuItem onClick={() => {
                                                    handleMenuClose();
                                                    setDeleteDialogOpen(true);
                                                    setMemberToDelete(memberForMenu.id);
                                                }}>
                                                    Delete
                                                </MenuItem>
                                            </Menu>

                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>

                {/* Add Team Member Modal */}
                <Dialog open={addDialogOpen} onClose={() => setAddDialogOpen(false)}>
                    <DialogTitle>Add Team Member</DialogTitle>
                    <DialogContent>
                        <TeamMemberForm onSubmit={handleSubmit} />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setAddDialogOpen(false)} color="secondary">
                            Cancel
                        </Button>
                    </DialogActions>
                </Dialog>

                {/* Edit Team Member Modal */}
                <Dialog open={editDialogOpen} onClose={() => setEditDialogOpen(false)}>
                    <DialogTitle>Edit Team Member</DialogTitle>
                    <DialogContent>
                        <TeamMemberForm onSubmit={async (values: any) => {
                            setTeamMembers(teamMembers.map((member) => member.id === memberToEdit.id ? { ...member, ...values } : member));
                            const { name, email, password, role, status, id } = values
                            await updateTeamMembers(name, email, password, role, status, memberToEdit.id)
                            setEditDialogOpen(false);
                            setSnackbar({ open: true, message: 'Team member updated successfully!', severity: 'success' });
                        }} memberToEdit={memberToEdit} />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setEditDialogOpen(false)} color="secondary">
                            Cancel
                        </Button>
                    </DialogActions>
                </Dialog>

                {/* Delete Confirmation Dialog */}
                <Dialog open={deleteDialogOpen} onClose={() => setDeleteDialogOpen(false)}>
                    <DialogTitle>Confirm Deletion</DialogTitle>
                    <DialogContent>Are you sure you want to delete this team member?</DialogContent>
                    <DialogActions>
                        <Button onClick={() => setDeleteDialogOpen(false)} color="secondary">Cancel</Button>
                        <Button onClick={handleDelete} color="error">Delete</Button>
                    </DialogActions>
                </Dialog>
            </Box>
        </Layout>
    );
};

export default TeamMemberPage;
