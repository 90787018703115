import React, { useState } from 'react';
import { Box } from '@mui/material';
import SubscriptionPlans from '../components/SubscriptionPlans';
import ChangePlanModal from '../components/ChangePlanModal';
import Layout from '../components/Layout';

const Subscription = () => {
    const [selectedPlan, setSelectedPlan] = useState('Premium');
    const [openModal, setOpenModal] = useState(false);
    const [planToChange, setPlanToChange] = useState('');
 
    const plans = [
        {
            title: "Starter",
            price: "$29/month",
            description: "Perfect for small teams starting their hiring journey.",
            features: [
                "50 Interviews",
                "Basic AI Reports",
                "Email Support",
                "Automated Candidate Screening",
                "Customizable Interview Templates",
            ],
            buttonText: "Current Plan",
        },
        {
            title: "Professional",
            price: "$99/month",
            description: "Ideal for growing businesses with advanced hiring needs.",
            features: [
                "500 Interviews",
                "Advanced AI Insights",
                "Priority Support",
                "Auto-Candidate Assessment",
                "Auto-Interview Invitations",
                "Advanced Candidate Matching",
            ],
            buttonText: "Upgrade to Pro",
            highlighted: true,
        },
        {
            title: "Enterprise",
            price: "Custom Pricing",
            description: "Designed for large organizations with custom requirements.",
            features: [
                "Unlimited Interviews",
                "Dedicated Support",
                "Custom Integrations",
                "Dedicated AI Recruiting Assistant",
                "API Access for Seamless Integration",
                "Advanced Security and Compliance Features",
            ],
            buttonText: "Contact Sales",
        },
    ];

    const handleChangePlan = (planName: string) => {
        setPlanToChange(planName);
        setOpenModal(true);
    };

    const handleConfirmChange = () => {
        setSelectedPlan(planToChange);
        setOpenModal(false);
    };

    const handleCancelChange = () => {
        setOpenModal(false);
    };

    return (
        <Layout>
            <Box p={4}>
                <SubscriptionPlans plans={plans} onChangePlan={handleChangePlan} />
                <ChangePlanModal open={openModal} planToChange={planToChange} onConfirm={handleConfirmChange} onCancel={handleCancelChange} />
            </Box>
        </Layout>
    );
};

export default Subscription;
