import React, { useEffect, useState } from 'react'
import { Search, Close } from '@mui/icons-material'
import { InputBase, IconButton, Modal, Box, Typography, List, ListItem, ListItemText, Divider } from '@mui/material'
import { fetchSearchResults, SearchResults } from '../api/api'
import { useNavigate } from 'react-router-dom'

// Dummy Data for Search Results
const dummyJobs = [
    { id: 1, title: "Software Engineer", company: "Tech Corp" },
    { id: 2, title: "Product Manager", company: "Innovate Ltd" },
    { id: 3, title: "UX Designer", company: "Creative Inc" },
]

const dummyCandidates = [
    { id: 1, name: "John Doe", role: "Software Engineer" },
    { id: 2, name: "Jane Smith", role: "Product Manager" },
]

const dummyUsers = [
    { id: 1, email: "johndoe@example.com" },
    { id: 2, email: "janesmith@example.com" },
]

const dummyEmailTemplates = [
    { id: 1, title: "Welcome Email" },
    { id: 2, title: "Password Reset" },
]

const useDebouncedSearch = (query: string, delay: number): string => {
    const [debouncedQuery, setDebouncedQuery] = useState(query);

    useEffect(() => {
        const handler = setTimeout(() => {
            setDebouncedQuery(query);
        }, delay);

        return () => {
            clearTimeout(handler);
        };
    }, [query, delay]);

    return debouncedQuery;
};

type Props = {}

function SearchCard({ }: Props) {
    const [searchQuery, setSearchQuery] = useState<string>('')
    const [open, setOpen] = useState(false)

    const filteredJobs = searchQuery ? dummyJobs.filter(job => job.title.toLowerCase().includes(searchQuery.toLowerCase())) : []
    const filteredCandidates = searchQuery ? dummyCandidates.filter(candidate => candidate.name.toLowerCase().includes(searchQuery.toLowerCase())) : []
    const filteredUsers = searchQuery ? dummyUsers.filter(user => user.email.toLowerCase().includes(searchQuery.toLowerCase())) : []
    const filteredEmailTemplates = searchQuery ? dummyEmailTemplates.filter(template => template.title.toLowerCase().includes(searchQuery.toLowerCase())) : []
    const debouncedQuery = useDebouncedSearch(searchQuery, 300); // 300ms debounce delay
    const [results, setResults] = useState<SearchResults | undefined>();

    useEffect(() => {
        const fetchResults = async () => {
            if (debouncedQuery) {
                try {
                    const data = await fetchSearchResults(debouncedQuery);
                    console.log(data)
                    setResults(data);
                } catch (error) {
                    console.error('Error fetching search results:', error);
                }
            } else {
                setResults(undefined);
            }
        };

        fetchResults();
    }, [debouncedQuery]);

    // Function to open modal
    const handleOpen = () => setOpen(true)

    // Function to close modal
    const handleClose = () => { setOpen(false); setSearchQuery("") }

    // Handle Esc key press to close the modal
    console.log("search", results)
    const navigate = useNavigate();

    const handleItemClick = (link: string) => {
        setOpen(false)
        setSearchQuery("")
        navigate(link); // Navigate to the provided link
    };
    return (
        <>
            {/* Search Input Triggering Modal */}


            {/* Search Bar */}
            <InputBase
                sx={{
                    ml: 2,
                    flex: 1,
                    backgroundColor: "white", // Set the background color to white
                    color: "black", // Set the text color to black
                    borderRadius: 1, // Optional: to add rounded corners
                    padding: "4px 8px", // Optional: to give some padding
                }}
                placeholder="Search Jobs, Candidates..."
                inputProps={{ 'aria-label': 'Search Jobs, Candidates...' }}
                onChange={(e) => { setSearchQuery(e.target.value); handleOpen() }}

                value=""
                onClick={handleOpen} // Open the modal on input click
            />

            {/* Search Icon Button */}
            <IconButton type="button" sx={{ p: '10px', color: "black" }} aria-label="search">
                <Search sx={{ color: "white" }} />
            </IconButton>

            {/* Modal with Search Bar */}
            <Modal
                open={open}
                onClose={handleClose}
                sx={{

                    display: 'flex',
                    flexDirection: "column",
                    alignItems: 'center',
                    justifyContent: 'center'

                }}
            >
                <Box sx={{
                    backgroundColor: 'white',
                    width: '400px',
                    padding: 2,
                    borderRadius: 2,
                    boxShadow: 3,
                    display: 'flex',
                    flexDirection: 'column',

                }}>
                    <Box sx={{ display: 'flex', width: '100%', alignItems: 'center' }}>
                        <IconButton
                            sx={{ p: '10px', color: "black" }}
                            aria-label="search"
                        >
                            <Search sx={{ color: "black" }} />
                        </IconButton>
                        <InputBase
                            autoFocus
                            sx={{
                                flex: 1,
                                backgroundColor: "white",
                                color: "black",
                                padding: "4px 8px",
                                borderRadius: 1,
                            }}
                            placeholder="Search..."
                            inputProps={{ 'aria-label': 'Search' }}
                            value={searchQuery}
                            onChange={(e) => setSearchQuery(e.target.value)}
                        />
                        <IconButton
                            type="button"
                            sx={{ p: '10px', color: "black" }}
                            aria-label="close"
                            onClick={handleClose}
                        >
                            <Close sx={{ color: "black" }} />
                        </IconButton>

                    </Box>
                    <Divider />
                    <Box
                        sx={{
                            position: 'relative',
                            height: '50vh', // Fixed modal height
                            overflowY: 'auto', // Enable scrolling if content overflows
                            padding: 2,
                            borderRadius: 1,
                            backgroundColor: 'white',
                        }}
                    >
                        {/* Search Results Section */}
                        <Box sx={{ width: '100%', marginTop: 2 }}>
                            {/* Jobs Section */}
                            {results?.jobs && results.jobs.length > 0 && (
                                <Box sx={{ marginBottom: 2 }}>
                                    <Typography
                                        variant="h6"
                                        sx={{
                                            fontWeight: 'bold',
                                            color: '#6a1b9a', // Use a theme color
                                            borderBottom: '2px solid #f3e5f5',
                                            paddingBottom: 1,
                                            marginBottom: 1,
                                        }}
                                    >
                                        Jobs
                                    </Typography>
                                    <List sx={{ padding: 0 }}>
                                        {results.jobs.map((job) => (
                                            <ListItem
                                                onClick={() => { handleItemClick(job.link) }}

                                                key={job.id}
                                                sx={{
                                                    padding: 1,
                                                    borderRadius: 1,
                                                    '&:hover': {
                                                        backgroundColor: '#f3e5f5',
                                                    },
                                                }}
                                            >
                                                <ListItemText

                                                    primary={job.name}
                                                    secondary={job.posted_at}
                                                    primaryTypographyProps={{
                                                        fontWeight: 'medium',
                                                        fontSize: '1rem',
                                                    }}
                                                    secondaryTypographyProps={{
                                                        color: 'text.secondary',
                                                    }}
                                                />
                                            </ListItem>
                                        ))}
                                    </List>
                                </Box>
                            )}

                            {/* Candidates Section */}
                            {results?.applicants && results?.applicants.length > 0 && (
                                <Box sx={{ marginBottom: 2 }}>
                                    <Typography
                                        variant="h6"
                                        sx={{
                                            fontWeight: 'bold',
                                            color: '#1565c0', // Use a different theme color
                                            borderBottom: '2px solid #bbdefb',
                                            paddingBottom: 1,
                                            marginBottom: 1,
                                        }}
                                    >
                                        Candidates
                                    </Typography>
                                    <List sx={{ padding: 0 }}>
                                        {results?.applicants.map((candidate) => (
                                            <ListItem
                                                onClick={() => { handleItemClick(candidate.link) }}

                                                key={candidate.id}
                                                sx={{
                                                    padding: 1,
                                                    borderRadius: 1,
                                                    '&:hover': {
                                                        backgroundColor: '#e3f2fd',
                                                    },
                                                }}
                                            >
                                                <ListItemText
                                                    primary={candidate.name}
                                                    secondary={candidate.details}
                                                    primaryTypographyProps={{
                                                        fontWeight: 'medium',
                                                        fontSize: '1rem',
                                                    }}
                                                    secondaryTypographyProps={{
                                                        color: 'text.secondary',
                                                    }}
                                                />
                                            </ListItem>
                                        ))}
                                    </List>
                                </Box>
                            )}

                            {/* Users Section */}
                            {results?.emailTemplates && results?.emailTemplates.length > 0 && (
                                <Box sx={{ marginBottom: 2 }}>
                                    <Typography
                                        variant="h6"
                                        sx={{
                                            fontWeight: 'bold',
                                            color: '#2e7d32',
                                            borderBottom: '2px solid #c8e6c9',
                                            paddingBottom: 1,
                                            marginBottom: 1,
                                        }}
                                    >
                                        Email Templates
                                    </Typography>
                                    <List sx={{ padding: 0 }}>
                                        {results?.emailTemplates.map((template) => (
                                            <ListItem
                                                onClick={() => { handleItemClick(template.link) }}
                                                key={template.id}
                                                sx={{
                                                    padding: 1,
                                                    borderRadius: 1,
                                                    '&:hover': {
                                                        backgroundColor: '#e8f5e9',
                                                    },
                                                }}
                                            >
                                                <ListItemText
                                                    primary={template.name}
                                                    secondary={template.details}
                                                    primaryTypographyProps={{
                                                        fontWeight: 'medium',
                                                        fontSize: '1rem',
                                                    }}
                                                    secondaryTypographyProps={{
                                                        color: 'text.secondary',
                                                    }}
                                                />
                                            </ListItem>
                                        ))}
                                    </List>
                                </Box>
                            )}

                            {/* Email Templates Section */}
                            {/* {filteredEmailTemplates.length > 0 && (
                                <Box sx={{ marginBottom: 2 }}>
                                    <Typography
                                        variant="h6"
                                        sx={{
                                            fontWeight: 'bold',
                                            color: '#ef6c00',
                                            borderBottom: '2px solid #ffe0b2',
                                            paddingBottom: 1,
                                            marginBottom: 1,
                                        }}
                                    >
                                        Email Templates
                                    </Typography>
                                    <List sx={{ padding: 0 }}>
                                        {filteredEmailTemplates.map((template) => (
                                            <ListItem
                                                key={template.id}
                                                sx={{
                                                    padding: 1,
                                                    borderRadius: 1,
                                                    '&:hover': {
                                                        backgroundColor: '#fff3e0',
                                                    },
                                                }}
                                            >
                                                <ListItemText
                                                    primary={template.title}
                                                    primaryTypographyProps={{
                                                        fontWeight: 'medium',
                                                        fontSize: '1rem',
                                                    }}
                                                    secondaryTypographyProps={{
                                                        color: 'text.secondary',
                                                    }}
                                                />
                                            </ListItem>
                                        ))}
                                    </List>
                                </Box>
                            )} */}

                            {/* If no results are found */}
                            {results?.applicants.length === 0 &&
                                results?.emailTemplates.length === 0 &&
                                results?.jobs.length === 0 &&
                          (
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            height: '100%',
                                        }}
                                    >
                                        <Typography
                                            variant="body1"
                                            sx={{
                                                color: 'text.secondary',
                                                textAlign: 'center',
                                                marginBottom: 2,
                                            }}
                                        >
                                            No results found.
                                        </Typography>
                                    </Box>
                                )}
                        </Box>
                    </Box>

                </Box>
            </Modal>
        </>
    )
}

export default SearchCard
