import React, { FC, useState } from 'react';
import {
  Box,
  TextField,
  Typography,
  Grid,
  Divider,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from '@mui/material';
import ReactQuill from 'react-quill';
import NumberInputIntroduction from './NumberInput';

interface JobDetailsFormProps {
  jobTitle: string;
  setJobTitle: (value: string) => void;
  description: string;
  setDescription: (value: string) => void;
  location: string;
  setLocation: (value: string) => void;
  salary: string;
  setSalary: (value: string) => void;
  language: string;
  setLanguage: (value: string) => void;
  steps: number,
  setSteps: React.Dispatch<React.SetStateAction<number>>
}

const JobDetailsForm: FC<JobDetailsFormProps> = ({
  jobTitle,
  setJobTitle,
  description,
  setDescription,
  location,
  setLocation,
  salary,
  setSalary,
  language,
  steps,
  setSteps,
  setLanguage,
}) => {
  const languages = ['English', 'Spanish', 'French', 'German', 'Chinese'];
  // Validation States
  const [errors, setErrors] = useState({
    jobTitle: false,
    description: false,
    location: false,
    salary: false,
  });

  const validateField = (field: keyof typeof errors, value: string) => {
    setErrors((prev) => ({
      ...prev,
      [field]: !value.trim(),
    }));
  };

  const isSalaryValid = (value: string) => !isNaN(Number(value));

  return (
    <Box
      sx={{
        py: 2, px: 4,

      }}
    >
      <Typography variant="subtitle1" sx={{ mb: 1, fontWeight: 'bold', color: 'primary.main' }}>
        Job Details
      </Typography>

      {/* Basic Information */}
      <Typography variant="subtitle2" sx={{ mb: 1, color: 'text.secondary' }}>
        Basic Information
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <TextField
            size="small"
            fullWidth
            label="Job Title"
            variant="outlined"
            value={jobTitle}
            onChange={(e) => {
              setJobTitle(e.target.value);
              validateField('jobTitle', e.target.value);
            }}
            error={errors.jobTitle}
            helperText={errors.jobTitle && 'Job title is required'}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FormControl fullWidth>
            <InputLabel>Preferred Language</InputLabel>
            <Select
              size="small"
              value={language}
              onChange={(e) => setLanguage(e.target.value as string)}
              label="Preferred Language"
            >
              {languages.map((lang, index) => (
                <MenuItem key={index} value={lang}>
                  {lang}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>

      {/* Job Description */}
      <Divider sx={{ my: 2 }} />

      <Grid container spacing={3}>

        <Grid item xs={6}>
          <Typography variant="subtitle2" sx={{ mb: 1, color: 'text.secondary' }}>
            Job Description
          </Typography>
          <ReactQuill
            value={description}
            onChange={(e) => {
              setDescription(e);
              validateField('description', e);
            }}

            placeholder="Job Description"
            style={{ height: "300px", marginBottom: "16px" }}
          />
          {/* <TextField
            fullWidth
            label="Description"
            variant="outlined"
            value={description}

            error={errors.description}
            helperText={errors.description ? 'Description is required' : `${description.length}/500`}
            multiline
            rows={4}
          /> */}
        </Grid>

        <Grid item xs={12} md={6}>
          <Typography variant="subtitle2" sx={{ mb: 2, color: 'text.secondary' }}>
            Location
          </Typography>

          <TextField
            size="small"
            fullWidth
            label="Location"
            variant="outlined"
            value={location}
            onChange={(e) => {
              setLocation(e.target.value);
              validateField('location', e.target.value);
            }}
            error={errors.location}
            helperText={errors.location && 'Location is required'}
          />

          <Typography variant="subtitle2" sx={{ my: 2, color: 'text.secondary' }}>
            Salary
          </Typography>
          <TextField
            size="small"
            fullWidth
            label="Salary (e.g., $50,000)"
            variant="outlined"
            value={salary}
            onChange={(e) => {
              setSalary(e.target.value);
              validateField('salary', e.target.value);
            }}
            error={!isSalaryValid(salary)}
            helperText={!isSalaryValid(salary) && 'Salary must be a number'}
          />
          <Box>
            <Typography variant="subtitle2" sx={{ mt: 2, color: 'text.secondary' }}>
              Number of Interview Rounds
            </Typography>
            <Typography variant="caption" sx={{ color: 'text.secondary' }}>
              Specify the total number of interview rounds for the process.
            </Typography>
            <NumberInputIntroduction setSteps={setSteps} steps={steps} />
          </Box>

        </Grid>
      </Grid>

      {/* Location & Salary */}


    </Box>
  );
};

export default JobDetailsForm;
