import React from 'react';
import { Card, CardContent, Typography, Box, Button, Divider, Grid } from '@mui/material';
import { Download } from '@mui/icons-material';
import { Applicant } from '../api/api';

interface ApplicantCardProps {
  applicant: Applicant,
  jobTitle: string
}

const ApplicantCard: React.FC<ApplicantCardProps> = ({ applicant, jobTitle }) => {
  return (
    <Box
      sx={{
        borderRight: '1px solid #e0e0e0',
        borderRadius: 2,
         
        backgroundColor: '#fff',

      }}
    >
      <CardContent sx={{ padding: 2 }}>
        {/* Applicant Information */}
        <Typography variant="h4" sx={{ fontWeight: 'bold', mb: 0, color: 'text.primary' }}>
          {applicant.name}
        </Typography>
        <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 1, color: 'text.secondary' }}>
          {jobTitle}
        </Typography>
        <Divider sx={{ my: 1 }} />

        <Box sx={{ mb: 1 }}>
          <Grid container gap={0}  >
            <Grid xs={12} md={6}>
              <Typography variant="body2" sx={{ mb: 1 }}>
                <strong>Email</strong><br/> {applicant.email}
              </Typography>
              <Typography variant="body2" sx={{ mb: 1 }}>
                <strong>Phone</strong><br/> {applicant.phone}
              </Typography>
              <Typography variant="body2" sx={{ mb: 1 }}>
                <strong>Location</strong><br/> {applicant.location || 'Not specified'}
              </Typography>

            </Grid>
            <Grid xs={12} md={6}>
              <Typography variant="body2" sx={{ mb: 1 }}>
                <strong>Salary</strong><br/> {applicant.salary || 'Not specified'}
              </Typography>
              <Typography sx={{ mb: 1 }} variant="body2">
                <strong>Applied At</strong><br/> {new Date(applicant.applied_at).toLocaleDateString() || 'N/A'}
              </Typography>
              <Typography variant="body2" sx={{ mb: 1 }}>
                <strong>Status</strong><br/> {applicant.status || 'Not specified'}
              </Typography>
            </Grid>
            {applicant.note &&
              <Typography variant="body2" sx={{ mb: 1 }}>
                <strong>Note:</strong> {applicant.note || 'Not specified'}
              </Typography>
            }
          </Grid>


        </Box>

        <Divider sx={{ my: 1 }} />

        {/* Resume Section */}
        <Typography variant="subtitle1" sx={{ fontWeight: 'bold', mb: 1, color: 'text.primary' }}>
          Resume
        </Typography>
        <Box sx={{ display: 'flex', justifyContent: 'start', alignItems: 'center', mb: 1 }}>
          <Button
            variant="contained"
            startIcon={<Download />}
            href={applicant.resume}
            target="_blank"
            sx={{
              mb:1,
              textTransform: 'capitalize',
              fontWeight: 'bold',
              color: '#fff',
              backgroundColor: '#1976d2',
              '&:hover': {
                backgroundColor: '#115293',
              },
            }}
          >
            Download Resume
          </Button>
        </Box>

        <Divider sx={{ my: 1 }} />

        {/* Cover Letter Section */}
        <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 1, color: 'text.primary' }}>
          Cover Letter
        </Typography>
        <Typography
          variant="body1"
          sx={{
            backgroundColor: '#f9f9f9',
            p: 1,
            borderRadius: 1,
            border: '1px solid #e0e0e0',
            color: 'text.secondary',
            whiteSpace: 'pre-wrap', // Preserve formatting
          }}
        >
          {applicant.cover_letter || 'No cover letter provided.'}
        </Typography>
      </CardContent>
    </Box >
  );
};

export default ApplicantCard;
