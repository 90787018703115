import React from "react";
import { Grid } from "@mui/material";
import SummaryCard from "./SummaryCard";

const HeroSection: React.FC = () => {
  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={3}>
        <SummaryCard 
          increaseRate={50} 
          title="Open Jobs" 
          value="50" 
          iconColor="#5e30a0" 
        />
      </Grid>
      <Grid item xs={12} sm={3}>
        <SummaryCard 
          increaseRate={20} 
          title="New Applications" 
          value="120" 
          iconColor="#ff9800" 
        />
      </Grid>
      <Grid item xs={12} sm={3}>
        <SummaryCard 
          increaseRate={10} 
          title="Pending Actions" 
          value="15" 
          iconColor="#f44336" 
        />
      </Grid>
      <Grid item xs={12} sm={3}>
        <SummaryCard 
          increaseRate={30} 
          title="Active Interviews" 
          value="8" 
          iconColor="#4caf50" 
        />
      </Grid>
    </Grid>
  );
};

export default HeroSection;
