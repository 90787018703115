import React from 'react';
import { Box, Typography, Grid, Card, CardContent, LinearProgress, Stack, Chip } from '@mui/material';

const dummyMetrics = [
    {
      jobId: '1',
      jobTitle: 'Software Engineer',
      totalApplications: 120,
      openPositions: 5,  // Positions available for this role
      filledPositions: 3,  // Positions already filled for this role
      hiredApplications: 30,
      avgTimeToHire: 35, // in days
      applicationsByStatus: {
        'Pending': 40,
        'In Progress': 60,
        'Hired': 20,
      },
      applicationsReceived: 120,
      timeToHireGraph: [25, 30, 35, 40, 45], // For graphical representation of time
    },
    // More jobs can be added in the same structure
  ];
  const JobAnalytics: React.FC = () => {
    return (
        <Box sx={{ mt: 4 }}>
            <Typography variant="h5" sx={{ fontWeight: 'bold', mb: 4 }}>
                Job Analytics and Metrics
            </Typography>

            <Grid container spacing={4}>
                {dummyMetrics.map((metric) => (
                    <Grid item xs={12} sm={6} md={4} key={metric.jobId}>
                        <Card elevation={3} sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                            <CardContent>
                                {/* Job Title */}
                                <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 2 }}>
                                    {metric.jobTitle}
                                </Typography>

                                {/* Total Applications */}
                                <Box sx={{ mb: 3 }}>
                                    <Typography variant="body1" sx={{ fontWeight: 'bold', mb: 1 }}>
                                        Total Applications
                                    </Typography>
                                    <Typography variant="h5" sx={{ fontWeight: 'bold' }}>
                                        {metric.totalApplications}
                                    </Typography>
                                </Box>

                                {/* Open Positions */}
                                <Box sx={{ mb: 3 }}>
                                    <Typography variant="body1" sx={{ fontWeight: 'bold', mb: 1 }}>
                                        Open Positions
                                    </Typography>
                                    <Typography variant="h5" sx={{ fontWeight: 'bold' }}>
                                        {metric.openPositions}
                                    </Typography>
                                </Box>

                                {/* Hired Applications */}
                                <Box sx={{ mb: 3 }}>
                                    <Typography variant="body1" sx={{ fontWeight: 'bold', mb: 1 }}>
                                        Hired Applications
                                    </Typography>
                                    <Stack direction="row" alignItems="center" spacing={1}>
                                        <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                                            {metric.hiredApplications}
                                        </Typography>
                                        <Typography variant="body1" color="textSecondary">
                                            / {metric.totalApplications}
                                        </Typography>
                                    </Stack>
                                </Box>

                                {/* Average Time to Hire */}
                                <Box sx={{ mb: 3 }}>
                                    <Typography variant="body1" sx={{ fontWeight: 'bold', mb: 1 }}>
                                        Average Time to Hire
                                    </Typography>
                                    <Typography variant="h5" sx={{ fontWeight: 'bold' }}>
                                        {metric.avgTimeToHire} days
                                    </Typography>
                                </Box>

                                {/* Applications by Status */}
                                <Box sx={{ mb: 3 }}>
                                    <Typography variant="body1" sx={{ fontWeight: 'bold', mb: 1 }}>
                                        Applications by Status
                                    </Typography>
                                    {Object.entries(metric.applicationsByStatus).map(([status, count]) => (
                                        <Chip
                                            label={`${status}: ${count}`}
                                            key={status}
                                            sx={{
                                                margin: '2px',
                                                backgroundColor: getStatusColor(status),
                                                color: 'white',
                                            }}
                                        />
                                    ))}
                                </Box>
                            </CardContent>
                        </Card>
                    </Grid>
                ))}
            </Grid>
        </Box>
    );
};

// Helper function to determine the status color
const getStatusColor = (status: string) => {
    switch (status) {
        case 'Applied':
            return '#3f51b5'; // Blue
        case 'Interviewed':
            return '#ff9800'; // Orange
        case 'Hired':
            return '#4caf50'; // Green
        case 'Rejected':
            return '#f44336'; // Red
        default:
            return '#9e9e9e'; // Gray for unknown status
    }
};

export default JobAnalytics;
