import React from 'react';
import { Card, CardContent, Typography, Box, Divider } from '@mui/material';
import JobListItem from './JobListItem';
import { Job } from '../api/api';

const JobListingsSection: React.FC<{ jobs: Job[] }> = ({ jobs }) => {
    return (
        <Box sx={{ mb: 4 }}>
            {/* Section Header */}
            <Box sx={{ mb: 3 }}>
                <Typography variant="h4" component="h2" gutterBottom sx={{ fontWeight: 'bold' }}>
                    Job Listings
                </Typography>
               
            </Box>
            
            {/* Job Listings */}
            <Card variant="outlined" sx={{ borderRadius: 2, boxShadow: 2 }}>
                <CardContent>
                    {jobs.length > 0 ? (
                        jobs.map((job) => <JobListItem key={job.id} job={job} />)
                    ) : (
                        <Typography align="center" sx={{ color: 'text.secondary' }}>
                            No jobs available for this company.
                        </Typography>
                    )}
                </CardContent>
            </Card>
        </Box>
    );
};

export default JobListingsSection;
