import React, { ReactNode } from "react";
import {
  Paper,
  Typography,
  Box,
  Button,
  List,
  ListItem,
  ListItemText,
  Divider,
  Chip,
  Tooltip,
  Avatar,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { RecentJobs } from "../api/api";

// Helper function to get status color for chips
const getStatusColor = (status: string | number) => {
  switch (status) {
    case "Invited":
    case "Interviewing":
    case "Completed":
      return "success";
    case "Awaiting Interview":
    case "Draft":
      return "warning";
    case "Hired":
      return "primary";
    case "Open":
      return "info";
    case "Closed":
      return "error";
    default:
      return "default";
  }
};

interface DashboardCardProps {
  title: string;
  icon: ReactNode;
  metric: string;
  actionLabel?: string;
  onActionClick?: () => void;
  recentItems?: RecentJobs[]; // Added link
}

const DashboardCard: React.FC<DashboardCardProps> = ({
  title,
  icon,
  metric,
  actionLabel,
  onActionClick,
  recentItems,
}) => {
  const navigate = useNavigate();

  const handleItemClick = (link: string) => {
    navigate(link); // Navigate to the provided link
  };

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          mb:2,
        }}
      >
        <Box sx={{ marginRight: 1, color: "#3f51b5" }}>{icon}</Box>
        <Typography variant="h6" sx={{ fontWeight: "bold", flexGrow: 1 }}>
          {title}
        </Typography>
      </Box>
      <Paper
        sx={{
          borderRadius: 2,
          boxShadow: 3,
          px: 0.5,
          py: 0,
          display: "flex",
          flexDirection: "column",
          height: "100%",
          backgroundColor: "#fff",
        }}
      >
        {/* Recent Items Section */}
        {recentItems && (
          <List sx={{ maxHeight: 250, overflowY: "auto" }}>
            {recentItems.map((item, index) => (
              <React.Fragment key={index}>
                <ListItem
                  sx={{
                    paddingY: 0,
                    "&:hover": {
                      backgroundColor: "#f9f9f9",
                      boxShadow: "0px 2px 8px rgba(0,0,0,0.1)", // Subtle shadow on hover
                      borderRadius: "8px",
                      cursor: "pointer",
                    },
                  }}
                  onClick={() => handleItemClick(item.link)}
                >
                  {/* Item Icon */}
                  <Avatar
                    sx={{
                      bgcolor: "#3f51b5",
                      color: "white",
                      marginRight: 2,
                    }}
                  >
                    {item.name.charAt(0).toUpperCase()}
                  </Avatar>

                  {/* Item Text */}
                  <ListItemText
                    primary={
                      <Typography variant="body1" fontWeight="bold" color="text.primary">
                        {item.name}
                      </Typography>
                    }
                    secondary={
                      <Typography variant="body2" color="text.secondary">
                        {item.details}
                      </Typography>
                    }
                  />

                  {/* Tooltip and Status Chip */}
                  <Tooltip title={`Status: ${item.status}`}>
                    <Chip
                      label={item.status}
                      color={getStatusColor(item.status)} // Dynamically set color
                      size="small"
                      sx={{ marginLeft: 2 }}
                    />
                  </Tooltip>
                </ListItem>
                {index !== recentItems.length - 1 && <Divider sx={{ marginY: 0, mx: 4 }} />}
              </React.Fragment>
            ))}
          </List>
        )}

        {/* Action Button (optional) */}
        {actionLabel && onActionClick && (
          <Button
            variant="text"
            color="primary"
            onClick={onActionClick}
            sx={{

              textTransform: "none",
              "&:hover": {
                backgroundColor: "white",
                fontWeight: "bold", // Larger shadow on hover
              },
            }}
          >
            {actionLabel}
          </Button>
        )}
      </Paper>
    </Box>
  );
};

export default DashboardCard;
