import React, { useState, useEffect } from "react";
import { Alert, Box, CircularProgress, Container, Grid, Snackbar } from "@mui/material";
import Layout from "../components/Layout";
import Header from "../components/Header";
import HeroSection from "../components/HeroSection";
import DashboardCard from "../components/DashboardCard";
import Footer from "../components/Footer";
import "./Dashboard.css";
import { AccountCircle, People, RecordVoiceOver, Report, Work } from "@mui/icons-material";
import { fetchPendingActions, fetchRecentApplicant, fetchRecentInterviews, fetchRecentJobs, PendingAction, RecentJobs } from "../api/api";
import JobListMinimal from "../components/JobListMinimal";
import ShortlistedCard from "../components/ShortlistedCard";
import Loading from "../components/Loading";
import PendingActionsCard from "../components/PendingActionsCard";

const ATSDashboard: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);
  const [recentJobs, setrecentJobs] = useState<RecentJobs[]>([])
  const [recentCandidates, setrecentCandidates] = useState<any[]>()
  const [recentInterviews, setrecentInterviews] = useState<any[]>()
  const [pendingActions, setpendingActions] = useState<PendingAction[]>([])
  useEffect(() => {
    setLoading(true);
    const getRecentJobsAndApplicants = async () => {
      const jobs = await fetchRecentJobs()
      const applicant = await fetchRecentApplicant()
      const interviews = await fetchRecentInterviews()
      const applicantPendingActions = await fetchPendingActions({ status: "Pending" });
      setpendingActions(applicantPendingActions)
      setrecentInterviews(interviews)
      setrecentJobs(jobs)
      setrecentCandidates(applicant)
      setLoading(false)
    }
    getRecentJobsAndApplicants()
  }, []);

  const handleSnackbarClose = (): void => {
    setOpenSnackbar(false);
  };

  const handleNavigation = (path: string) => {
    console.log(`Navigate to ${path}`);
  };



  if (loading) return <Loading />;
  return (
    <Layout>
      <Container maxWidth="lg" className="dashboard-container">

        <HeroSection />
        <Grid container spacing={3} className="content-area">
        <Grid sx={{ mt: 2 }} item xs={12} sm={8}>
            <PendingActionsCard />
            {/* <ShortlistedCard /> */}
          </Grid>
          <Grid sx={{ mt: 2 }} item xs={12} md={4}>
            <DashboardCard
              title="Recent Interviews"
              icon={<RecordVoiceOver />}
              metric="15"
              recentItems={recentInterviews}
              actionLabel="View All Interviews"
              onActionClick={() => console.log("Navigate to all candidates")}
            />
          </Grid>
          <Grid sx={{ mt: 0, }} item xs={12} sm={8}>
            <JobListMinimal />
          </Grid>
          <Grid sx={{ mt: 0 }} item xs={12} md={4}>
            <DashboardCard
              title="Recent Candidates"
              icon={<AccountCircle />}
              metric="15"
              recentItems={recentCandidates}
              actionLabel="View All Candidates"
              onActionClick={() => console.log("Navigate to all candidates")}
            />
          </Grid>
         
        </Grid>
       


        <Snackbar
          open={openSnackbar}
          message="Data loaded successfully!"
          onClose={handleSnackbarClose}
          autoHideDuration={3000}
        />
      </Container>
    </Layout>
  );
};

export default ATSDashboard;
