import React, { useState } from 'react';
import { Box, Divider } from '@mui/material';
import BillingOverview from '../components/BillingOverview';
import InvoiceHistory from '../components/InvoiceHistory';
import PaymentMethod from '../components/ManagePaymentMethods';
import Layout from '../components/Layout';

const CompanyBillingPage = () => {
    const [selectedPlan, setSelectedPlan] = useState('Premium');
  
    const invoices = [
        { id: 1, date: '2025-01-01', amount: '$100', status: 'Paid' },
        { id: 2, date: '2025-02-01', amount: '$120', status: 'Unpaid' },
    ];
 
    return (
        <Layout>
            <Box p={4}>
                <BillingOverview companyName="ABC Corp" currentPlan={selectedPlan} nextBillingDate="2025-03-01" />
                <Divider sx={{ my: 4 }} />
                <InvoiceHistory invoices={invoices} />
                <Divider sx={{ my: 4 }} />
             </Box>
        </Layout>
    );
};

export default CompanyBillingPage;
