import React, { useState, useEffect } from 'react';
import {
    Card,
    CardContent,
    CircularProgress,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
    Button,
    Menu,
    MenuItem,
    ListItemIcon,
    ListItemText,
    Tooltip,
    Box,
} from '@mui/material';
import { KeyboardArrowDown, Publish, Save, HighlightOff, CheckCircle, Clear, Visibility } from '@mui/icons-material';
import theme from '../theme';
import { fetchPendingActions, PendingAction } from '../api/api';
import { formatExpiresAt } from '../utils/timeUtils';
import { useNavigate } from 'react-router-dom';


const PendingActionsCard = () => {
    const [pendingActions, setPendingActions] = useState<PendingAction[]>([]);
    const [loading, setLoading] = useState(false);
    const [menuAnchor, setMenuAnchor] = useState<null | HTMLElement>(null);
    const navigate = useNavigate();

    useEffect(() => {
        const loadPendingActions = async () => {
            setLoading(true);
            try {
                const actions = await fetchPendingActions();
                setPendingActions(actions);
            } catch (error) {
                console.error(error);
            } finally {
                setLoading(false);
            }
        };

        loadPendingActions();
    }, []);

    const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
        setMenuAnchor(event.currentTarget);
    };

    const handleMenuClose = () => {
        setMenuAnchor(null);
    };

    const handleRowClick = (jobId:string,id: string) => {
        navigate("applicants/"+jobId +"/" + id); // Navigate to the provided link
        console.log(`Navigate to action ID: ${id}`); // Replace with your navigation logic
    };



    return (
        <Box>
            <Typography variant="h6" sx={{ mb: 2, fontWeight: 'bold' }}>
                Pending Actions
            </Typography>
            <Card sx={{ borderRadius: 2, boxShadow: 3 }}>
                <CardContent sx={{ p: 0 }}>

                    {loading ? (
                        <CircularProgress sx={{ display: 'block', margin: 'auto' }} />
                    ) : pendingActions.length === 0 ? (
                        <Typography variant="body1" color="text.secondary" sx={{ textAlign: 'center' }}>
                            No pending actions.
                        </Typography>
                    ) : (
                        <TableContainer sx={{height:280 }}>
                            <Table stickyHeader>
                                <TableHead>
                                    <TableRow>
                                        <TableCell sx={{ fontWeight: 'bold', backgroundColor: theme.palette.grey[200] }}>Job</TableCell>
                                        <TableCell sx={{ fontWeight: 'bold', backgroundColor: theme.palette.grey[200] }}>Candidate</TableCell>
                                        <TableCell sx={{ fontWeight: 'bold', backgroundColor: theme.palette.grey[200] }}>Action</TableCell>
                                        <TableCell sx={{ fontWeight: 'bold', backgroundColor: theme.palette.grey[200] }}>Due Date</TableCell>
                                        <TableCell sx={{ fontWeight: 'bold', backgroundColor: theme.palette.grey[200] }}>Status</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {pendingActions.map((action) => (
                                        <TableRow
                                            key={action.id}
                                            sx={{
                                                '&:hover': {
                                                    cursor: 'pointer',
                                                    backgroundColor: theme.palette.action.hover,
                                                },
                                            }}
                                            onClick={() => handleRowClick(action.job.id, action.applicant.id)}
                                        >
                                            <TableCell>
                                                <Tooltip title={action.action} arrow>
                                                    <Typography
                                                        noWrap
                                                        sx={{
                                                            fontWeight: "500"
                                                        }}
                                                    >
                                                        {action.job.title}
                                                    </Typography>
                                                </Tooltip>
                                            </TableCell>
                                            <TableCell>{action.applicant.name}</TableCell>
                                            <TableCell>{action.action}</TableCell>
                                            <TableCell>{formatExpiresAt(action.expiresAt)}</TableCell>
                                            <TableCell>
                                                <Button
                                                    sx={{
                                                        borderRadius: 2.5,
                                                        textTransform: 'none',
                                                        fontWeight: 'bold',
                                                        fontSize: 12,
                                                        color: 'white',
                                                        backgroundColor: theme.palette.warning.main,
                                                        '&:hover': { backgroundColor: theme.palette.warning.dark },
                                                    }}
                                                    onClick={(e) => {
                                                        e.stopPropagation(); // Prevent row click
                                                        handleMenuClick(e);
                                                    }}
                                                    endIcon={<KeyboardArrowDown />}
                                                >
                                                    Pending
                                                </Button>
                                                <Menu
                                                    anchorEl={menuAnchor}
                                                    open={Boolean(menuAnchor)}
                                                    onClose={handleMenuClose}
                                                    sx={{
                                                        '& .MuiPaper-root': {
                                                            boxShadow: 'none',
                                                            border: '1px solid #ccc',
                                                            borderRadius: 2,
                                                            minWidth: '180px', // Adjust as needed
                                                        },
                                                    }}
                                                >
                                                    <MenuItem onClick={handleMenuClose} sx={{ display: 'flex', alignItems: 'center' }}>
                                                        <ListItemIcon>
                                                            <CheckCircle fontSize="small" />
                                                        </ListItemIcon>
                                                        <ListItemText primary="Approve" sx={{ marginLeft: 1 }} />
                                                    </MenuItem>
                                                    <MenuItem onClick={handleMenuClose} sx={{ display: 'flex', alignItems: 'center' }}>
                                                        <ListItemIcon>
                                                            <Clear fontSize="small" />
                                                        </ListItemIcon>
                                                        <ListItemText primary="Decline" sx={{ marginLeft: 1 }} />
                                                    </MenuItem>
                                                    <MenuItem onClick={handleMenuClose} sx={{ display: 'flex', alignItems: 'center' }}>
                                                        <ListItemIcon>
                                                            <Visibility fontSize="small" />
                                                        </ListItemIcon>
                                                        <ListItemText primary="View" sx={{ marginLeft: 1 }} />
                                                    </MenuItem>
                                                    <MenuItem onClick={handleMenuClose} sx={{ display: 'flex', alignItems: 'center' }}>
                                                        <ListItemIcon>
                                                            <HighlightOff fontSize="small" />
                                                        </ListItemIcon>
                                                        <ListItemText primary="Close" sx={{ marginLeft: 1 }} />
                                                    </MenuItem>
                                                </Menu>

                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    )}
                </CardContent>
            </Card>
        </Box>
    );
};

export default PendingActionsCard;
