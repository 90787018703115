import { Box, CircularProgress, } from '@mui/material'
import React from 'react'

type Props = {}

const Loading = (props: Props) => {
    return (
        <Box sx={{ height: "100vh", alignSelf: "center", display: "flex", flex: 1, justifyContent: "center", alignItems: "center" }} >
            <CircularProgress />
        </Box>
    )
}

export default Loading