import React from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, Button, Typography } from '@mui/material';

interface ChangePlanModalProps {
  open: boolean;
  planToChange: string;
  onConfirm: () => void;
  onCancel: () => void;
}

const ChangePlanModal: React.FC<ChangePlanModalProps> = ({ open, planToChange, onConfirm, onCancel }) => {
  return (
    <Dialog open={open} onClose={onCancel}>
      <DialogTitle>Confirm Plan Change</DialogTitle>
      <DialogContent>
        <Typography variant="body1" gutterBottom>
          You are about to change your subscription plan to <strong>{planToChange}</strong>.
        </Typography>
        <Typography variant="body2" color="text.secondary">
          Are you sure you want to proceed with this change? If you confirm, your subscription will be updated immediately.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel} color="secondary">
          Cancel
        </Button>
        <Button onClick={onConfirm} color="primary">
          Confirm Change
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ChangePlanModal;
