import React, { useState } from 'react';
import { Box, Typography, TextField, Button } from '@mui/material';
import { Job } from '../api/api';

interface JobDetailsProps {
  job: Job
  onSave: (updatedJob: Job) => void;
}

const EditableJobDetails: React.FC<JobDetailsProps> = ({ job, onSave }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [localJob, setLocalJob] = useState(job);

  const handleSave = () => {
    onSave(localJob);
    setIsEditing(false);
  };

  return (
    <Box sx={{ p: 2 }}>
      {/* Header with Edit Button */}
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
        <Typography variant="h5" gutterBottom>
          Job Details
        </Typography>
        {!isEditing && (
          <Button
            variant="outlined"
            color="primary"
            onClick={() => setIsEditing(true)}
            sx={{
              textTransform: 'none',
              paddingX: 2,
              fontSize: '0.875rem',
              borderRadius: '8px',
            }}
          >
            Edit
          </Button>
        )}
      </Box>

      {/* Job Details Form or Read-Only View */}
      {!isEditing ? (
        <Box>
          <Typography variant="subtitle1" sx={{ mb: 1, fontWeight: 500 }}>
            {localJob.job_title || 'No title provided'}
          </Typography>
          <Typography
            paragraph
            sx={{
              whiteSpace: 'pre-wrap',
              lineHeight: 1.6,
              color: 'text.secondary',
              mb: 2,
            }}
          >
            {localJob.description || 'No description available.'}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary', mb: 0.5 }}>
            <strong>Location:</strong> {localJob.location || 'Not specified'}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary', mb: 0.5 }}>
            <strong>Salary:</strong> {localJob.salary || 'Not specified'}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary', mb: 0.5 }}>
            <strong>Language:</strong> {localJob.language || 'Not specified'}
          </Typography>
        </Box>
      ) : (
        <Box>
          <TextField
            fullWidth
            variant="outlined"
            label="Job Title"
            value={localJob.job_title}
            onChange={(e) =>
              setLocalJob((prev) => ({ ...prev, job_title: e.target.value }))
            }
            sx={{ mb: 2, backgroundColor: 'background.default', borderRadius: '8px' }}
          />
          <TextField
            fullWidth
            variant="outlined"
            label="Edit Description"
            multiline
            minRows={4}
            value={localJob.description}
            onChange={(e) =>
              setLocalJob((prev) => ({ ...prev, description: e.target.value }))
            }
            sx={{ mb: 2, backgroundColor: 'background.default', borderRadius: '8px' }}
          />
          <TextField
            fullWidth
            variant="outlined"
            label="Location"
            value={localJob.location}
            onChange={(e) =>
              setLocalJob((prev) => ({ ...prev, location: e.target.value }))
            }
            sx={{ mb: 2, backgroundColor: 'background.default', borderRadius: '8px' }}
          />
          <TextField
            fullWidth
            variant="outlined"
            label="Salary"
            value={localJob.salary}
            onChange={(e) =>
              setLocalJob((prev) => ({ ...prev, salary: e.target.value }))
            }
            sx={{ mb: 2, backgroundColor: 'background.default', borderRadius: '8px' }}
          />
          <TextField
            fullWidth
            variant="outlined"
            label="Language"
            value={localJob.language}
            onChange={(e) =>
              setLocalJob((prev) => ({ ...prev, language: e.target.value }))
            }
            sx={{ mb: 2, backgroundColor: 'background.default', borderRadius: '8px' }}
          />
          <Box sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end', gap: 1 }}>
            <Button
              variant="outlined"
              color="secondary"
              onClick={() => setIsEditing(false)}
              sx={{
                textTransform: 'none',
                paddingX: 2,
                fontSize: '0.875rem',
                borderRadius: '8px',
              }}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={handleSave}
              sx={{
                textTransform: 'none',
                paddingX: 2,
                fontSize: '0.875rem',
                borderRadius: '8px',
              }}
            >
              Save
            </Button>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default EditableJobDetails;
