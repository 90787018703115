import React, { useEffect, useState } from 'react';
import { Avatar, Button, Container, Divider, Grid, Paper, Typography, Box, IconButton, List, ListItem, ListItemText, TextField } from '@mui/material';
import { fetchUserDetails, User, fetchUserJobs, fetchCategories, Category, Job, updateUserProfile, uploadCv } from '../api/api';
import { useParams, Link } from 'react-router-dom';
import Layout from '../components/Layout';
import JobListItem from '../components/JobListItem';
import { Edit, Lock, FileUpload, Save } from '@mui/icons-material';

const UserProfile: React.FC = () => {
    const { userID } = useParams<{ userID: string }>();
    const [user, setUser] = useState<User | undefined>(undefined);
    const [jobs, setJobs] = useState<Job[]>([]);
    const [categories, setCategories] = useState<Category[]>([]);
    const [cvFile, setCvFile] = useState<File | null>(null);
    const [isEditing, setIsEditing] = useState(false); // Track editing state
    const [updatedUser, setUpdatedUser] = useState<Partial<User>>({});
    const [userData, setUserData] = useState<Partial<User>>({}); // State for form data

    useEffect(() => {
        if (userID) {
            fetchUserDetails(Number(userID)).then((data) => {
                setUser(data);
                setUserData(data); // Initialize form data
                setUpdatedUser(data); // Initialize updated user with current data
            });
            fetchUserJobs(Number(userID)).then((data) => setJobs(data));
            fetchCategories().then((data) => setCategories(data));
        }
    }, [userID]);


    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        setUpdatedUser({ ...updatedUser, [name]: value }); // Update the form input state
    };

    const handleProfileUpdate = async () => {
        if (userID) {
            await updateUserProfile(Number(userID), updatedUser);
            setIsEditing(false); // Exit edit mode after saving
            fetchUserDetails(Number(userID)).then((data) => setUser(data)); // Refresh user data
        }
    };

    const handleCvUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files && event.target.files.length > 0) {
            const file = event.target.files[0];
            setCvFile(file);
            if (userID) {
                await uploadCv(Number(userID), file);
                // Handle any additional logic post-upload, like updating UI or showing a success message
            }
        }
    };

    return (
        <Layout>
            <Container maxWidth="md" sx={{ mt: 8, mb: 4 }}>
                {/* Header with Detailed Information */}
                <Paper elevation={3} sx={{ p: 4, borderRadius: '8px', mb: 4, position: 'relative' }}>
                    <Grid container alignItems="center" spacing={2}>
                        <Grid item>
                            <Avatar sx={{ width: 100, height: 100, bgcolor: 'purple' }}>
                                {user?.username?.charAt(0).toUpperCase()}
                            </Avatar>
                        </Grid>
                        <Grid item xs>
                            {!isEditing ? (
                                <>
                                    <Typography variant="h4" sx={{ fontWeight: 'bold', color: 'purple' }}>
                                        {user?.username}
                                    </Typography>
                                    <Typography variant="subtitle1" color="textSecondary">
                                        {user?.email}
                                    </Typography>
                                    <Box sx={{ mt: 1 }}>
                                        <Typography variant="body2" color="textSecondary">
                                            <strong>Role:</strong> {user?.role || 'N/A'}
                                        </Typography>
                                        <Typography variant="body2" color="textSecondary">
                                            <strong>Birth Date:</strong> {user?.birth_date || 'N/A'}
                                        </Typography>
                                        <Typography variant="body2" color="textSecondary">
                                            <strong>Location:</strong> {user?.location || 'N/A'}
                                        </Typography>
                                    </Box>
                                </>
                            ) : (
                                <>
                                <TextField
                                    label="Username"
                                    name="username"
                                    value={updatedUser.username || ''}
                                    onChange={handleInputChange}
                                    fullWidth
                                    margin="normal"
                                />
                                <TextField
                                    disabled
                                    label="Email"
                                    name="email"
                                    value={userData.email || ''}
                                    fullWidth
                                    margin="normal"
                                />
                                <TextField
                                    label="Birth Date"
                                    name="birth_date"
                                    type="date"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    value={updatedUser.birth_date || ''}
                                    onChange={handleInputChange}
                                    fullWidth
                                    margin="normal"
                                />
                                  <TextField
                                    label="role"
                                    name="role"
                                    value={updatedUser.role || ''}
                                    onChange={handleInputChange}
                                    fullWidth
                                    margin="normal"
                                />
                                <TextField
                                    label="Location"
                                    name="location"
                                    value={updatedUser.location || ''}
                                    onChange={handleInputChange}
                                    fullWidth
                                    margin="normal"
                                />
                            </>
                            )}
                        </Grid>
                        <Grid item sx={{ textAlign: 'right' }}>
                            {!isEditing ? (
                                <IconButton color="primary" onClick={() => setIsEditing(true)}>
                                    <Edit />
                                </IconButton>
                            ) : (
                                <Button variant="contained" color="primary" onClick={handleProfileUpdate}>
                                    Save
                                </Button>
                            )}
                        </Grid>
                    </Grid>
                    <Divider sx={{ my: 2 }} />
                    <Box sx={{ textAlign: 'center', mt: 3 }}>
                        <Button
                            variant="contained"
                            color="primary"
                            component="label"
                            startIcon={<FileUpload />}
                            sx={{ borderRadius: '20px', textTransform: 'none' }}
                        >
                            Upload CV
                            <input
                                type="file"
                                accept=".pdf,.doc,.docx"
                                hidden
                                onChange={handleCvUpload}
                            />
                        </Button>
                        {cvFile && (
                            <Typography variant="body2" sx={{ mt: 1 }}>
                                {cvFile.name} selected
                            </Typography>
                        )}
                    </Box>
                </Paper>

                {/* Job Overview Section */}
                <Paper elevation={3} sx={{ p: 4, borderRadius: '8px', mb: 4 }}>
                    <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 2 }}>
                        Job Applications
                    </Typography>
                    {jobs.length > 0 ? (
                        <List>
                            {jobs.map((job) => (
                                <JobListItem key={job.id} job={job} />
                            ))}
                        </List>
                    ) : (
                        <Typography variant="body2" color="textSecondary">
                            No jobs associated with this user.
                        </Typography>
                    )}
                </Paper>

                {/* Categories Section */}
                <Paper elevation={3} sx={{ p: 4, borderRadius: '8px' }}>
                    <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 2 }}>
                        Data Collection Flow
                    </Typography>
                    <List>
                        {categories.map((category) => (
                            <ListItem component={Link} to={`answers/${category.category_id}`} key={category.category_id}>
                                <ListItemText
                                    primary={<Typography variant="h6" sx={{ fontWeight: 'bold' }}>{category.name}</Typography>}
                                    secondary={<Typography variant="body2" color="textSecondary">{category.description}</Typography>}
                                />
                            </ListItem>
                        ))}
                    </List>
                </Paper>
            </Container>
        </Layout>
    );
};

export default UserProfile;