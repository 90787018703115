import React from "react";
import { Box, Chip } from "@mui/material";

const JobStatusChip: React.FC<{ status: number }> = ({ status }) => {
  const isActive = status === 1;

  return (
    <Box>
      <Chip
        size="small"
        label={isActive ? "Active" : "Draft"}
        sx={{
          backgroundColor: isActive ? "success.light" : "warning.light", // Subtle theme colors
          color: isActive ? "success.contrastText" : "warning.contrastText", // Text color based on theme
          fontWeight: 600, // Slightly bolder for emphasis
          borderRadius: "20px", // More rounded for a modern look
          padding: "6px 14px", // Extra padding for better spacing
          boxShadow: isActive
            ? "0px 4px 6px rgba(76, 175, 80, 0.2)" // Soft green shadow for active
            : "0px 4px 6px rgba(255, 152, 0, 0.2)", // Soft orange shadow for draft
          textTransform: "uppercase", // Uniform text style
          letterSpacing: "0.05em", // Adds spacing for better readability
          cursor: "default", // Prevents pointer cursor
          transition: "all 0.3s ease", // Smooth hover effect
          "&:hover": {
            boxShadow: isActive
              ? "0px 6px 10px rgba(76, 175, 80, 0.3)" // Slightly larger shadow on hover
              : "0px 6px 10px rgba(255, 152, 0, 0.3)",
          },
        }}
      />
    </Box>
  );
};

export default JobStatusChip;
