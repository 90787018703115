import React from 'react';
import { Table, TableHead, TableRow, TableCell, TableBody, Button, Typography, Paper, TableContainer } from '@mui/material';

interface InvoiceHistoryProps {
  invoices: { id: number; date: string; amount: string; status: string }[];
}

const InvoiceHistory: React.FC<InvoiceHistoryProps> = ({ invoices }) => (
  <Paper sx={{ padding: 3, boxShadow: 2, borderRadius: 2 }}>
    <Typography variant="h6" mb={2} fontWeight={600}>Invoice History</Typography>
    <TableContainer sx={{ maxHeight: 400 }}>
      <Table sx={{ minWidth: 650 }}>
        <TableHead>
          <TableRow>
            <TableCell sx={{ fontWeight: 600 }}>Invoice #</TableCell>
            <TableCell sx={{ fontWeight: 600 }}>Date</TableCell>
            <TableCell sx={{ fontWeight: 600 }}>Amount</TableCell>
            <TableCell sx={{ fontWeight: 600 }}>Status</TableCell>
            <TableCell sx={{ fontWeight: 600 }}>Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {invoices.map(invoice => (
            <TableRow
              key={invoice.id}
              sx={{
                '&:hover': {
                  backgroundColor: '#f5f5f5',
                },
                transition: 'background-color 0.2s ease',
              }}
            >
              <TableCell>{invoice.id}</TableCell>
              <TableCell>{invoice.date}</TableCell>
              <TableCell>{invoice.amount}</TableCell>
              <TableCell>{invoice.status}</TableCell>
              <TableCell>
                <Button
                  variant="outlined"
                  size="small"
                  sx={{
                    '&:hover': {
                      backgroundColor: '#e3f2fd',
                    },
                    textTransform: 'none',
                    fontWeight: 500,
                    paddingX: 2,
                  }}
                >
                  View
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  </Paper>
);

export default InvoiceHistory;
