import { formatDistanceToNow,format, parseISO } from 'date-fns';
import { tr } from 'date-fns/locale'; // Import Turkish locale

const formatExpiresAt = (expiresAt: string) => {
    const parsedDate = parseISO(expiresAt); // Parse the string date
    return formatDistanceToNow(parsedDate, { addSuffix: true,locale:tr });
};

const formatPostedAt = (postedAt: string) => {
    const parsedDate = parseISO(postedAt);
    return format(parsedDate, 'dd MMM yyyy', { locale: tr }); // Example: "03 Şub 2025"
  };

export {
    formatExpiresAt,
    formatPostedAt
}