import React from "react";
import { Grid, Paper, Typography, Button, Box } from "@mui/material";

const Footer: React.FC = () => {
  return (
    <Paper
      sx={{
        marginTop: 10,
        padding: 3,
        backgroundColor: "#f5f5f5",
        boxShadow: "none",
        borderTop: "1px solid #e0e0e0", // Subtle border for separation
      }}
      className="footer"
    >
      <Grid
        container
        alignItems="center"
        justifyContent="space-between"
        spacing={2}
        sx={{
          flexWrap: "wrap", // Allow wrapping for better responsiveness
          textAlign: { xs: "center", md: "left" }, // Center on small screens
        }}
      >
        {/* Left Section: Branding/Company Info */}
        <Grid item xs={12} md={6}>
          <Typography variant="body2" color="text.secondary">
            &copy; {new Date().getFullYear()} <strong>OptihireAI</strong>. All rights reserved.
          </Typography>
        </Grid>

        {/* Right Section: Links */}
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            textAlign: { xs: "center", md: "right" }, // Adjust alignment
            marginTop: { xs: 2, md: 0 }, // Add spacing for smaller screens
          }}
        >
          <Button
            color="primary"
            href="/help-center"
            sx={{ textTransform: "none", marginRight: 2 }}
          >
            Help Center
          </Button>
          <Button color="primary" href="/support" sx={{ textTransform: "none" }}>
            Support
          </Button>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default Footer;
