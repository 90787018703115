import React from 'react';
import { Paper, Typography, Box, Grid, LinearProgress, Divider } from '@mui/material';
import { Evaluation } from './IntervieweeItem';
import { CvEvaluation } from '../api/api';

interface EvaluationProps {
    evaluation: CvEvaluation;
}

const CVEvaluationSummary: React.FC<EvaluationProps> = ({ evaluation }) => {
    const getProgressValue = (score: number) => (score / 10) * 100; // Convert score to percentage for progress bar
    if (evaluation && evaluation.qualifications) {
        return (
          
                <Box sx={{ mt: 3 }}>

                    <Grid container spacing={2}>
                        <Grid item xs={6} md={3}>
                            <Typography variant="body2" color="text.secondary" gutterBottom>
                                Qualifications: {evaluation.qualifications.score}/10
                            </Typography>
                            <LinearProgress
                                variant="determinate"
                                value={getProgressValue(evaluation.qualifications.score)}
                                sx={{ mb: 2 }}
                            />
                        </Grid>
                        <Grid item xs={6} md={3}>
                            <Typography variant="body2" color="text.secondary" gutterBottom>
                                Experience: {evaluation.experience.score}/10
                            </Typography>
                            <LinearProgress
                                variant="determinate"
                                value={getProgressValue(evaluation.experience.score)}
                                sx={{ mb: 2 }}
                            />
                        </Grid>
                        <Grid item xs={6} md={3}>
                            <Typography variant="body2" color="text.secondary" gutterBottom>
                                Skills: {evaluation.skills.score}/10
                            </Typography>
                            <LinearProgress
                                variant="determinate"
                                value={getProgressValue(evaluation.skills.score)}
                                sx={{ mb: 2 }}
                            />
                        </Grid>
                        <Grid item xs={6} md={3}>
                            <Typography variant="body2" color="text.secondary" gutterBottom>
                                Overall: {evaluation.overall.score}/10
                            </Typography>
                            <LinearProgress
                                variant="determinate"
                                value={getProgressValue(evaluation.overall.score)}
                                sx={{ mb: 2 }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="body2" color="text.secondary">
                                Comments: {evaluation.comments}
                            </Typography>
                        </Grid>
                    </Grid>
                </Box>
          

        )
    }
    return <></>
};

export default CVEvaluationSummary;
