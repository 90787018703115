import React, { createContext, ReactElement, useContext, useState, useEffect } from 'react';
import { companyLogin } from '../api/api';

interface AuthContextType {
    token: string | null;
    isLoading: boolean;
    error: string | null;
    isAuthenticated: boolean
    login: (email: string, password: string) => Promise<void>;
    logout: () => void;
    resetError: () => void;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider: React.FC<{ children: ReactElement }> = ({ children }) => {
    const [token, setToken] = useState<string | null>(null); // Store the token, or null if not authenticated
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);
    const [isAuthenticated, setisAuthenticated] = useState(false)
    useEffect(() => {
        // Check if a token exists in localStorage on initial load
        const storedToken = localStorage.getItem('authToken');
        if (storedToken) {
            setToken(storedToken);
            setisAuthenticated(true)
        }
    }, []);

    const login = async (email: string, password: string) => {
        setIsLoading(true);
        setError(null);


        const response = await companyLogin(email, password)
        console.log(response.token)
        const token = response.token; // Replace with the actual token received from your API
        if (token) {
            setToken(token);
            localStorage.setItem('authToken', token); // Store the token in localStorage
            setIsLoading(false);
            setisAuthenticated(true)
        }
        else {

            setError('Invalid email or password');
            setIsLoading(false);
            throw "Auth error"

        }


    };

    const logout = () => {
        setToken(null); // Clear token from state
        localStorage.removeItem('authToken'); // Remove token from localStorage
        setisAuthenticated(false)

    };

    const resetError = () => setError(null);

    return (
        <AuthContext.Provider value={{ token, isAuthenticated, isLoading, error, login, logout, resetError }}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = (): AuthContextType => {
    const context = useContext(AuthContext);
    if (!context) {
        throw new Error('useAuth must be used within an AuthProvider');
    }
    return context;
};
