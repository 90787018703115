import React, { useState } from 'react';
import { Box, Typography, Card, CardContent, Divider, Chip, Rating, Stack, Collapse, IconButton } from '@mui/material';
import { ExpandMore as ExpandMoreIcon } from '@mui/icons-material';
import { ResponseEvaluationDetail, UserResponse } from '../api/api';
import { getQuestionSound } from '../utils';

interface ResponseItemProps {
    response: UserResponse;
    userID: string;
    jobID: string;
    evaluation?: ResponseEvaluationDetail;
    questionID: string;
}

const ResponseItem: React.FC<ResponseItemProps> = ({ response, userID, jobID, evaluation, questionID }) => {
    const [expanded, setExpanded] = useState(false);

    return (
        <Box sx={{ mt: 2, borderRadius: 2 }}>
            <CardContent sx={{ p:0 }}>
                {/* Main Content */}
                <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="flex-start">
                    <Box flex="1">
                        {/* Question */}
                        <Typography variant="subtitle1" sx={{ fontWeight: 'bold', color: 'primary.main', mb: 1 }}>
                            {response.question_text}
                        </Typography>

                        {/* User Response */}
                        <Typography variant="body2" sx={{ color: 'text.secondary', fontStyle: 'italic' }}>
                            {response.response_text}
                        </Typography>
                    </Box>

                    {/* Expand Button */}
                    <IconButton
                        onClick={() => setExpanded(!expanded)}
                        aria-expanded={expanded}
                        aria-label="show more"
                        size="small"
                    >
                        <ExpandMoreIcon />
                    </IconButton>
                </Box>

                {/* Collapsible Details */}
                <Collapse in={expanded} timeout="auto" unmountOnExit>
                    <Divider sx={{ my: 2 }} />

                    {/* Audio Recording */}
                    {response.response_sound && (
                        <Box>
                            
                            <audio controls style={{ width: '100%' }}>
                                <source
                                    src={getQuestionSound(userID, jobID, response.response_sound, questionID)}
                                    type="audio/wav"
                                />
                                Your browser does not support the audio element.
                            </audio>
                        </Box>
                    )}

                    {/* Follow-up Section */}
                    {response.followup_question && (
                        <Box sx={{ mt: 2 }}>
                            <Typography variant="subtitle1" sx={{ fontWeight: 'bold', color: 'primary.dark', mb: 1 }}>
                                Follow-up Question
                            </Typography>
                            <Typography variant="body2" sx={{ color: 'text.secondary', fontStyle: 'italic' }}>
                                {response.followup_question}
                            </Typography>

                            {response.followup_answer && (
                                <Typography variant="body2" sx={{ color: 'text.secondary', fontStyle: 'italic', mt: 1 }}>
                                    {response.followup_answer}
                                </Typography>
                            )}

                            {response.followup_sound && (
                                <Box sx={{ mt: 1 }}>
                                  
                                    <audio controls style={{ width: '100%' }}>
                                        <source
                                            src={getQuestionSound(userID, jobID, response.followup_sound, questionID)}
                                            type="audio/wav"
                                        />
                                        Your browser does not support the audio element.
                                    </audio>
                                </Box>
                            )}
                        </Box>
                    )}
                 
                    {/* AI Evaluation */}
                    {evaluation && (
                        <Box sx={{ mt: 0, padding: 2, borderRadius: 2,  }}>
                            <Typography variant="subtitle2" sx={{ fontWeight: 'bold', color: 'secondary.main', mb: 1 }}>
                                AI Feedback
                            </Typography>
                            <Typography variant="body2" sx={{ color: 'text.secondary', mb: 1 }}>
                                {evaluation.comments}
                            </Typography>
                            <Stack direction="row" spacing={1} alignItems="center">
                                <Chip
                                    label={`Quality: ${evaluation.quality}`}
                                    color={evaluation.quality > 3 ? 'success' : 'warning'}
                                    size="small"
                                />
                                <Chip
                                    label={`Relevance: ${evaluation.relevance}`}
                                    color={evaluation.relevance > 3 ? 'success' : 'warning'}
                                    size="small"
                                />
                                <Rating
                                    value={evaluation.quality / 2}
                                    precision={0.5}
                                    readOnly
                                    size="small"
                                    sx={{
                                        color: evaluation.quality > 3 ? 'success.main' : 'warning.main',
                                    }}
                                />
                            </Stack>
                        </Box>
                    )}
                </Collapse>
            </CardContent>
        </Box>
    );
};

export default ResponseItem;
