import React from 'react';
import { Paper, Typography, Box, Grid, LinearProgress, Divider } from '@mui/material';
import { Evaluation } from './IntervieweeItem';

interface EvaluationSummaryProps {
    evaluation: Evaluation;
}

const EvaluationSummary: React.FC<EvaluationSummaryProps> = ({ evaluation }) => {
    const getProgressValue = (score: number) => (score / 10) * 100; // Convert score to percentage for progress bar

    return (
        <>
            
            <Box sx={{ mt: 3, mb: 3 }}>
                <Typography variant="subtitle1" gutterBottom>
                    Response Evaluation:
                </Typography>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={4}>
                        <Typography variant="body2" color="text.secondary" gutterBottom>
                            Accuracy: {evaluation.response_evaluation.score}/10
                        </Typography>
                        <LinearProgress
                            variant="determinate"
                            value={getProgressValue(evaluation.response_evaluation.score)}
                            sx={{ mb: 2 }}
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Typography variant="body2" color="text.secondary" gutterBottom>
                            Completeness: {evaluation.response_evaluation.score}/10
                        </Typography>
                        <LinearProgress
                            variant="determinate"
                            value={getProgressValue(evaluation.response_evaluation.score)}
                            sx={{ mb: 2 }}
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Typography variant="body2" color="text.secondary" gutterBottom>
                            Overall Score: {evaluation.overall_score}/10
                        </Typography>
                        <LinearProgress
                            variant="determinate"
                            value={getProgressValue(evaluation.overall_score)}
                            sx={{ mb: 2 }}
                        />
                    </Grid>
                </Grid>
            </Box>
            <Divider />

            {/* Overall Score and Recommendations */}
            <Box sx={{ mt: 3 }}>
             
                <Typography variant="body2" color="text.secondary">
                    Recommendations: {evaluation.recommendations}
                </Typography>
            </Box>
        </>
    );
};

export default EvaluationSummary;
