import React, { useState } from 'react';
import { Box, Typography, Grid, Button, Card, CardContent, Divider } from '@mui/material';
import { Add } from '@mui/icons-material';
import ManagePaymentMethods from './ManagePaymentMethods';

interface BillingOverviewProps {
  companyName: string;
  currentPlan: string;
  nextBillingDate: string;
}

const BillingOverview: React.FC<BillingOverviewProps> = ({ companyName, currentPlan, nextBillingDate }) => {
  const [dialogOpen, setDialogOpen] = useState(false);

  const handleDialogOpen = () => setDialogOpen(true);
  const handleDialogClose = () => setDialogOpen(false);

  return (
    <>
      <Card sx={{ mb: 4, boxShadow: 2, borderRadius: 2 }}>
        <CardContent>
          <Grid container spacing={3}>
            {/* Left Column: Company Info */}
            <Grid item xs={12} sm={8}>
              <Typography variant="h5" fontWeight={700} color="primary">
                {companyName}
              </Typography>
              <Typography variant="body1" sx={{ color: 'text.secondary' }}>
                Current Plan: <strong>{currentPlan}</strong>
              </Typography>
              <Typography variant="body1" sx={{ color: 'text.secondary' }}>
                Next Billing Date: <strong>{nextBillingDate}</strong>
              </Typography>
            </Grid>
            
            {/* Right Column: Action */}
            <Grid item xs={12} sm={4} textAlign="right">
              <Button
                variant="contained"
                color="primary"
                sx={{
                  '&:hover': { backgroundColor: '#1565c0' },
                  paddingX: 4,
                  fontWeight: 600,
                  textTransform: 'none',
                }}
              >
                Pay Now
              </Button>
            </Grid>
          </Grid>

          <Divider sx={{ my: 2 }} />

          {/* Optional additional section for future information */}
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Typography variant="body2" color="text.secondary">
              Payment Methods, Recent Activities, etc.
            </Typography>
            <Button
              variant="outlined"
              color="primary"
              size="small"
              onClick={handleDialogOpen}
              startIcon={<Add />}
            >
              Manage Payment Methods
            </Button>
          </Box>
        </CardContent>
      </Card>

      {/* Payment Method Management Dialog */}
      <ManagePaymentMethods open={dialogOpen} onClose={handleDialogClose} />
    </>
  );
};

export default BillingOverview;
