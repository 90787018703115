import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Layout from '../components/Layout';
import { fetchIntervieweesEvaluationsWithJobId, fetchIntervieweesWithJobId, IntervieweeEvaluation, ScoreInterview } from '../api/api';
import { Container, Typography, Card, CardContent, Box, Button, CircularProgress, Alert, Grid } from '@mui/material';
import EvaluationGraph from '../components/Evaluation';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import WorkIcon from '@mui/icons-material/Work';
import LocationOnIcon from '@mui/icons-material/LocationOn'
import Loading from '../components/Loading';
const IntervieweeList: React.FC = () => {
    const { jobID } = useParams<{ jobID: string }>();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<string | null>(null);
    const [interviewees, setInterviewees] = useState<IntervieweeEvaluation[] | undefined>(undefined);

    useEffect(() => {
        const fetchInterviewees = async () => {
            try {
                if (jobID) {
                    const response = await fetchIntervieweesWithJobId(jobID);
                    const resx = await fetchIntervieweesEvaluationsWithJobId(jobID);
                    setInterviewees(resx);
                    response.map(async (val) => {
                        await handleScoreInterview(val.user_id, val.cv_url)
                    })
                    const res = await fetchIntervieweesEvaluationsWithJobId(jobID);
                    setInterviewees(res);
                }
            } catch (error) {
                setError('Error fetching interviewees');
                console.error('Error fetching interviewees:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchInterviewees();
    }, [jobID]);

    const handleNavigateToDetails = (userId: number) => {
        navigate(`/interviewees/${jobID}/${userId}`);
    };

    const handleScoreInterview = async (userId: number, cv_url?: string) => {
        try {
            if (cv_url === undefined || jobID === undefined) return
            const response = await ScoreInterview(jobID, userId, cv_url)
            if (response.data.result) { }
        } catch (error) {
            console.error('Error scoring interview:', error);
            //alert('Error scoring interview');
        }
    };

    if (loading) {
        return <Loading />;
    }

    if (error) {
        return <Alert severity="error">{error}</Alert>;
    }
    console.log("interviewees", interviewees)
    return (
        <Layout>
            <Container sx={{ mt: 8 }}>
                <Typography variant="h4" gutterBottom>List of users joined interviewee</Typography>
                {interviewees && interviewees.length > 0 ? (
                    <Box>
                        {interviewees.map(interviewee => (
                            <Card key={interviewee.user_id} variant="outlined" sx={{ mb: 2, p: 2 }}>
                                <CardContent>


                                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, p: 2, borderRadius: 1, bgcolor: '#f9f9f9' }}>
                                        <Typography variant="h6" sx={{ fontWeight: 'bold' }}>{interviewee.user_name}</Typography>

                                        {/* Contact Information Section */}
                                        <Card variant="outlined">
                                            <CardContent>
                                                <Typography variant="subtitle1" color="text.secondary" gutterBottom>Contact Information</Typography>
                                                <Grid container spacing={1}>
                                                    <Grid item xs={6} sx={{ display: 'flex', alignItems: 'center' }}>
                                                        <EmailIcon fontSize="small" sx={{ mr: 1 }} />
                                                        <Typography variant="body2">{interviewee.email}</Typography>
                                                    </Grid>
                                                    <Grid item xs={6} sx={{ display: 'flex', alignItems: 'center' }}>
                                                        <PhoneIcon fontSize="small" sx={{ mr: 1 }} />
                                                        <Typography variant="body2">{interviewee.phone}</Typography>
                                                    </Grid>
                                                </Grid>
                                            </CardContent>
                                        </Card>

                                        {/* Professional Background Section */}
                                        <Card variant="outlined">
                                            <CardContent>
                                                <Typography variant="subtitle1" color="text.secondary" gutterBottom>Professional Background</Typography>
                                                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                                    <WorkIcon fontSize="small" />
                                                    <Typography variant="body2">Salary: {interviewee.salary}</Typography>
                                                </Box>
                                                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mt: 1 }}>
                                                    <LocationOnIcon fontSize="small" />
                                                    <Typography variant="body2">Location: {interviewee.location}</Typography>
                                                </Box>
                                            </CardContent>
                                        </Card>
                                    </Box>

                                    {interviewee.cv_evaluation && interviewee.response_evaluation && (
                                        <EvaluationGraph evaluation={{ cv_evaluation: interviewee.cv_evaluation, overall_score: interviewee.overall_score ? interviewee.overall_score : 0, recommendations: interviewee.recommendations ? interviewee.recommendations : "", response_evaluation: interviewee.response_evaluation }} />
                                    )}

                                    <Box sx={{ mt: 2 }}>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={() => handleNavigateToDetails(interviewee.user_id)}
                                        >
                                            View Details
                                        </Button>

                                    </Box>
                                </CardContent>
                            </Card>
                        ))}
                    </Box>
                ) : (
                    <Typography>No interviewee data available.</Typography>
                )}
            </Container>
        </Layout>
    );
};

export default IntervieweeList;
