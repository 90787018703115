import React, { useState, useEffect } from 'react';
import { Accordion, AccordionSummary, AccordionDetails, Box, Typography, FormControl, InputLabel, Select, MenuItem, Paper } from '@mui/material';
import { CheckCircle, ErrorOutline, ExpandMore } from '@mui/icons-material';
import { EmailTemplates, getEmailTemplates } from '../api/api';
import { replacePlaceholders } from '../utils';


interface EmailTemplatesAccordionProps {
    applicationReceivedTemplate: string;
    applicationRejectedTemplate: string;
    interviewConfirmationTemplate: string;
    offerEmailTemplate: string;
    setApplicationReceivedTemplate: React.Dispatch<React.SetStateAction<string>>
    setApplicationRejectedTemplate: React.Dispatch<React.SetStateAction<string>>
    setInterviewConfirmationTemplate: React.Dispatch<React.SetStateAction<string>>
    setOfferEmailTemplate: React.Dispatch<React.SetStateAction<string>>
    emailTemplates: EmailTemplates[]

}

const EmailTemplatesAccordion: React.FC<EmailTemplatesAccordionProps> = ({ applicationReceivedTemplate,
    applicationRejectedTemplate,
    interviewConfirmationTemplate,
    offerEmailTemplate,
    emailTemplates,
    setApplicationReceivedTemplate,
    setApplicationRejectedTemplate,
    setInterviewConfirmationTemplate,
    setOfferEmailTemplate,
}) => {



    const filterEmailTemplates = (templates: EmailTemplates[], type: number) => {
        switch (type) {
            case 1:
                // received
                return templates.filter((val) => val.template_type_id === 1)
            case 2:
                // invitation
                return templates.filter((val) => val.template_type_id === 2)
            case 3:
                // offer
                return templates.filter((val) => val.template_type_id === 3)
            case 4:
                // rejection
                return templates.filter((val) => val.template_type_id === 4)

            default:
                return templates
        }

    }
    const renderPreview = (templateId: string) => {

        const template = emailTemplates.find((val) => String(val.id) === templateId);
        return (
            <>
                 <Paper
                    sx={{
                        mt: 2,
                        p: 2,
                        border: "1px solid #ddd",
                        maxHeight: "400px",
                        overflowY: "auto",
                        backgroundColor: "#f9f9f9",
                    }}
                >
                    <Typography variant="h6">
                        {replacePlaceholders(template?.subject) || "Subject here"}
                    </Typography>
                    <Typography
                        variant="body1"
                        sx={{ mt: 2 }}
                        dangerouslySetInnerHTML={{
                            __html: replacePlaceholders(template?.body) || "Body content here.",
                        }}
                    />
                </Paper>
            </>
        );
    };


    const renderAccordion = (
        label: string,
        template: string,
        setTemplate: React.Dispatch<React.SetStateAction<string>>,
        filterType: number
    ) => (
        <Accordion sx={{ mt: 2 }}>
            <AccordionSummary expandIcon={<ExpandMore />} id={`${label.toLowerCase().replace(/ /g, '-')}-header`}>
                <Box display="flex" alignItems="center">
                    {template ? (
                        <CheckCircle sx={{ color: 'green', fontSize: 20, marginRight: 2 }} />
                    ) : (
                        <ErrorOutline sx={{ color: 'orange', fontSize: 20, marginRight: 2 }} />
                    )}
                    <Typography>{label}</Typography>
                </Box>
            </AccordionSummary>
            <AccordionDetails>
                <FormControl fullWidth margin="normal">
                    <InputLabel id={`${label.toLowerCase().replace(/ /g, '-')}-label`}>Select Template</InputLabel>
                    <Select
                        label={`Select Template`}
                        labelId={`${label.toLowerCase().replace(/ /g, '-')}-label`}
                        value={template}
                        onChange={(e) => setTemplate(e.target.value)}
                    >
                        {filterEmailTemplates(emailTemplates, filterType).map((template) => (
                            <MenuItem key={template.id} value={template.id}>
                                {template.name}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                {template && renderPreview(template)}
            </AccordionDetails>
        </Accordion>
    );

    return (
        <Box sx={{ mb: 4, px:4,py:2 }}>
            <Typography variant="subtitle1" sx={{ mb: 1, fontWeight: 'bold', color: 'primary.main' }}>
                Email Configuration
            </Typography>
            <Typography variant="subtitle2" sx={{ mb: 1, color: 'text.secondary' }}>
                Please select email templates
            </Typography>
            {renderAccordion('Application Received', applicationReceivedTemplate, setApplicationReceivedTemplate, 1)}
            {renderAccordion('Application Rejected', applicationRejectedTemplate, setApplicationRejectedTemplate, 4)}
            {renderAccordion('Interview Confirmation', interviewConfirmationTemplate, setInterviewConfirmationTemplate, 2)}
            {renderAccordion('Offer Email', offerEmailTemplate, setOfferEmailTemplate, 3)}
        </Box>
    );
};

export default EmailTemplatesAccordion;
