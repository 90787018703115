import React, { useEffect, useState } from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    TextField,
    MenuItem,
    Select,
    InputLabel,
    FormControl,
    IconButton,
    Typography,
    Box,
    Menu,
    Button,
    TableSortLabel,
} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import {  Applicant, fetchAllApplicant } from '../api/api';
import Layout from '../components/Layout';
import { Link } from 'react-router-dom';
import theme from '../theme';

const Applicants: React.FC = () => {
    const [applicants, setApplicants] = useState<Applicant[]>([]);
    const [filteredApplicants, setFilteredApplicants] = useState<Applicant[]>([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [filterStatus, setFilterStatus] = useState('');
    const [sortField, setSortField] = useState<'applied_at' | 'status'>('applied_at');
    const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('asc');
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [selectedApplicantId, setSelectedApplicantId] = useState<string | null>(null);

    useEffect(() => {
        const loadApplicants = async () => {
            try {
                const applicantsData = await fetchAllApplicant();
                setApplicants(applicantsData);
                setFilteredApplicants(applicantsData);
            } catch (err) {
                console.error(err);
            }
        };

        loadApplicants();
    }, []);

    useEffect(() => {
        // Apply filtering and searching
        let filtered = applicants;

        if (filterStatus) {
            filtered = filtered.filter((applicant) => applicant.status === filterStatus);
        }

        if (searchTerm) {
            filtered = filtered.filter(
                (applicant) =>
                    applicant.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
                    applicant.email.toLowerCase().includes(searchTerm.toLowerCase())
            );
        }

        setFilteredApplicants(filtered);
    }, [searchTerm, filterStatus, applicants]);

    const handleSort = (field: 'applied_at' | 'status') => {
        const isAsc = sortField === field && sortOrder === 'asc';
        setSortOrder(isAsc ? 'desc' : 'asc');
        setSortField(field);

        const sorted = [...filteredApplicants].sort((a, b) => {
            if (field === 'applied_at') {
                return isAsc
                    ? new Date(a.applied_at).getTime() - new Date(b.applied_at).getTime()
                    : new Date(b.applied_at).getTime() - new Date(a.applied_at).getTime();
            }
            return isAsc ? a.status.localeCompare(b.status) : b.status.localeCompare(a.status);
        });

        setFilteredApplicants(sorted);
    };

    const handleMenuOpen = (event: React.MouseEvent<HTMLElement>, applicantId: string) => {
        setAnchorEl(event.currentTarget);
        setSelectedApplicantId(applicantId);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
        setSelectedApplicantId(null);
    };

    return (
        <Layout>
            <Box>
                <Typography variant="h4" gutterBottom>
                    Manage Applicants
                </Typography>
                {/* Search and Filters */}
                <Box display="flex" gap={2} marginBottom={2}>
                    <TextField
                        label="Search Applicants"
                        variant="outlined"
                        fullWidth
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                    />
                    <FormControl sx={{ width: 300 }}>
                        <InputLabel>Status</InputLabel>
                        <Select
                            label="Status"
                            value={filterStatus}
                            onChange={(e) => setFilterStatus(e.target.value)}
                        >
                            <MenuItem value="">All</MenuItem>
                            <MenuItem value="shortlisted">Shortlisted</MenuItem>
                            <MenuItem value="rejected">Rejected</MenuItem>
                            <MenuItem value="hired">Hired</MenuItem>
                        </Select>
                    </FormControl>
                </Box>

                {/* Applicants Table */}
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>
                                    <Typography variant="subtitle2" fontWeight="bold">
                                        Name
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography variant="subtitle2" fontWeight="bold">
                                        Email
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography variant="subtitle2" fontWeight="bold">
                                        Job Title
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <TableSortLabel
                                        active={sortField === 'applied_at'}
                                        direction={sortOrder}
                                        onClick={() => handleSort('applied_at')}
                                    >
                                        <Typography variant="subtitle2" fontWeight="bold">
                                            Applied Date
                                        </Typography>
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell>
                                    <TableSortLabel
                                        active={sortField === 'status'}
                                        direction={sortOrder}
                                        onClick={() => handleSort('status')}
                                    >
                                        <Typography variant="subtitle2" fontWeight="bold">
                                            Status
                                        </Typography>
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell>
                                    <Typography variant="subtitle2" fontWeight="bold">
                                        Actions
                                    </Typography>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {filteredApplicants.map((applicant) => (
                                <TableRow
                                    sx={{
                                        '&:hover': {
                                            backgroundColor: theme.palette.action.hover,
                                        },
                                    }}
                                    key={applicant.id}
                                >
                                    <TableCell>{applicant.name}</TableCell>
                                    <TableCell>{applicant.email}</TableCell>
                                    <TableCell>{applicant.job_title}</TableCell>
                                    <TableCell>
                                        {new Date(applicant.applied_at).toLocaleDateString()}
                                    </TableCell>
                                    <TableCell>{applicant.status}</TableCell>
                                    <TableCell>
                                        <IconButton
                                            aria-controls={`menu-${applicant.id}`}
                                            aria-haspopup="true"
                                            onClick={(e) => handleMenuOpen(e, applicant.id.toString())}
                                        >
                                            <MoreVertIcon />
                                        </IconButton>
                                        <Menu
                                            id={`menu-${applicant.id}`}
                                            anchorEl={anchorEl}
                                            open={Boolean(anchorEl) && selectedApplicantId === applicant.id.toString()}
                                            onClose={handleMenuClose}
                                        >
                                            <MenuItem component={Link} to={`/applicants/${applicant.job_id}/${applicant.id}`}>
                                                View Applicant
                                            </MenuItem>
                                            <MenuItem>Shortlist</MenuItem>
                                            <MenuItem>Reject</MenuItem>
                                            <MenuItem>Hire</MenuItem>
                                        </Menu>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
        </Layout>
    );
};

export default Applicants;
