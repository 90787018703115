import React, { useEffect, useState } from 'react';
import {
    Button,
    Card,
    CardContent,
    CircularProgress,
    IconButton,
    Menu,
    MenuItem,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tooltip,
    Typography,
    Box,
} from '@mui/material';
import { KeyboardArrowDown, Publish, Save, HighlightOff } from '@mui/icons-material';
import theme from '../theme'; // Your custom theme
import { fetchJobs, Job } from '../api/api';
import { formatPostedAt } from '../utils/timeUtils';
import { useNavigate } from 'react-router-dom';

const JobListMinimal = () => {
    const [jobs, setJobs] = useState<Job[]>([]);
    const [loading, setLoading] = useState(false);
    const [menuAnchor, setMenuAnchor] = useState<null | HTMLElement>(null);
    const navigate = useNavigate();

    useEffect(() => {
        const loadJobs = async () => {
            setLoading(true);
            try {
                const jobsData = await fetchJobs();
                setJobs(jobsData);
            } catch (error) {
                console.error(error);
            } finally {
                setLoading(false);
            }
        };

        loadJobs();
    }, []);

    const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
        setMenuAnchor(event.currentTarget);
    };

    const handleMenuClose = () => {
        setMenuAnchor(null);
    };

    const handleRowClick = (id: string) => {
        navigate("jobs/detail/" + id); // Navigate to the provided link

    };

    return (
        <Box>
            <Typography variant="h6" sx={{ mb: 2, fontWeight: 'bold' }}>
                Recent Jobs
            </Typography>
            <Card sx={{ borderRadius: 2, boxShadow: 3 }}>
                <CardContent sx={{ p: 0 }}>
                    {loading ? (
                        <Box display="flex" justifyContent="center" alignItems="center" sx={{ height: 200 }}>
                            <CircularProgress />
                        </Box>
                    ) : jobs.length === 0 ? (
                        <Box display="flex" justifyContent="center" alignItems="center" sx={{ height: 200 }}>
                            <Typography variant="body1" color="text.secondary">
                                No jobs available.
                            </Typography>
                        </Box>
                    ) : (
                        <TableContainer sx={{height:280 }}>
                            <Table stickyHeader>
                                <TableHead>
                                    <TableRow>
                                        <TableCell sx={{ fontWeight: 'bold', backgroundColor: theme.palette.grey[200] }}>Title</TableCell>
                                        <TableCell sx={{ fontWeight: 'bold', backgroundColor: theme.palette.grey[200] }}>Posted At</TableCell>
                                        <TableCell sx={{ fontWeight: 'bold', backgroundColor: theme.palette.grey[200] }}>Applicants</TableCell>
                                        <TableCell sx={{ fontWeight: 'bold', backgroundColor: theme.palette.grey[200] }}>Status</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {jobs.map((job) => (
                                        <TableRow
                                            key={job.id}
                                            sx={{
                                                '&:hover': {
                                                    cursor: 'pointer',
                                                    backgroundColor: theme.palette.action.hover,
                                                },
                                            }}
                                        >
                                            <TableCell onClick={() => handleRowClick(job.id)}>
                                                <Tooltip title={job.job_title} arrow>
                                                    <Typography
                                                        noWrap
                                                        sx={{
                                                            fontWeight: 500,

                                                        }}
                                                    >
                                                        {job.job_title}
                                                    </Typography>
                                                </Tooltip>
                                            </TableCell>
                                            <TableCell onClick={() => handleRowClick(job.id)}
                                            >{formatPostedAt(job.posted_at)}</TableCell>
                                            <TableCell onClick={() => handleRowClick(job.id)}
                                            >{job.totalApplicants}</TableCell>
                                            <TableCell >
                                                <Button
                                                    sx={{
                                                        borderRadius: 2.5,
                                                        textTransform: 'none',
                                                        fontWeight: 'bold',
                                                        fontSize: 12,
                                                        color: 'white',
                                                        backgroundColor: theme.palette.success.main,
                                                        '&:hover': { backgroundColor: theme.palette.success.dark },
                                                    }}
                                                    onClick={(e) => {
                                                        e.stopPropagation(); // Prevent row click
                                                        handleMenuClick(e);
                                                    }}
                                                    endIcon={<KeyboardArrowDown />}
                                                >
                                                    Published
                                                </Button>
                                                <Menu
                                                    anchorEl={menuAnchor}
                                                    open={Boolean(menuAnchor)}
                                                    onClose={handleMenuClose}
                                                    sx={{
                                                        '& .MuiPaper-root': {
                                                            boxShadow: 'none',
                                                            border: '1px solid #ccc',
                                                            borderRadius: 2,
                                                        },
                                                    }}
                                                >
                                                    <MenuItem onClick={handleMenuClose}>
                                                        <Publish fontSize="small" />
                                                        Publish
                                                    </MenuItem>
                                                    <MenuItem onClick={handleMenuClose}>
                                                        <Save fontSize="small" />
                                                        Save as Draft
                                                    </MenuItem>
                                                    <MenuItem onClick={handleMenuClose}>
                                                        <HighlightOff fontSize="small" />
                                                        Close Job
                                                    </MenuItem>
                                                </Menu>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    )}
                </CardContent>
            </Card>
        </Box>
    );
};

export default JobListMinimal;
